define("subscriptionclient/helpers/datewithtime", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.datewithtime = datewithtime;
  _exports.default = void 0;

  function datewithtime(params
  /*, hash*/
  ) {
    if (params[0] != null) {
      var pm = false;
      var am_set;
      var monthrepresent = ["none", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
      var t = params[0].split("T");
      var dateinitial = t[0].split("-");
      var year = dateinitial[0].match(/.{1,2}/g);
      var monthinital = parseInt(dateinitial[1], 10);
      var month = monthrepresent[monthinital];
      var timeinitial = t[1].match(/.{1,8}/g);
      var time2 = timeinitial[0].split(":");

      if (time2[0] >= 12) {
        pm = true;
      }

      if (time2[0] > 12) {
        var hour = parseInt(time2[0], 10);
        hour = hour - 12;
        time2[0] = hour.toString();
      }

      if (pm) {
        am_set = 'pm';
      } else {
        am_set = 'am';
      }

      if (params[1] == null) {
        // var datefinal=dateinitial[2]+' '+ month+ ' ' +year[1]+'  '+time2[0]+':'+time2[1]+':'+time2[2]+' '+am_set;  full time with seconds
        var datefinal = dateinitial[2] + ' ' + month + ' ' + year[1] + '  ' + time2[0] + ':' + time2[1] + ' ' + am_set;
      } else if (params[1] == 'date') {
        var datefinal = dateinitial[2] + ' ' + month + ' ' + year[1];
      }

      return datefinal; // return moment(params[0]).format('LLL');
    } else {
      return null;
    }
  }

  var _default = Ember.Helper.helper(datewithtime);

  _exports.default = _default;
});