define("subscriptionclient/helpers/list-dict", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.listDict = listDict;
  _exports.default = void 0;

  function listDict(params) {
    var v0 = params[0];
    var v1 = params[1];
    var v2 = params[2];

    if (v0.length >= parseInt(v1)) {
      if (v0[v1].hasOwnProperty(v2)) {
        return v0[v1][v2];
      }
    }

    return 'None';
  }

  var _default = Ember.Helper.helper(listDict);

  _exports.default = _default;
});