define("subscriptionclient/routes/settings/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    show_tech: Cookies.get('show_tech'),
    show_role: Cookies.get('show_role'),
    model: function model() {
      var show_role = this.get('show_role');
      var show_tech = this.get('show_tech');

      if (show_role == 'true') {
        this.transitionTo('settings.roles');
      } else if (show_tech == 'true') {
        this.transitionTo('settings.technicians');
      } else {
        window.location.href = '/notfound';
      }
    }
  });

  _exports.default = _default;
});