define("subscriptionclient/authenticators/auth", ["exports", "ember-simple-auth/authenticators/base", "subscriptionclient/config/environment"], function (_exports, _base, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import Base from 'ember-simple-auth/authenticators/base';
  // export default Base.extend({
  //   restore(data) {
  //   },
  //   authenticate(/*args*/) {
  //   },
  //   invalidate(data) {
  //   }
  // });
  var _default = _base.default.extend({
    tokenEndpoint: _environment.default.SUBSCRIPTION_URL + "/token/",
    // api-token-auth
    restore: function restore(data) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (!Ember.isEmpty(data.token)) {
          resolve(data);
        } else {
          reject();
        }
      });
    },
    authenticate: function authenticate(creds) {
      var username = creds.username,
          password = creds.password,
          s = creds.s;

      if (s == 'yes') {
        var data = JSON.stringify({
          username: username,
          password: password
        });
        var requestOptions = {
          url: this.tokenEndpoint,
          type: 'POST',
          data: data,
          contentType: 'application/json',
          dataType: 'json'
        };
        return new Ember.RSVP.Promise(function (resolve, reject) {
          jQuery.ajax(requestOptions).then(function (response) {
            var tok = 'Bearer ' + response.access;
            var refresh = response.refresh;
            Ember.run(function () {
              resolve({
                token: tok,
                refresh: refresh
              });
            });
          }, function (error) {
            Ember.run(function () {
              reject(error);
            });
          });
        });
      } else {
        return new Ember.RSVP.Promise(function (resolve, reject) {
          if (!Ember.isEmpty(username)) {
            resolve({
              token: username,
              refresh: password
            });
          } else {
            reject();
          }
        });
      }
    },
    invalidate: function invalidate(data) {
      return Ember.RSVP.Promise.resolve(data);
    }
  });

  _exports.default = _default;
});