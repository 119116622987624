define("subscriptionclient/helpers/compare", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.compare = compare;
  _exports.default = void 0;

  function compare(params) {
    if (params[3]) {
      params[2] = Cookies.get('usrid');

      if (params[0] != null) {
        params[0] = params[0].toLowerCase();
      }

      if (params[2] != null) {
        params[2] = params[2].toLowerCase();
      }
    }

    var v1 = params[0];
    var operator = params[1];
    var v2 = params[2];

    switch (operator) {
      case '==':
        return v1 == v2;

      case '!=':
        return v1 != v2;

      case '===':
        return v1 === v2;

      case '<':
        return v1 < v2;

      case '<=':
        return v1 <= v2;

      case '>':
        return v1 > v2;

      case '>=':
        return v1 >= v2;

      case '&&':
        return !!(v1 && v2);

      case '||':
        return !!(v1 || v2);

      case 'inc':
        if (v1 != null) {
          v1 = v1.toLowerCase();
        }

        if (v2 != null) {
          v2 = v2.toLowerCase();
        }

        if (v1 != null) {
          return v1.includes(v2);
        } else {
          return false;
        }

      default:
        return false;
    }
  }

  var _default = Ember.Helper.helper(compare);

  _exports.default = _default;
});