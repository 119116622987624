define("subscriptionclient/helpers/times", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function times(params
  /*, hash*/
  ) {
    var accum = [];

    for (var i = params[0]; i <= params[1]; ++i) {
      accum.push({
        index: parseInt(i)
      });
    }

    return accum;
  });

  _exports.default = _default;
});