define("subscriptionclient/controllers/quote", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    quote_show: Cookies.get('quote_show'),
    quoteacc_show: Cookies.get('quoteacc_show')
  });

  _exports.default = _default;
});