define("subscriptionclient/templates/components", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oXycP5SU",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"session\",\"isAuthenticated\"]]],null,{\"statements\":[[0,\"\\n\"],[7,\"div\",true],[10,\"id\",\"mytopsubnav\"],[10,\"class\",\"topnav\"],[10,\"style\",\"min-height: 43px;padding-left:20px;position:sticky;top:68px;z-index: 0;width: 100%;right:0px;\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"compare\",[[24,[\"show_coupon\"]],\"==\",\"true\"],null]],null,{\"statements\":[[4,\"link-to\",null,[[\"class\",\"route\"],[\"link_changeclass\",\"components.coupons\"]],{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"topsubnavbarhead\"],[10,\"align\",\"center\"],[8],[0,\"\\n\\t\\t\"],[7,\"span\",true],[10,\"class\",\"topsubnavbarheadmargin\"],[8],[0,\"Coupon\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[4,\"if\",[[28,\"compare\",[[24,[\"show_plan\"]],\"==\",\"true\"],null]],null,{\"statements\":[[4,\"link-to\",null,[[\"class\",\"route\"],[\"link_changeclass\",\"components.plans\"]],{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"topsubnavbarhead\"],[10,\"align\",\"center\"],[8],[0,\"\\n\\t\\t\"],[7,\"span\",true],[10,\"class\",\"topsubnavbarheadmargin\"],[8],[0,\"Plan\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[4,\"if\",[[28,\"compare\",[[24,[\"show_tax\"]],\"==\",\"true\"],null]],null,{\"statements\":[[4,\"link-to\",null,[[\"class\",\"route\"],[\"link_changeclass\",\"components.tax\"]],{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"topsubnavbarhead\"],[10,\"align\",\"center\"],[8],[0,\"\\n\\t\\t\"],[7,\"span\",true],[10,\"class\",\"topsubnavbarheadmargin\"],[8],[0,\"Tax\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[9],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "subscriptionclient/templates/components.hbs"
    }
  });

  _exports.default = _default;
});