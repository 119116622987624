define("subscriptionclient/helpers/countryandstate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.countryandstate = countryandstate;
  _exports.default = void 0;

  function countryandstate(params
  /*, hash*/
  ) {
    if (params[0] != null) {
      var stateselect = [{
        "name": "Afghanistan",
        "code": "AF",
        "divisions": [{
          "code": "AF-BDS",
          "represent": "Badakhshān"
        }, {
          "code": "AF-BDG",
          "represent": "Bādghīs"
        }, {
          "code": "AF-BGL",
          "represent": "Baghlān"
        }, {
          "code": "AF-BGL",
          "represent": "Baghlān"
        }, {
          "code": "AF-BAL",
          "represent": "Balkh"
        }, {
          "code": "AF-BAM",
          "represent": "Bāmīān"
        }, {
          "code": "AF-FRA",
          "represent": "Farāh"
        }, {
          "code": "AF-FYB",
          "represent": "Fāryāb"
        }, {
          "code": "AF-GHA",
          "represent": "Ghaznī"
        }, {
          "code": "AF-GHO",
          "represent": "Ghowr"
        }, {
          "code": "AF-HEL",
          "represent": "Helmand"
        }, {
          "code": "AF-HER",
          "represent": "Herāt"
        }, {
          "code": "AF-JOW",
          "represent": "Jowzjān"
        }, {
          "code": "AF-KAB",
          "represent": "Kabul (Kābol)"
        }, {
          "code": "AF-KAN",
          "represent": "Kandahār"
        }, {
          "code": "AF-KAP",
          "represent": "Kāpīsā"
        }, {
          "code": "AF-KNR",
          "represent": "Konar (Kunar)"
        }, {
          "code": "AF-KDZ",
          "represent": "Kondoz (Kunduz)"
        }, {
          "code": "AF-LAG",
          "represent": "Laghmān"
        }, {
          "code": "AF-LOW",
          "represent": "Lowgar"
        }, {
          "code": "AF-NAN",
          "represent": "Nangrahār (Nangarhār)"
        }, {
          "code": "AF-NIM",
          "represent": "Nīmrūz"
        }, {
          "code": "AF-ORU",
          "represent": "Orūzgān (Urūzgā)"
        }, {
          "code": "AF-PIA",
          "represent": "Paktīā"
        }, {
          "code": "AF-PKA",
          "represent": "Paktīkā"
        }, {
          "code": "AF-PAR",
          "represent": "Parwān"
        }, {
          "code": "AF-SAM",
          "represent": "Samangān"
        }, {
          "code": "AF-SAR",
          "represent": "Sar-e Pol"
        }, {
          "code": "AF-TAK",
          "represent": "Takhār"
        }, {
          "code": "AF-WAR",
          "represent": "Wardak (Wardag)"
        }, {
          "code": "AF-ZAB",
          "represent": "Zābol (Zābul)"
        }]
      }, {
        "name": "Albania",
        "code": "AL",
        "divisions": [{
          "code": "AL-BR",
          "represent": "Berat"
        }, {
          "code": "AL-BU",
          "represent": "Bulqizë"
        }, {
          "code": "AL-DL",
          "represent": "Delvinë"
        }, {
          "code": "AL-DV",
          "represent": "Devoll"
        }, {
          "code": "AL-DI",
          "represent": "Dibër"
        }, {
          "code": "AL-DR",
          "represent": "Durrës"
        }, {
          "code": "AL-EL",
          "represent": "Elbasan"
        }, {
          "code": "AL-FR",
          "represent": "Fier"
        }, {
          "code": "AL-GR",
          "represent": "Gramsh"
        }, {
          "code": "AL-GJ",
          "represent": "Gjirokastër"
        }, {
          "code": "AL-HA",
          "represent": "Has"
        }, {
          "code": "AL-KA",
          "represent": "Kavajë"
        }, {
          "code": "AL-ER",
          "represent": "Kolonjë"
        }, {
          "code": "AL-KO",
          "represent": "Korcë"
        }, {
          "code": "AL-KR",
          "represent": "Krujë"
        }, {
          "code": "AL-KC",
          "represent": "Kucovë"
        }, {
          "code": "AL-KU",
          "represent": "Kukës"
        }, {
          "code": "AL-LA",
          "represent": "Laç"
        }, {
          "code": "AL-LE",
          "represent": "Lezhë"
        }, {
          "code": "AL-LB",
          "represent": "Librazhd"
        }, {
          "code": "AL-LU",
          "represent": "Lushnjë"
        }, {
          "code": "AL-MM",
          "represent": "Malësia e Madhe"
        }, {
          "code": "AL-MK",
          "represent": "Mallakastër"
        }, {
          "code": "AL-MT",
          "represent": "Mat"
        }, {
          "code": "AL-MR",
          "represent": "Mirditë"
        }, {
          "code": "AL-PQ",
          "represent": "Peqin"
        }, {
          "code": "AL-PR",
          "represent": "Përmet"
        }, {
          "code": "AL-PG",
          "represent": "Pogradec"
        }, {
          "code": "AL-PU",
          "represent": "Pukë"
        }, {
          "code": "AL-SR",
          "represent": "Sarandë"
        }, {
          "code": "AL-SK",
          "represent": "Skrapar"
        }, {
          "code": "AL-SH",
          "represent": "Shkodër"
        }, {
          "code": "AL-TE",
          "represent": "Tepelenë"
        }, {
          "code": "AL-TR",
          "represent": "Tiranë"
        }, {
          "code": "AL-TP",
          "represent": "Tropojë"
        }, {
          "code": "AL-VL",
          "represent": "Vlorë"
        }]
      }, {
        "name": "Algeria",
        "code": "DZ",
        "divisions": [{
          "code": "DZ-01",
          "represent": "Adrar"
        }, {
          "code": "DZ-44",
          "represent": "Aïn Defla"
        }, {
          "code": "DZ-46",
          "represent": "Aïn Témouchent"
        }, {
          "code": "DZ-16",
          "represent": "Alger"
        }, {
          "code": "DZ-23",
          "represent": "Annaba"
        }, {
          "code": "DZ-05",
          "represent": "Batna"
        }, {
          "code": "DZ-08",
          "represent": "Béchar"
        }, {
          "code": "DZ-06",
          "represent": "Béjaïa"
        }, {
          "code": "DZ-07",
          "represent": "Biskra"
        }, {
          "code": "DZ-09",
          "represent": "Blida"
        }, {
          "code": "DZ-34",
          "represent": "Bordj Bou Arréridj"
        }, {
          "code": "DZ-10",
          "represent": "Bouira"
        }, {
          "code": "DZ-35",
          "represent": "Boumerdès"
        }, {
          "code": "DZ-02",
          "represent": "Chlef"
        }, {
          "code": "DZ-25",
          "represent": "Constantine"
        }, {
          "code": "DZ-17",
          "represent": "Djelfa"
        }, {
          "code": "DZ-32",
          "represent": "El Bayadh"
        }, {
          "code": "DZ-39",
          "represent": "El Oued"
        }, {
          "code": "DZ-36",
          "represent": "El Tarf"
        }, {
          "code": "DZ-47",
          "represent": "Ghardaïa"
        }, {
          "code": "DZ-24",
          "represent": "Guelma"
        }, {
          "code": "DZ-33",
          "represent": "Illizi"
        }, {
          "code": "DZ-18",
          "represent": "Jijel"
        }, {
          "code": "DZ-40",
          "represent": "Khenchela"
        }, {
          "code": "DZ-03",
          "represent": "Laghouat"
        }, {
          "code": "DZ-29",
          "represent": "Mascara"
        }, {
          "code": "DZ-26",
          "represent": "Médéa"
        }, {
          "code": "DZ-43",
          "represent": "Mila"
        }, {
          "code": "DZ-27",
          "represent": "Mostaganem"
        }, {
          "code": "DZ-28",
          "represent": "Msila"
        }, {
          "code": "DZ-45",
          "represent": "Naama"
        }, {
          "code": "DZ-31",
          "represent": "Oran"
        }, {
          "code": "DZ-30",
          "represent": "Ouargla"
        }, {
          "code": "DZ-04",
          "represent": "Oum el Bouaghi"
        }, {
          "code": "DZ-48",
          "represent": "Relizane"
        }, {
          "code": "DZ-20",
          "represent": "Saïda"
        }, {
          "code": "DZ-19",
          "represent": "Sétif"
        }, {
          "code": "DZ-22",
          "represent": "Sidi Bel Abbès"
        }, {
          "code": "DZ-21",
          "represent": "Skikda"
        }, {
          "code": "DZ-41",
          "represent": "Souk Ahras"
        }, {
          "code": "DZ-11",
          "represent": "Tamanghasset"
        }, {
          "code": "DZ-12",
          "represent": "Tébessa"
        }, {
          "code": "DZ-14",
          "represent": "Tiaret"
        }, {
          "code": "DZ-37",
          "represent": "Tindouf"
        }, {
          "code": "DZ-42",
          "represent": "Tipaza"
        }, {
          "code": "DZ-38",
          "represent": "Tissemsilt"
        }, {
          "code": "DZ-15",
          "represent": "Tizi Ouzou"
        }, {
          "code": "DZ-13",
          "represent": "Tlemcen"
        }]
      }, {
        "name": "American Samoa",
        "code": "AS",
        "divisions": [{
          "code": "AS-AS",
          "represent": "American Samoa"
        }]
      }, {
        "name": "Andorra",
        "code": "AD",
        "divisions": [{
          "code": "AD-AD",
          "represent": "Andorra"
        }, {
          "code": "AD-02",
          "represent": "Canillo"
        }, {
          "code": "AD-03",
          "represent": "Encamp"
        }, {
          "code": "AD-04",
          "represent": "La Massana"
        }, {
          "code": "AD-05",
          "represent": "Ordino"
        }, {
          "code": "AD-06",
          "represent": "Sant Julià de Lòria"
        }, {
          "code": "AD-07",
          "represent": "Andorra la Vella"
        }, {
          "code": "AD-08",
          "represent": "Escaldes-Engordany"
        }]
      }, {
        "name": "Angola",
        "code": "AO",
        "divisions": [{
          "code": "AO-BGO",
          "represent": "Bengo"
        }, {
          "code": "AO-BGU",
          "represent": "Benguela"
        }, {
          "code": "AO-BIE",
          "represent": "Bié"
        }, {
          "code": "AO-CAB",
          "represent": "Cabinda"
        }, {
          "code": "AO-CCU",
          "represent": "Cuando-Cubango"
        }, {
          "code": "AO-CNO",
          "represent": "Cuanza Norte"
        }, {
          "code": "AO-CUS",
          "represent": "Cuanza Sul"
        }, {
          "code": "AO-CNN",
          "represent": "Cunene"
        }, {
          "code": "AO-HUA",
          "represent": "Huambo"
        }, {
          "code": "AO-HUI",
          "represent": "Huíla"
        }, {
          "code": "AO-LUA",
          "represent": "Luanda"
        }, {
          "code": "AO-LNO",
          "represent": "Lunda Norte"
        }, {
          "code": "AO-LSU",
          "represent": "Lunda Sul"
        }, {
          "code": "AO-MAL",
          "represent": "Malange"
        }, {
          "code": "AO-MOX",
          "represent": "Moxico"
        }, {
          "code": "AO-NAM",
          "represent": "Namibe"
        }, {
          "code": "AO-UIG",
          "represent": "Uíge"
        }, {
          "code": "AO-ZAI",
          "represent": "Zaïre"
        }]
      }, {
        "name": "Anguilla",
        "code": "AI",
        "divisions": [{
          "code": "AI-AI",
          "represent": "Anguilla"
        }]
      }, {
        "name": "Antarctica",
        "code": "AQ",
        "divisions": [{
          "code": "AQ-AQ",
          "represent": "Antarctica"
        }]
      }, {
        "name": "Antigua & Barbuda",
        "code": "AG",
        "divisions": [{
          "code": "AG-AG",
          "represent": "Antigua & Barbuda"
        }]
      }, {
        "name": "Argentina",
        "code": "AR",
        "divisions": [{
          "code": "AR-C",
          "represent": "Capital federal"
        }, {
          "code": "AR-B",
          "represent": "Buenos Aires"
        }, {
          "code": "AR-K",
          "represent": "Catamarca"
        }, {
          "code": "AR-X",
          "represent": "Córdoba"
        }, {
          "code": "AR-W",
          "represent": "Corrientes"
        }, {
          "code": "AR-H",
          "represent": "Chaco"
        }, {
          "code": "AR-U",
          "represent": "Chubut"
        }, {
          "code": "AR-E",
          "represent": "Entre Ríos"
        }, {
          "code": "AR-P",
          "represent": "Formosa"
        }, {
          "code": "AR-Y",
          "represent": "Jujuy"
        }, {
          "code": "AR-L",
          "represent": "La Pampa"
        }, {
          "code": "AR-F",
          "represent": "La Rioja"
        }, {
          "code": "AR-M",
          "represent": "Mendoza"
        }, {
          "code": "AR-N",
          "represent": "Misiones"
        }, {
          "code": "AR-Q",
          "represent": "Neuquén"
        }, {
          "code": "AR-R",
          "represent": "Río Negro"
        }, {
          "code": "AR-A",
          "represent": "Salta"
        }, {
          "code": "AR-J",
          "represent": "San Juan"
        }, {
          "code": "AR-D",
          "represent": "San Luis"
        }, {
          "code": "AR-Z",
          "represent": "Santa Cruz"
        }, {
          "code": "AR-S",
          "represent": "Santa Fe"
        }, {
          "code": "AR-G",
          "represent": "Santiago del Estero"
        }, {
          "code": "AR-V",
          "represent": "Tierra del Fuego"
        }, {
          "code": "AR-T",
          "represent": "Tucumán"
        }]
      }, {
        "name": "Armenia",
        "code": "AM",
        "divisions": [{
          "code": "AM-ER",
          "represent": "Erevan"
        }, {
          "code": "AM-AG",
          "represent": "Aragacotn"
        }, {
          "code": "AM-AR",
          "represent": "Ararat"
        }, {
          "code": "AM-AV",
          "represent": "Armavir"
        }, {
          "code": "AM-GR",
          "represent": "Geģark'unik'"
        }, {
          "code": "AM-KT",
          "represent": "Kotayk'"
        }, {
          "code": "AM-LO",
          "represent": "Loŕy"
        }, {
          "code": "AM-SH",
          "represent": "Širak"
        }, {
          "code": "AM-SU",
          "represent": "Syunik'"
        }, {
          "code": "AM-TV",
          "represent": "Tavuš"
        }, {
          "code": "AM-VD",
          "represent": "Vayoc Jor"
        }]
      }, {
        "name": "Aruba",
        "code": "AW",
        "divisions": [{
          "code": "AW-AW",
          "represent": "Aruba"
        }]
      }, {
        "name": "Australia",
        "code": "AU",
        "divisions": [{
          "code": "AU-NSW",
          "represent": "New South Wales"
        }, {
          "code": "AU-QLD",
          "represent": "Queensland"
        }, {
          "code": "AU-SA",
          "represent": "South Australia"
        }, {
          "code": "AU-TAS",
          "represent": "Tasmania"
        }, {
          "code": "AU-VIC",
          "represent": "Victoria"
        }, {
          "code": "AU-WA",
          "represent": "Western Australia"
        }, {
          "code": "AU-ACT",
          "represent": "Australian Capital Territory"
        }, {
          "code": "AU-NT",
          "represent": "Northern Territory"
        }]
      }, {
        "name": "Austria",
        "code": "AT",
        "divisions": [{
          "code": "AT-1",
          "represent": "Burgenland"
        }, {
          "code": "AT-2",
          "represent": "Kärnten"
        }, {
          "code": "AT-3",
          "represent": "Niederösterreich"
        }, {
          "code": "AT-4",
          "represent": "Oberösterreich"
        }, {
          "code": "AT-5",
          "represent": "Salzburg"
        }, {
          "code": "AT-6",
          "represent": "Steiermark"
        }, {
          "code": "AT-7",
          "represent": "Tirol"
        }, {
          "code": "AT-8",
          "represent": "Vorarlberg"
        }, {
          "code": "AT-9",
          "represent": "Wien"
        }]
      }, {
        "name": "Azerbaijan",
        "code": "AZ",
        "divisions": [{
          "code": "AZ-MM",
          "represent": "Naxçivan"
        }, {
          "code": "AZ-AB",
          "represent": "Äli Bayramli"
        }, {
          "code": "AZ-BA",
          "represent": "Baki"
        }, {
          "code": "AZ-GA",
          "represent": "Gäncä"
        }, {
          "code": "AZ-LA",
          "represent": "Länkäran"
        }, {
          "code": "AZ-MI",
          "represent": "Mingäçevir"
        }, {
          "code": "AZ-NA",
          "represent": "Naftalan"
        }, {
          "code": "AZ-SA",
          "represent": "Şäki"
        }, {
          "code": "AZ-SM",
          "represent": "Sumqayit"
        }, {
          "code": "AZ-SS",
          "represent": "Şuşa"
        }, {
          "code": "AZ-XA",
          "represent": "Xankändi"
        }, {
          "code": "AZ-YE",
          "represent": "Yevlax"
        }, {
          "code": "AZ-ABS",
          "represent": "Abşeron"
        }, {
          "code": "AZ-AGC",
          "represent": "Ağcabädi"
        }, {
          "code": "AZ-AGM",
          "represent": "Ağdam"
        }, {
          "code": "AZ-AGS",
          "represent": "Ağdas"
        }, {
          "code": "AZ-AGA",
          "represent": "Ağstafa"
        }, {
          "code": "AZ-AGU",
          "represent": "Ağsu"
        }, {
          "code": "AZ-AST",
          "represent": "Astara"
        }, {
          "code": "AZ-BAB",
          "represent": "Babäk"
        }, {
          "code": "AZ-BAL",
          "represent": "Balakän"
        }, {
          "code": "AZ-BAR",
          "represent": "Bärdä"
        }, {
          "code": "AZ-BEY",
          "represent": "Beyläqan"
        }, {
          "code": "AZ-BIL",
          "represent": "Biläsuvar"
        }, {
          "code": "AZ-CAB",
          "represent": "Cäbrayil"
        }, {
          "code": "AZ-CAL",
          "represent": "Cälilabad"
        }, {
          "code": "AZ-CUL",
          "represent": "Culfa"
        }, {
          "code": "AZ-DAS",
          "represent": "Daşkäsän"
        }, {
          "code": "AZ-DAV",
          "represent": "Däväçi"
        }, {
          "code": "AZ-FUZ",
          "represent": "Füzuli"
        }, {
          "code": "AZ-GAD",
          "represent": "Gädäbäy"
        }, {
          "code": "AZ-GOR",
          "represent": "Goranboy"
        }, {
          "code": "AZ-GOY",
          "represent": "Göyçay"
        }, {
          "code": "AZ-HAC",
          "represent": "Haciqabul"
        }, {
          "code": "AZ-IMI",
          "represent": "Imişli"
        }, {
          "code": "AZ-ISM",
          "represent": "Ismayilli"
        }, {
          "code": "AZ-KAL",
          "represent": "Kälbäcär"
        }, {
          "code": "AZ-KUR",
          "represent": "Kürdämir"
        }, {
          "code": "AZ-LAC",
          "represent": "Laçin"
        }, {
          "code": "AZ-LAN",
          "represent": "Länkäran"
        }, {
          "code": "AZ-LER",
          "represent": "Lerik"
        }, {
          "code": "AZ-MAS",
          "represent": "Masalli"
        }, {
          "code": "AZ-NEF",
          "represent": "Neftçala"
        }, {
          "code": "AZ-OGU",
          "represent": "Oğuz"
        }, {
          "code": "AZ-ORD",
          "represent": "Ordubad"
        }, {
          "code": "AZ-QAB",
          "represent": "Qäbälä"
        }, {
          "code": "AZ-QAX",
          "represent": "Qax"
        }, {
          "code": "AZ-QAZ",
          "represent": "Qazax"
        }, {
          "code": "AZ-QOB",
          "represent": "Qobustan"
        }, {
          "code": "AZ-QBA",
          "represent": "Quba"
        }, {
          "code": "AZ-QBI",
          "represent": "Qubadlı"
        }, {
          "code": "AZ-QUS",
          "represent": "Qusar"
        }, {
          "code": "AZ-SAT",
          "represent": "Saatli"
        }, {
          "code": "AZ-SAB",
          "represent": "Sabirabad"
        }, {
          "code": "AZ-SAD",
          "represent": "Sädäräk"
        }, {
          "code": "AZ-SAH",
          "represent": "Şahbuz"
        }, {
          "code": "AZ-SAK",
          "represent": "Şäki"
        }, {
          "code": "AZ-SAL",
          "represent": "Salyan"
        }, {
          "code": "AZ-SMI",
          "represent": "Şamaxı"
        }, {
          "code": "AZ-SKR",
          "represent": "Şämkir"
        }, {
          "code": "AZ-SMX",
          "represent": "Samux"
        }, {
          "code": "AZ-SAR",
          "represent": "Şärur"
        }, {
          "code": "AZ-SIY",
          "represent": "Siyäzän"
        }, {
          "code": "AZ-SUS",
          "represent": "Şuşa"
        }, {
          "code": "AZ-TAR",
          "represent": "Tärtär"
        }, {
          "code": "AZ-TOV",
          "represent": "Tovuz"
        }, {
          "code": "AZ-UCA",
          "represent": "Ucar"
        }, {
          "code": "AZ-XAC",
          "represent": "Xaçmaz"
        }, {
          "code": "AZ-XAN",
          "represent": "Xanlar"
        }, {
          "code": "AZ-XIZ",
          "represent": "Xizi"
        }, {
          "code": "AZ-XCI",
          "represent": "Xocalı"
        }, {
          "code": "AZ-XVD",
          "represent": "Xocavänd"
        }, {
          "code": "AZ-YAR",
          "represent": "Yardimli"
        }, {
          "code": "AZ-YEV",
          "represent": "Yevlax"
        }, {
          "code": "AZ-ZAN",
          "represent": "Zängılan"
        }, {
          "code": "AZ-ZAQ",
          "represent": "Zaqatala"
        }, {
          "code": "AZ-ZAR",
          "represent": "Zärdab"
        }]
      }, {
        "name": "Bahamas",
        "code": "BS",
        "divisions": [{
          "code": "BS-AC",
          "represent": "Acklins and Crooked Islands"
        }, {
          "code": "BS-BI",
          "represent": "Bimini"
        }, {
          "code": "BS-CI",
          "represent": "Cat Island"
        }, {
          "code": "BS-EX",
          "represent": "Exuma"
        }, {
          "code": "BS-FP",
          "represent": "Freeport"
        }, {
          "code": "BS-FC",
          "represent": "Fresh Creek"
        }, {
          "code": "BS-GH",
          "represent": "Governor's Harbour"
        }, {
          "code": "BS-GT",
          "represent": "Green Turtle Cay"
        }, {
          "code": "BS-HI",
          "represent": "Harbour Island"
        }, {
          "code": "BS-HR",
          "represent": "High Rock"
        }, {
          "code": "BS-IN",
          "represent": "Inagua"
        }, {
          "code": "BS-KB",
          "represent": "Kemps Bay"
        }, {
          "code": "BS-LI",
          "represent": "Long Island"
        }, {
          "code": "BS-MH",
          "represent": "Marsh Harbour"
        }, {
          "code": "BS-MG",
          "represent": "Mayaguana"
        }, {
          "code": "BS-NP",
          "represent": "New Providence"
        }, {
          "code": "BS-NB",
          "represent": "Nicholls Town and Berry Islands"
        }, {
          "code": "BS-RI",
          "represent": "Ragged Island"
        }, {
          "code": "BS-RS",
          "represent": "Rock Sound"
        }, {
          "code": "BS-SP",
          "represent": "Sandy Point"
        }, {
          "code": "BS-SR",
          "represent": "San Salvador and Rum Cay"
        }]
      }, {
        "name": "Bahrain",
        "code": "BH",
        "divisions": [{
          "code": "BH-01",
          "represent": "Al Ḩadd"
        }, {
          "code": "BH-03",
          "represent": "Al Manāmah"
        }, {
          "code": "BH-10",
          "represent": "Al Minţaqah al Gharbīyah"
        }, {
          "code": "BH-07",
          "represent": "Al Minţaqah al Wusţa"
        }, {
          "code": "BH-05",
          "represent": "Al Minţaqah ash Shamālīyah"
        }, {
          "code": "BH-02",
          "represent": "Al Muḩarraq"
        }, {
          "code": "BH-09",
          "represent": "Ar Rifā‘"
        }, {
          "code": "BH-04",
          "represent": "Jidd Ḩafş"
        }, {
          "code": "BH-12",
          "represent": "Madīnat Ḩamad"
        }, {
          "code": "BH-08",
          "represent": "Madīnat ‘Īsá"
        }, {
          "code": "BH-11",
          "represent": "Minţaqat Juzur Ḩawār"
        }, {
          "code": "BH-06",
          "represent": "Sitrah"
        }]
      }, {
        "name": "Bangladesh",
        "code": "BD",
        "divisions": [{
          "code": "BD-1",
          "represent": "Barisal bibhag"
        }, {
          "code": "BD-1B",
          "represent": "Barisal anchal"
        }, {
          "code": "BD-1Q",
          "represent": "Patuakhali anchal"
        }, {
          "code": "BD-2",
          "represent": "Chittagong bibhag"
        }, {
          "code": "BD-2A",
          "represent": "Bandarban anchal"
        }, {
          "code": "BD-2D",
          "represent": "Chittagong anchal"
        }, {
          "code": "BD-2E",
          "represent": "Chittagong Hill Tracts"
        }, {
          "code": "BD-2F",
          "represent": "Comilla anchal"
        }, {
          "code": "BD-2O",
          "represent": "Noakhali anchal"
        }, {
          "code": "BD-2T",
          "represent": "Sylhet anchal"
        }, {
          "code": "BD-3",
          "represent": "Dhaka bibhag"
        }, {
          "code": "BD-3G",
          "represent": "Dhaka anchal"
        }, {
          "code": "BD-3I",
          "represent": "Faridpur anchal"
        }, {
          "code": "BD-3J",
          "represent": "Jamalpur anchal"
        }, {
          "code": "BD-3N",
          "represent": "Mymensingh anchal"
        }, {
          "code": "BD-3U",
          "represent": "Tangail anchal"
        }, {
          "code": "BD-4",
          "represent": "Khulna bibhag"
        }, {
          "code": "BD-4K",
          "represent": "Jessore anchal"
        }, {
          "code": "BD-4L",
          "represent": "Khulna anchal"
        }, {
          "code": "BD-4M",
          "represent": "Khustia anchal"
        }, {
          "code": "BD-5",
          "represent": "Rajshahi bibhag"
        }, {
          "code": "BD-5C",
          "represent": "Bogra anchal"
        }, {
          "code": "BD-5H",
          "represent": "Dinajpur anchal"
        }, {
          "code": "BD-5P",
          "represent": "Pabna anchal"
        }, {
          "code": "BD-5R",
          "represent": "Rajshahi anchal"
        }, {
          "code": "BD-5S",
          "represent": "Rangpur anchal"
        }]
      }, {
        "name": "Barbados",
        "code": "BB",
        "divisions": [{
          "code": "BB-BB",
          "represent": "Barbados"
        }]
      }, {
        "name": "Belarus",
        "code": "BY",
        "divisions": [{
          "code": "BY-BR",
          "represent": "Brestskaya voblasts'"
        }, {
          "code": "BY-HO",
          "represent": "Homyel'skaya voblasts’"
        }, {
          "code": "BY-HR",
          "represent": "Hrodnenskaya voblasts'"
        }, {
          "code": "BY-MA",
          "represent": "Mahilyowskaya voblasts'"
        }, {
          "code": "BY-MI",
          "represent": "Minskaya voblasts'"
        }, {
          "code": "BY-VI",
          "represent": "Vitsyebskaya voblasts'"
        }]
      }, {
        "name": "Belgium",
        "code": "BE",
        "divisions": [{
          "code": "BE-BRU",
          "represent": "Bruxelles-Capitale, Region de (fr), Brussels Hoofdstedelijk Gewest (nl)"
        }, {
          "code": "BE-VLG",
          "represent": "Vlaamse Gewest (nl)"
        }, {
          "code": "BE-VAN",
          "represent": "Antwerpen (nl)"
        }, {
          "code": "BE-VLI",
          "represent": "Limburg (nl)"
        }, {
          "code": "BE-VOV",
          "represent": "Oost-Vlaanderen (nl)"
        }, {
          "code": "BE-VBR",
          "represent": "Vlaams Brabant (nl)"
        }, {
          "code": "BE-VWV",
          "represent": "West-Vlaanderen (nl)"
        }, {
          "code": "BE-WAL",
          "represent": "Wallonne, Region (fr)"
        }, {
          "code": "BE-WBR",
          "represent": "Brabant Wallon (fr)"
        }, {
          "code": "BE-WHT",
          "represent": "Hainaut (fr)"
        }, {
          "code": "BE-WLG",
          "represent": "Liège (fr)"
        }, {
          "code": "BE-WLX",
          "represent": "Luxembourg (fr)"
        }, {
          "code": "BE-WNA",
          "represent": "Namur (fr)"
        }]
      }, {
        "name": "Belize",
        "code": "BZ",
        "divisions": [{
          "code": "BZ-BZ",
          "represent": "Belize"
        }, {
          "code": "BZ-CY",
          "represent": "Cayo"
        }, {
          "code": "BZ-CZL",
          "represent": "Corozal"
        }, {
          "code": "BZ-OW",
          "represent": "Orange Walk"
        }, {
          "code": "BZ-SC",
          "represent": "Stann Creek"
        }, {
          "code": "BZ-TOL",
          "represent": "Toledo"
        }]
      }, {
        "name": "Benin",
        "code": "BJ",
        "divisions": [{
          "code": "BJ-AK",
          "represent": "Atakora"
        }, {
          "code": "BJ-AQ",
          "represent": "Atlantique"
        }, {
          "code": "BJ-BO",
          "represent": "Borgou"
        }, {
          "code": "BJ-MO",
          "represent": "Mono"
        }, {
          "code": "BJ-OU",
          "represent": "Ouémé"
        }, {
          "code": "BJ-ZO",
          "represent": "Zou"
        }]
      }, {
        "name": "Bermuda",
        "code": "BM",
        "divisions": [{
          "code": "BM-BM",
          "represent": "Bermuda"
        }]
      }, {
        "name": "Bhutan",
        "code": "BT",
        "divisions": [{
          "code": "BT-33",
          "represent": "Bumthang"
        }, {
          "code": "BT-12",
          "represent": "Chhukha"
        }, {
          "code": "BT-22",
          "represent": "Dagana"
        }, {
          "code": "BT-GA",
          "represent": "Gasa"
        }, {
          "code": "BT-13",
          "represent": "Ha"
        }, {
          "code": "BT-44",
          "represent": "Lhuentse"
        }, {
          "code": "BT-42",
          "represent": "Monggar"
        }, {
          "code": "BT-11",
          "represent": "Paro"
        }, {
          "code": "BT-43",
          "represent": "Pemagatshel"
        }, {
          "code": "BT-23",
          "represent": "Punakha"
        }, {
          "code": "BT-45",
          "represent": "Samdrup Jongkha"
        }, {
          "code": "BT-14",
          "represent": "Samtse"
        }, {
          "code": "BT-31",
          "represent": "Sarpang"
        }, {
          "code": "BT-15",
          "represent": "Thimphu"
        }, {
          "code": "BT-41",
          "represent": "Trashigang"
        }, {
          "code": "BT-TY",
          "represent": "Trashi Yangtse"
        }, {
          "code": "BT-32",
          "represent": "Trongsa"
        }, {
          "code": "BT-21",
          "represent": "Tsirang"
        }, {
          "code": "BT-24",
          "represent": "Wangdue Phodrang"
        }, {
          "code": "BT-34",
          "represent": "Zhemgang"
        }]
      }, {
        "name": "Bolivia",
        "code": "BO",
        "divisions": [{
          "code": "BO-C",
          "represent": "Cochabamba"
        }, {
          "code": "BO-H",
          "represent": "Chuquisaca"
        }, {
          "code": "BO-B",
          "represent": "El Beni"
        }, {
          "code": "BO-L",
          "represent": "La Paz"
        }, {
          "code": "BO-O",
          "represent": "Oruro"
        }, {
          "code": "BO-N",
          "represent": "Pando"
        }, {
          "code": "BO-P",
          "represent": "Potosi"
        }, {
          "code": "BO-S",
          "represent": "Santa Cruz"
        }, {
          "code": "BO-T",
          "represent": "Tarija"
        }]
      }, {
        "name": "Bosnia & Herzegovina",
        "code": "BA",
        "divisions": [{
          "code": "BA-BIH",
          "represent": "Federacija Bosna i Hercegovina"
        }, {
          "code": "BA-SRP",
          "represent": "Republika Srpska"
        }]
      }, {
        "name": "Botswana",
        "code": "BW",
        "divisions": [{
          "code": "BW-CE",
          "represent": "Central [Serowe-Palapye]"
        }, {
          "code": "BW-CH",
          "represent": "Chobe"
        }, {
          "code": "BW-GH",
          "represent": "Ghanzi"
        }, {
          "code": "BW-KG",
          "represent": "Kgalagadi"
        }, {
          "code": "BW-KL",
          "represent": "Kgatleng"
        }, {
          "code": "BW-KW",
          "represent": "Kweneng"
        }, {
          "code": "BW-NG",
          "represent": "Ngamiland [North-West]"
        }, {
          "code": "BW-NE",
          "represent": "North-East"
        }, {
          "code": "BW-SE",
          "represent": "South-East"
        }, {
          "code": "BW-SO",
          "represent": "Southern [Ngwaketse]"
        }]
      }, {
        "name": "Bouvet Island",
        "code": "BV",
        "divisions": [{
          "code": "BV-BV",
          "represent": "Bouvet Island"
        }]
      }, {
        "name": "Brazil",
        "code": "BR",
        "divisions": [{
          "code": "BR-DF",
          "represent": "Distrito Federal"
        }, {
          "code": "BR-AC",
          "represent": "Acre"
        }, {
          "code": "BR-AL",
          "represent": "Alagoas"
        }, {
          "code": "BR-AP",
          "represent": "Amapá"
        }, {
          "code": "BR-AM",
          "represent": "Amazonas"
        }, {
          "code": "BR-BA",
          "represent": "Bahia"
        }, {
          "code": "BR-CE",
          "represent": "Ceará"
        }, {
          "code": "BR-ES",
          "represent": "Espírito Santo"
        }, {
          "code": "BR-GO",
          "represent": "Goiás"
        }, {
          "code": "BR-MA",
          "represent": "Maranhāo"
        }, {
          "code": "BR-MT",
          "represent": "Mato Grosso"
        }, {
          "code": "BR-MS",
          "represent": "Mato Grosso do Sul"
        }, {
          "code": "BR-MG",
          "represent": "Minas Gerais"
        }, {
          "code": "BR-PA",
          "represent": "Pará"
        }, {
          "code": "BR-PB",
          "represent": "Paraíba"
        }, {
          "code": "BR-PR",
          "represent": "Paraná"
        }, {
          "code": "BR-PE",
          "represent": "Pernambuco"
        }, {
          "code": "BR-PI",
          "represent": "Piauí"
        }, {
          "code": "BR-RJ",
          "represent": "Rio de Janeiro"
        }, {
          "code": "BR-RN",
          "represent": "Rio Grande do Norte"
        }, {
          "code": "BR-RS",
          "represent": "Rio Grande do Sul"
        }, {
          "code": "BR-R0",
          "represent": "Rondônia"
        }, {
          "code": "BR-RR",
          "represent": "Roraima"
        }, {
          "code": "BR-SC",
          "represent": "Santa Catarina"
        }, {
          "code": "BR-SP",
          "represent": "São Paulo"
        }, {
          "code": "BR-SE",
          "represent": "Sergipe"
        }, {
          "code": "BR-TO",
          "represent": "Tocantins"
        }]
      }, {
        "name": "British Indian Ocean Territory",
        "code": "IO",
        "divisions": [{
          "code": "IO-IO",
          "represent": "British Indian Ocean Territory"
        }]
      }, {
        "name": "Brunei Darussalam",
        "code": "BN",
        "divisions": [{
          "code": "BN-BE",
          "represent": "Belait"
        }, {
          "code": "BN-BM",
          "represent": "Brunei-Muara"
        }, {
          "code": "BN-TE",
          "represent": "Temburong"
        }, {
          "code": "BN-TU",
          "represent": "Tutong"
        }]
      }, {
        "name": "Bulgaria",
        "code": "BG",
        "divisions": [{
          "code": "BG-02",
          "represent": "Burgas"
        }, {
          "code": "BG-09",
          "represent": "Haskovo"
        }, {
          "code": "BG-04",
          "represent": "Loveč"
        }, {
          "code": "BG-05",
          "represent": "Montana"
        }, {
          "code": "BG-06",
          "represent": "Plovdiv"
        }, {
          "code": "BG-07",
          "represent": "Ruse"
        }, {
          "code": "BG-08",
          "represent": "Sofija"
        }, {
          "code": "BG-01",
          "represent": "Sofija-Grad"
        }, {
          "code": "BG-03",
          "represent": "Varna"
        }]
      }, {
        "name": "Burkina Faso",
        "code": "BF",
        "divisions": [{
          "code": "BF-BAL",
          "represent": "Balé"
        }, {
          "code": "BF-BAM",
          "represent": "Bam"
        }, {
          "code": "BF-BAN",
          "represent": "Banwa"
        }, {
          "code": "BF-BAZ",
          "represent": "Bazèga"
        }, {
          "code": "BF-BGR",
          "represent": "Bougouriba"
        }, {
          "code": "BF-BLG",
          "represent": "Boulgou"
        }, {
          "code": "BF-BLK",
          "represent": "Boulkiemdé"
        }, {
          "code": "BF-COM",
          "represent": "Comoé"
        }, {
          "code": "BF-GAN",
          "represent": "Ganzourgou"
        }, {
          "code": "BF-GNA",
          "represent": "Gnagna"
        }, {
          "code": "BF-GOU",
          "represent": "Gourma"
        }, {
          "code": "BF-HOU",
          "represent": "Houet"
        }, {
          "code": "BF-IOB",
          "represent": "Ioba"
        }, {
          "code": "BF-KAD",
          "represent": "Kadiogo"
        }, {
          "code": "BF-KEN",
          "represent": "Kénédougou"
        }, {
          "code": "BF-KMD",
          "represent": "Komondjari"
        }, {
          "code": "BF-KMP",
          "represent": "Kompienga"
        }, {
          "code": "BF-KOS",
          "represent": "Kossi"
        }, {
          "code": "BF-KOP",
          "represent": "Koulpélogo"
        }, {
          "code": "BF-KOT",
          "represent": "Kouritenga"
        }, {
          "code": "BF-KOW",
          "represent": "Kourwéogo"
        }, {
          "code": "BF-LER",
          "represent": "Léraba"
        }, {
          "code": "BF-LOR",
          "represent": "Loroum"
        }, {
          "code": "BF-MOU",
          "represent": "Mouhoun"
        }, {
          "code": "BF-NAO",
          "represent": "Nahouri"
        }, {
          "code": "BF-NAM",
          "represent": "Namentenga"
        }, {
          "code": "BF-NAY",
          "represent": "Nayala"
        }, {
          "code": "BF-NOU",
          "represent": "Noumbiel"
        }, {
          "code": "BF-OUB",
          "represent": "Oubritenga"
        }, {
          "code": "BF-OUD",
          "represent": "Oudalan"
        }, {
          "code": "BF-PAS",
          "represent": "Passoré"
        }, {
          "code": "BF-PON",
          "represent": "Poni"
        }, {
          "code": "BF-SNG",
          "represent": "Sanguié"
        }, {
          "code": "BF-SMT",
          "represent": "Sanmatenga"
        }, {
          "code": "BF-SEN",
          "represent": "Séno"
        }, {
          "code": "BF-SIS",
          "represent": "Sissili"
        }, {
          "code": "BF-SOM",
          "represent": "Soum"
        }, {
          "code": "BF-SOR",
          "represent": "Sourou"
        }, {
          "code": "BF-TAP",
          "represent": "Tapoa"
        }, {
          "code": "BF-TUI",
          "represent": "Tui"
        }, {
          "code": "BF-YAG",
          "represent": "Yagha"
        }, {
          "code": "BF-YAT",
          "represent": "Yatenga"
        }, {
          "code": "BF-ZIR",
          "represent": "Ziro"
        }, {
          "code": "BF-ZON",
          "represent": "Zondoma"
        }, {
          "code": "BF-ZOU",
          "represent": "Zoundwéogo"
        }]
      }, {
        "name": "Burundi",
        "code": "BI",
        "divisions": [{
          "code": "BI-BB",
          "represent": "Bubanza"
        }, {
          "code": "BI-BJ",
          "represent": "Bujumbura"
        }, {
          "code": "BI-BR",
          "represent": "Bururi"
        }, {
          "code": "BI-CA",
          "represent": "Cankuzo"
        }, {
          "code": "BI-CI",
          "represent": "Cibitoke"
        }, {
          "code": "BI-GI",
          "represent": "Gitega"
        }, {
          "code": "BI-KR",
          "represent": "Karuzi"
        }, {
          "code": "BI-KY",
          "represent": "Kayanza"
        }, {
          "code": "BI-KI",
          "represent": "Kirundo"
        }, {
          "code": "BI-MA",
          "represent": "Makamba"
        }, {
          "code": "BI-MU",
          "represent": "Muramvya"
        }, {
          "code": "BI-MY",
          "represent": "Muyinga"
        }, {
          "code": "BI-NG",
          "represent": "Ngozi"
        }, {
          "code": "BI-RT",
          "represent": "Rutana"
        }, {
          "code": "BI-RY",
          "represent": "Ruyigi"
        }]
      }, {
        "name": "Cambodia",
        "code": "KH",
        "divisions": [{
          "code": "KH-23",
          "represent": "Krong Kaeb [Krŏng Kêb]"
        }, {
          "code": "KH-18",
          "represent": "Krong Preah Sihanouk [Krŏng Preăh Sihanouk]"
        }, {
          "code": "KH-12",
          "represent": "Phnom Penh [Phnum Pénh]"
        }, {
          "code": "KH-2",
          "represent": "Baat Dambang [Bătdâmbâng]"
        }, {
          "code": "KH-1",
          "represent": "Banteay Mean Chey [Bântéay Méanchey]"
        }, {
          "code": "KH-3",
          "represent": "Kampong Chaam [Kâmpóng Cham]"
        }, {
          "code": "KH-4",
          "represent": "Kampong Chhnang [Kâmpóng Chhnăng]"
        }, {
          "code": "KH-5",
          "represent": "Kampong Spueu [Kâmpóng Spœ]"
        }, {
          "code": "KH-6",
          "represent": "Kampong Thum [Kâmpóng Thum]"
        }, {
          "code": "KH-7",
          "represent": "Kampot [Kâmpôt]"
        }, {
          "code": "KH-8",
          "represent": "Kandaal [Kândal]"
        }, {
          "code": "KH-10",
          "represent": "Kracheh [Krâchéh]"
        }, {
          "code": "KH-11",
          "represent": "Mond01 Kiri [Môndól Kiri]"
        }, {
          "code": "KH-22",
          "represent": "Otdar Mean Chey [Ŏtdâr Méanchey]"
        }, {
          "code": "KH-15",
          "represent": "Pousaat [Poŭthĭsăt]"
        }, {
          "code": "KH-13",
          "represent": "Preah Vihear [Preăh Vihéar]"
        }, {
          "code": "KH-14",
          "represent": "Prey Veaeng [Prey Vêng]"
        }, {
          "code": "KH-16",
          "represent": "Rotanak Kiri [Rôtânôkiri]"
        }, {
          "code": "KH-17",
          "represent": "Siem Reab [Siĕmréab]"
        }, {
          "code": "KH-19",
          "represent": "Stueng Traeng [Stœng Trêng]"
        }, {
          "code": "KH-20",
          "represent": "Svaay Rieng [Svay Riĕng]"
        }, {
          "code": "KH-21",
          "represent": "Taakaev [Takêv]"
        }]
      }, {
        "name": "Cameroon",
        "code": "CM",
        "divisions": [{
          "code": "CM-AD",
          "represent": "Adamaoua"
        }, {
          "code": "CM-CE",
          "represent": "Centre"
        }, {
          "code": "CM-ES",
          "represent": "Est"
        }, {
          "code": "CM-EN",
          "represent": "Far North"
        }, {
          "code": "CM-LT",
          "represent": "Littoral"
        }, {
          "code": "CM-NO",
          "represent": "North"
        }, {
          "code": "CM-NW",
          "represent": "North-West"
        }, {
          "code": "CM-SU",
          "represent": "South"
        }, {
          "code": "CM-SW",
          "represent": "South-West"
        }, {
          "code": "CM-OU",
          "represent": "West"
        }]
      }, {
        "name": "Canada",
        "code": "CA",
        "divisions": [{
          "code": "CA-AB",
          "represent": "Alberta"
        }, {
          "code": "CA-BC",
          "represent": "British Columbia (Colombie-Britannique)"
        }, {
          "code": "CA-MB",
          "represent": "Manitoba"
        }, {
          "code": "CA-NB",
          "represent": "New Brunswick (Nouveau-Brunswick)"
        }, {
          "code": "CA-NL",
          "represent": "Newfoundland and Labrador (Terre-Neuve)"
        }, {
          "code": "CA-NS",
          "represent": "Nova Scotia (Nouvelle-Écosse)"
        }, {
          "code": "CA-ON",
          "represent": "Ontario"
        }, {
          "code": "CA-PE",
          "represent": "Prince Edward Island (Île-du-Prince-Édouard)"
        }, {
          "code": "CA-QC",
          "represent": "Quebec (Québec)"
        }, {
          "code": "CA-SK",
          "represent": "Saskatchewan"
        }, {
          "code": "CA-NT",
          "represent": "Northwest Territories (Territoires du Nord-Ouest)"
        }, {
          "code": "CA-NU",
          "represent": "Nunavut"
        }, {
          "code": "CA-YT",
          "represent": "Yukon Territory (Teritoire du Yukon)"
        }]
      }, {
        "name": "Cape Verde",
        "code": "CV",
        "divisions": [{
          "code": "CV-B",
          "represent": "Ilhas de Barlavento"
        }, {
          "code": "CV-BV",
          "represent": "Boa Vista"
        }, {
          "code": "CV-PA",
          "represent": "Paul"
        }, {
          "code": "CV-PN",
          "represent": "Porto Novo"
        }, {
          "code": "CV-RG",
          "represent": "Ribeira Grande"
        }, {
          "code": "CV-SL",
          "represent": "Sal"
        }, {
          "code": "CV-SN",
          "represent": "Sāo Nicolau"
        }, {
          "code": "CV-SV",
          "represent": "Sāo Vicente"
        }, {
          "code": "CV-S",
          "represent": "Ilhas de Sotavento"
        }, {
          "code": "CV-BR",
          "represent": "Brava"
        }, {
          "code": "CV-FO",
          "represent": "Fogo"
        }, {
          "code": "CV-MA",
          "represent": "Maio"
        }, {
          "code": "CV-PR",
          "represent": "Praia"
        }, {
          "code": "CV-CA",
          "represent": "Santa Catarina"
        }, {
          "code": "CV-CR",
          "represent": "Santa Cruz"
        }, {
          "code": "CV-TA",
          "represent": "Tarrafal"
        }]
      }, {
        "name": "Cayman Islands",
        "code": "KY",
        "divisions": [{
          "code": "KY-KY",
          "represent": "Cayman Islands"
        }]
      }, {
        "name": "Central African Republic",
        "code": "CF",
        "divisions": [{
          "code": "CF-BGF",
          "represent": "Bangui"
        }, {
          "code": "CF-BB",
          "represent": "Bamingui-Bangoran"
        }, {
          "code": "CF-BK",
          "represent": "Basse-Kotto"
        }, {
          "code": "CF-HK",
          "represent": "Haute-Kotto"
        }, {
          "code": "CF-HM",
          "represent": "Haut-Mbomou"
        }, {
          "code": "CF-KG",
          "represent": "Kémo"
        }, {
          "code": "CF-LB",
          "represent": "Lobaye"
        }, {
          "code": "CF-HS",
          "represent": "Mambéré-Kadéï"
        }, {
          "code": "CF-MB",
          "represent": "Mbomou"
        }, {
          "code": "CF-KB",
          "represent": "Nana-Grébizi"
        }, {
          "code": "CF-NM",
          "represent": "Nana-Mambéré"
        }, {
          "code": "CF-MP",
          "represent": "Ombella-Mpoko"
        }, {
          "code": "CF-UK",
          "represent": "Ouaka"
        }, {
          "code": "CF-AC",
          "represent": "Ouham"
        }, {
          "code": "CF-OP",
          "represent": "Ouham-Pendé"
        }, {
          "code": "CF-SE",
          "represent": "Sangha-Mbaéré"
        }, {
          "code": "CF-VK",
          "represent": "Vakaga"
        }]
      }, {
        "name": "Chad",
        "code": "TD",
        "divisions": [{
          "code": "TD-BA",
          "represent": "Batha"
        }, {
          "code": "TD-BI",
          "represent": "Biltine"
        }, {
          "code": "TD-BET",
          "represent": "Borkou-Ennedi-Tibesti"
        }, {
          "code": "TD-CB",
          "represent": "Chari-Baguirmi"
        }, {
          "code": "TD-GR",
          "represent": "Guéra"
        }, {
          "code": "TD-KA",
          "represent": "Kanem"
        }, {
          "code": "TD-LC",
          "represent": "Lac"
        }, {
          "code": "TD-LO",
          "represent": "Logone-Occidental"
        }, {
          "code": "TD-LR",
          "represent": "Logone-Oriental"
        }, {
          "code": "TD-MK",
          "represent": "Mayo-Kébbi"
        }, {
          "code": "TD-MC",
          "represent": "Moyen-Chari"
        }, {
          "code": "TD-OD",
          "represent": "Ouaddaï"
        }, {
          "code": "TD-SA",
          "represent": "Salamat"
        }, {
          "code": "TD-TA",
          "represent": "Tandjilé"
        }]
      }, {
        "name": "Chile",
        "code": "CL",
        "divisions": [{
          "code": "CL-AI",
          "represent": "Aisén del General Carlos Ibáñiez del Campo"
        }, {
          "code": "CL-AN",
          "represent": "Antofagasta"
        }, {
          "code": "CL-AR",
          "represent": "Araucanía"
        }, {
          "code": "CL-AT",
          "represent": "Atacama"
        }, {
          "code": "CL-BI",
          "represent": "Bío-Bío"
        }, {
          "code": "CL-CO",
          "represent": "Coquimbo"
        }, {
          "code": "CL-LI",
          "represent": "Libertador General Bernardo O'Higgins"
        }, {
          "code": "CL-LL",
          "represent": "Los Lagos"
        }, {
          "code": "CL-MA",
          "represent": "Magallanes"
        }, {
          "code": "CL-ML",
          "represent": "Maule"
        }, {
          "code": "CL-RM",
          "represent": "Regíon Metropolitana de Santiago"
        }, {
          "code": "CL-TA",
          "represent": "Tarapacá"
        }, {
          "code": "CL-VS",
          "represent": "Valparaiso"
        }]
      }, {
        "name": "China",
        "code": "CN",
        "divisions": [{
          "code": "CN-11",
          "represent": "Beijing"
        }, {
          "code": "CN-50",
          "represent": "Chongqing"
        }, {
          "code": "CN-31",
          "represent": "Shanghai"
        }, {
          "code": "CN-12",
          "represent": "Tianjin"
        }, {
          "code": "CN-34",
          "represent": "Anhui"
        }, {
          "code": "CN-35",
          "represent": "Fujian"
        }, {
          "code": "CN-62",
          "represent": "Gansu"
        }, {
          "code": "CN-44",
          "represent": "Guangdong"
        }, {
          "code": "CN-52",
          "represent": "Guizhou"
        }, {
          "code": "CN-46",
          "represent": "Hainan"
        }, {
          "code": "CN-13",
          "represent": "Hebei"
        }, {
          "code": "CN-23",
          "represent": "Heilongjiang"
        }, {
          "code": "CN-41",
          "represent": "Henan"
        }, {
          "code": "CN-42",
          "represent": "Hubei"
        }, {
          "code": "CN-43",
          "represent": "Hunan"
        }, {
          "code": "CN-32",
          "represent": "Jiangsu"
        }, {
          "code": "CN-36",
          "represent": "Jiangxi"
        }, {
          "code": "CN-22",
          "represent": "Jilin"
        }, {
          "code": "CN-21",
          "represent": "Liaoning"
        }, {
          "code": "CN-63",
          "represent": "Qinghai"
        }, {
          "code": "CN-61",
          "represent": "Shaanxi"
        }, {
          "code": "CN-37",
          "represent": "Shandong"
        }, {
          "code": "CN-14",
          "represent": "Shanxi"
        }, {
          "code": "CN-51",
          "represent": "Sichuan"
        }, {
          "code": "CN-71",
          "represent": "Taiwan"
        }, {
          "code": "CN-53",
          "represent": "Yunnan"
        }, {
          "code": "CN-33",
          "represent": "Zhejiang"
        }, {
          "code": "CN-45",
          "represent": "Guangxi"
        }, {
          "code": "CN-15",
          "represent": "Nei Monggol"
        }, {
          "code": "CN-64",
          "represent": "Ningxia"
        }, {
          "code": "CN-65",
          "represent": "Xinjiang"
        }, {
          "code": "CN-54",
          "represent": "Xizang"
        }, {
          "code": "CN-91",
          "represent": "Hong Kong"
        }]
      }, {
        "name": "Christmas Island",
        "code": "CX",
        "divisions": [{
          "code": "CX-CX",
          "represent": "Christmas Island"
        }]
      }, {
        "name": "Cocos (Keeling) Islands",
        "code": "CC",
        "divisions": [{
          "code": "CC-CC",
          "represent": "Cocos (Keeling) Islands"
        }]
      }, {
        "name": "Colombia",
        "code": "CO",
        "divisions": [{
          "code": "CO-DC",
          "represent": "Distrito Capital de Santa Fe de Bogota"
        }, {
          "code": "CO-AMA",
          "represent": "Amazonas"
        }, {
          "code": "CO-ANT",
          "represent": "Antioguia"
        }, {
          "code": "CO-ARA",
          "represent": "Arauca"
        }, {
          "code": "CO-ATL",
          "represent": "Atlántico"
        }, {
          "code": "CO-BOL",
          "represent": "Bolívar"
        }, {
          "code": "CO-BOY",
          "represent": "Boyacá"
        }, {
          "code": "CO-CAL",
          "represent": "Caldas"
        }, {
          "code": "CO-CAQ",
          "represent": "Caquetá"
        }, {
          "code": "CO-CAS",
          "represent": "Casanare"
        }, {
          "code": "CO-CAU",
          "represent": "Cauca"
        }, {
          "code": "CO-CES",
          "represent": "Cesar"
        }, {
          "code": "CO-COR",
          "represent": "Córdoba"
        }, {
          "code": "CO-CUN",
          "represent": "Cundinamarca"
        }, {
          "code": "CO-CHO",
          "represent": "Chocó"
        }, {
          "code": "CO-GUA",
          "represent": "Guainía"
        }, {
          "code": "CO-GUV",
          "represent": "Guaviare"
        }, {
          "code": "CO-HUI",
          "represent": "Huila"
        }, {
          "code": "CO-LAG",
          "represent": "La Guajira"
        }, {
          "code": "CO-MAG",
          "represent": "Magdalena"
        }, {
          "code": "CO-MET",
          "represent": "Meta"
        }, {
          "code": "CO-NAR",
          "represent": "Nariño"
        }, {
          "code": "CO-NSA",
          "represent": "Norte de Santander"
        }, {
          "code": "CO-PUT",
          "represent": "Putumayo"
        }, {
          "code": "CO-QUI",
          "represent": "Quindío"
        }, {
          "code": "CO-RIS",
          "represent": "Risaralda"
        }, {
          "code": "CO-SAP",
          "represent": "San Andrés, Providencia y Santa Catalina"
        }, {
          "code": "CO-SAN",
          "represent": "Santander"
        }, {
          "code": "CO-SUC",
          "represent": "Sucre"
        }, {
          "code": "CO-TOL",
          "represent": "Tolima"
        }, {
          "code": "CO-VAC",
          "represent": "Valle del Cauca"
        }, {
          "code": "CO-VAU",
          "represent": "Vaupés"
        }, {
          "code": "CO-VID",
          "represent": "Vichada"
        }]
      }, {
        "name": "Comoros",
        "code": "KM",
        "divisions": [{
          "code": "KM-A",
          "represent": "Anjouan (Ndzouani)"
        }, {
          "code": "KM-G",
          "represent": "Grande Comore (Ngazidja)"
        }, {
          "code": "KM-M",
          "represent": "Mohéli (Moili)"
        }]
      }, {
        "name": "Congo",
        "code": "CG",
        "divisions": [{
          "code": "CG-BZV",
          "represent": "Brazzaville"
        }, {
          "code": "CG-11",
          "represent": "Bouenza"
        }, {
          "code": "CG-8",
          "represent": "Cuvette"
        }, {
          "code": "CG-15",
          "represent": "Cuvette-Ouest"
        }, {
          "code": "CG-5",
          "represent": "Kouilou"
        }, {
          "code": "CG-2",
          "represent": "Lékoumou"
        }, {
          "code": "CG-7",
          "represent": "Likouala"
        }, {
          "code": "CG-9",
          "represent": "Niari"
        }, {
          "code": "CG-14",
          "represent": "Plateaux"
        }, {
          "code": "CG-12",
          "represent": "Pool"
        }, {
          "code": "CG-13",
          "represent": "Sangha"
        }]
      }, {
        "name": "Congo, The Democratic Republic of the",
        "code": "CD",
        "divisions": [{
          "code": "CD-BN",
          "represent": "Bandundu"
        }, {
          "code": "CD-BC",
          "represent": "Bas-Congo"
        }, {
          "code": "CD-KW",
          "represent": "Kasai-Occidental"
        }, {
          "code": "CD-KE",
          "represent": "Kasai-Oriental"
        }, {
          "code": "CD-KA",
          "represent": "Katanga"
        }, {
          "code": "CD-KN",
          "represent": "Kinshasa"
        }, {
          "code": "CD-MA",
          "represent": "Maniema"
        }, {
          "code": "CD-NK",
          "represent": "Nord-Kivu"
        }, {
          "code": "CD-OR",
          "represent": "Orientale"
        }, {
          "code": "CD-SK",
          "represent": "Sud-Kivu"
        }, {
          "code": "CD-EQ",
          "represent": "Équateur"
        }, {
          "code": "CD-TO",
          "represent": "Tshopo"
        }]
      }, {
        "name": "Cook Islands",
        "code": "CK",
        "divisions": [{
          "code": "CK-CK",
          "represent": "Cook Islands"
        }]
      }, {
        "name": "Costa Rica",
        "code": "CR",
        "divisions": [{
          "code": "CR-A",
          "represent": "Alajuela"
        }, {
          "code": "CR-C",
          "represent": "Cartago"
        }, {
          "code": "CR-G",
          "represent": "Guanacaste"
        }, {
          "code": "CR-H",
          "represent": "Heredia"
        }, {
          "code": "CR-L",
          "represent": "Limón"
        }, {
          "code": "CR-P",
          "represent": "Puntarenas"
        }, {
          "code": "CR-SJ",
          "represent": "San José"
        }]
      }, {
        "name": "Cote D'ivoire (Ivory Coast)",
        "code": "CI",
        "divisions": [{
          "code": "CI-06",
          "represent": "18 Montagnes (Région des)"
        }, {
          "code": "CI-16",
          "represent": "Agnébi (Région de l')"
        }, {
          "code": "CI-09",
          "represent": "Bas-Sassandra (Région du)"
        }, {
          "code": "CI-10",
          "represent": "Denguélé (Région du)"
        }, {
          "code": "CI-02",
          "represent": "Haut-Sassandra (Région du)"
        }, {
          "code": "CI-07",
          "represent": "Lacs (Région des)"
        }, {
          "code": "CI-01",
          "represent": "Lagunes (Région des)"
        }, {
          "code": "CI-12",
          "represent": "Marahoué (Région de la)"
        }, {
          "code": "CI-05",
          "represent": "Moyen-Comoé (Région du)"
        }, {
          "code": "CI-11",
          "represent": "Nzi-Comoé (Région)"
        }, {
          "code": "CI-03",
          "represent": "Savanes (Région des)"
        }, {
          "code": "CI-15",
          "represent": "Sud-Bandama (Région du)"
        }, {
          "code": "CI-13",
          "represent": "Sud-Comoé (Région du)"
        }, {
          "code": "CI-04",
          "represent": "Vallée du Bandama (Région de la)"
        }, {
          "code": "CI-14",
          "represent": "Worodougou (Région du)"
        }, {
          "code": "CI-08",
          "represent": "Zanzan (Région du)"
        }]
      }, {
        "name": "Croatia (Hrvatska)",
        "code": "HR",
        "divisions": [{
          "code": "HR-07",
          "represent": "Bjelovarsko-bilogorska županija"
        }, {
          "code": "HR-12",
          "represent": "Brodsko-posavska županija"
        }, {
          "code": "HR-19",
          "represent": "Dubrovačko-neretvanska županija"
        }, {
          "code": "HR-18",
          "represent": "Istarska županija"
        }, {
          "code": "HR-04",
          "represent": "Karlovačka županija"
        }, {
          "code": "HR-06",
          "represent": "Koprivničkco-križevačka županija"
        }, {
          "code": "HR-02",
          "represent": "Krapinsko-zagorska županija"
        }, {
          "code": "HR-09",
          "represent": "Ličko-senjska županija"
        }, {
          "code": "HR-20",
          "represent": "Medjimurska županija"
        }, {
          "code": "HR-14",
          "represent": "Osječko-baranjska županija"
        }, {
          "code": "HR-11",
          "represent": "Požeško-slavonska županija"
        }, {
          "code": "HR-08",
          "represent": "Primorsko-goranska županija"
        }, {
          "code": "HR-03",
          "represent": "Sisaško-moslavačka županija"
        }, {
          "code": "HR-17",
          "represent": "Splitsko-dalmatinska županija"
        }, {
          "code": "HR-15",
          "represent": "Šibensko-kninska županija"
        }, {
          "code": "HR-05",
          "represent": "Varaždinska županija"
        }, {
          "code": "HR-10",
          "represent": "Virovitičko-podravska županija"
        }, {
          "code": "HR-16",
          "represent": "Vukovarsko-srijemska županija"
        }, {
          "code": "HR-13",
          "represent": "Zadarska županija"
        }, {
          "code": "HR-01",
          "represent": "Zagrebačka županija"
        }]
      }, {
        "name": "Cuba",
        "code": "CU",
        "divisions": [{
          "code": "CU-09",
          "represent": "Camagüey"
        }, {
          "code": "CU-03",
          "represent": "Ciudad de La Habana"
        }, {
          "code": "CU-12",
          "represent": "Granma"
        }, {
          "code": "CU-14",
          "represent": "Guantánamo"
        }, {
          "code": "CU-11",
          "represent": "Holguín"
        }, {
          "code": "CU-02",
          "represent": "La Habana"
        }, {
          "code": "CU-10",
          "represent": "Las Tunas"
        }, {
          "code": "CU-04",
          "represent": "Matanzas"
        }, {
          "code": "CU-01",
          "represent": "Pinar del Río"
        }, {
          "code": "CU-07",
          "represent": "Sancti Spíritus"
        }, {
          "code": "CU-13",
          "represent": "Santiago de Cuba"
        }, {
          "code": "CU-05",
          "represent": "Villa Clara"
        }, {
          "code": "CU-99",
          "represent": "Isla de la Juventud"
        }]
      }, {
        "name": "Curaçao",
        "code": "CW",
        "divisions": [{
          "code": "CW-CW",
          "represent": "Curaçao"
        }]
      }, {
        "name": "Cyprus",
        "code": "CY",
        "divisions": [{
          "code": "CY-04",
          "represent": "Ammochostos (Mağusa)"
        }, {
          "code": "CY-06",
          "represent": "Keryneia (Girne)"
        }, {
          "code": "CY-03",
          "represent": "Larnaka"
        }, {
          "code": "CY-01",
          "represent": "Lefkosia (Lefkoşa)"
        }, {
          "code": "CY-02",
          "represent": "Lemesos (Leymosun)"
        }, {
          "code": "CY-05",
          "represent": "Pafos (Baf)"
        }]
      }, {
        "name": "Czech Republic",
        "code": "CZ",
        "divisions": [{
          "code": "CZ-PRG",
          "represent": "Praha"
        }, {
          "code": "CZ-CJC",
          "represent": "Jihočeský kraj"
        }, {
          "code": "CZ-CJM",
          "represent": "Jihomoravský kraj"
        }, {
          "code": "CZ-CSC",
          "represent": "Severočeský kraj"
        }, {
          "code": "CZ-CSM",
          "represent": "Severomoravský kraj"
        }, {
          "code": "CZ-CST",
          "represent": "Středočeský kraj"
        }, {
          "code": "CZ-CVC",
          "represent": "Východočeský kraj"
        }, {
          "code": "CZ-CZC",
          "represent": "Západočeský kraj"
        }]
      }, {
        "name": "Denmark",
        "code": "DK",
        "divisions": [{
          "code": "DK-147",
          "represent": "Frederiksberg"
        }, {
          "code": "DK-101",
          "represent": "Kǿbenhavn"
        }, {
          "code": "DK-040",
          "represent": "Bornholm"
        }, {
          "code": "DK-020",
          "represent": "Frederiksborg"
        }, {
          "code": "DK-042",
          "represent": "Fyn"
        }, {
          "code": "DK-015",
          "represent": "Kǿbenhavn"
        }, {
          "code": "DK-080",
          "represent": "Nordjylland"
        }, {
          "code": "DK-055",
          "represent": "Ribe"
        }, {
          "code": "DK-065",
          "represent": "Ringkǿbing"
        }, {
          "code": "DK-025",
          "represent": "Roskilde"
        }, {
          "code": "DK-035",
          "represent": "Storstrǿm"
        }, {
          "code": "DK-050",
          "represent": "Sǿnderjylland"
        }, {
          "code": "DK-060",
          "represent": "Vejle"
        }, {
          "code": "DK-030",
          "represent": "Vestsjælland"
        }, {
          "code": "DK-076",
          "represent": "Viborg"
        }, {
          "code": "DK-070",
          "represent": "Århus"
        }]
      }, {
        "name": "Djibouti",
        "code": "DJ",
        "divisions": [{
          "code": "DJ-AS",
          "represent": "Ali Sabieh"
        }, {
          "code": "DJ-DI",
          "represent": "Dikhil"
        }, {
          "code": "DJ-DJ",
          "represent": "Djibouti"
        }, {
          "code": "DJ-OB",
          "represent": "Obock"
        }, {
          "code": "DJ-TA",
          "represent": "Tadjoura"
        }]
      }, {
        "name": "Dominica",
        "code": "DM",
        "divisions": [{
          "code": "DM-DM",
          "represent": "Dominica"
        }]
      }, {
        "name": "Dominican Republic",
        "code": "DO",
        "divisions": [{
          "code": "DO-DN",
          "represent": "Distrito National (Santo Domingo)"
        }, {
          "code": "DO-AZ",
          "represent": "Azua"
        }, {
          "code": "DO-BR",
          "represent": "Bahoruco"
        }, {
          "code": "DO-BH",
          "represent": "Barahona"
        }, {
          "code": "DO-DA",
          "represent": "Dajabón"
        }, {
          "code": "DO-DU",
          "represent": "Duarte"
        }, {
          "code": "DO-SE",
          "represent": "El Seibo"
        }, {
          "code": "DO-EP",
          "represent": "La Estrelleta [Elías Piña]"
        }, {
          "code": "DO-HM",
          "represent": "Hato Mayor"
        }, {
          "code": "DO-IN",
          "represent": "Independencia"
        }, {
          "code": "DO-AL",
          "represent": "La Altagracia"
        }, {
          "code": "DO-RO",
          "represent": "La Romana"
        }, {
          "code": "DO-VE",
          "represent": "La Vega"
        }, {
          "code": "DO-MT",
          "represent": "María Trinidad Sánchez"
        }, {
          "code": "DO-MN",
          "represent": "Monseñor Nouel"
        }, {
          "code": "DO-MC",
          "represent": "Monte Cristi"
        }, {
          "code": "DO-MP",
          "represent": "Monte Plata"
        }, {
          "code": "DO-PN",
          "represent": "Pedernales"
        }, {
          "code": "DO-PR",
          "represent": "Peravia"
        }, {
          "code": "DO-PP",
          "represent": "Puerto Plata"
        }, {
          "code": "DO-SC",
          "represent": "Salcedo"
        }, {
          "code": "DO-SM",
          "represent": "Samaná"
        }, {
          "code": "DO-SZ",
          "represent": "Sanchez Ramírez"
        }, {
          "code": "DO-CR",
          "represent": "San Cristóbal"
        }, {
          "code": "DO-JU",
          "represent": "San Juan"
        }, {
          "code": "DO-PM",
          "represent": "San Pedro de Macorís"
        }, {
          "code": "DO-ST",
          "represent": "Santiago"
        }, {
          "code": "DO-SR",
          "represent": "Santiago Rodríguez"
        }, {
          "code": "DO-VA",
          "represent": "Valverde"
        }]
      }, {
        "name": "East Timor",
        "code": "TP",
        "divisions": [{
          "code": "TP-TP",
          "represent": "East Timor"
        }]
      }, {
        "name": "Ecuador",
        "code": "EC",
        "divisions": [{
          "code": "EC-A",
          "represent": "Azuay"
        }, {
          "code": "EC-B",
          "represent": "Bolívar"
        }, {
          "code": "EC-F",
          "represent": "Cañar"
        }, {
          "code": "EC-C",
          "represent": "Carchi"
        }, {
          "code": "EC-X",
          "represent": "Cotopaxi"
        }, {
          "code": "EC-H",
          "represent": "Chimborazo"
        }, {
          "code": "EC-O",
          "represent": "El Oro"
        }, {
          "code": "EC-E",
          "represent": "Esmeraldas"
        }, {
          "code": "EC-W",
          "represent": "Galápagos"
        }, {
          "code": "EC-G",
          "represent": "Guayas"
        }, {
          "code": "EC-I",
          "represent": "Imbabura"
        }, {
          "code": "EC-L",
          "represent": "Loja"
        }, {
          "code": "EC-R",
          "represent": "Los Ríos"
        }, {
          "code": "EC-M",
          "represent": "Manabí"
        }, {
          "code": "EC-S",
          "represent": "Morona-Santiago"
        }, {
          "code": "EC-N",
          "represent": "Napo"
        }, {
          "code": "EC-Y",
          "represent": "Pastaza"
        }, {
          "code": "EC-P",
          "represent": "Pichincha"
        }, {
          "code": "EC-U",
          "represent": "Sucumbíos"
        }, {
          "code": "EC-T",
          "represent": "Tungurahua"
        }, {
          "code": "EC-Z",
          "represent": "Zarnora-Chinchipe"
        }]
      }, {
        "name": "Egypt",
        "code": "EG",
        "divisions": [{
          "code": "EG-DK",
          "represent": "Ad Daqahlīyah"
        }, {
          "code": "EG-BA",
          "represent": "Al Baḩr al Aḩmar"
        }, {
          "code": "EG-BH",
          "represent": "Al Buḩayrah"
        }, {
          "code": "EG-FYM",
          "represent": "Al Fayyūm"
        }, {
          "code": "EG-GH",
          "represent": "Al Gharbīyah"
        }, {
          "code": "EG-ALX",
          "represent": "Al Iskandarīyah"
        }, {
          "code": "EG-IS",
          "represent": "Al Ismā‘īlīyah"
        }, {
          "code": "EG-GZ",
          "represent": "Al Jīzah"
        }, {
          "code": "EG-MNF",
          "represent": "Al Minūfīyah"
        }, {
          "code": "EG-MN",
          "represent": "Al Minyā"
        }, {
          "code": "EG-C",
          "represent": "Al Qāhirah"
        }, {
          "code": "EG-KB",
          "represent": "Al Qalyūbīyah"
        }, {
          "code": "EG-WAD",
          "represent": "Al Wādī al Jadīd"
        }, {
          "code": "EG-SHR",
          "represent": "Ash Sharqīyah"
        }, {
          "code": "EG-SUZ",
          "represent": "As Suways"
        }, {
          "code": "EG-ASN",
          "represent": "Aswān"
        }, {
          "code": "EG-AST",
          "represent": "Asyūţ"
        }, {
          "code": "EG-BNS",
          "represent": "Banī Suwayf"
        }, {
          "code": "EG-PTS",
          "represent": "Būr Sa‘īd"
        }, {
          "code": "EG-DT",
          "represent": "Dumyāţ"
        }, {
          "code": "EG-JS",
          "represent": "Janūb Sīnā'"
        }, {
          "code": "EG-KFS",
          "represent": "Kafr ash Shaykh"
        }, {
          "code": "EG-MT",
          "represent": "Maţrūḩ"
        }, {
          "code": "EG-KN",
          "represent": "Qinā"
        }, {
          "code": "EG-SIN",
          "represent": "Shamāl Sīnā'"
        }, {
          "code": "EG-SHG",
          "represent": "Sūhāj"
        }]
      }, {
        "name": "El Salvador",
        "code": "SV",
        "divisions": [{
          "code": "SV-AH",
          "represent": "Ahuachapán"
        }, {
          "code": "SV-CA",
          "represent": "Cabañas"
        }, {
          "code": "SV-CU",
          "represent": "Cuscatlán"
        }, {
          "code": "SV-CH",
          "represent": "Chalatenango"
        }, {
          "code": "SV-LI",
          "represent": "La Libertad"
        }, {
          "code": "SV-PA",
          "represent": "La Paz"
        }, {
          "code": "SV-UN",
          "represent": "La Unión"
        }, {
          "code": "SV-MO",
          "represent": "Morazán"
        }, {
          "code": "SV-SM",
          "represent": "San Miguel"
        }, {
          "code": "SV-SS",
          "represent": "San Salvador"
        }, {
          "code": "SV-SA",
          "represent": "Santa Ana"
        }, {
          "code": "SV-SV",
          "represent": "San Vicente"
        }, {
          "code": "SV-SO",
          "represent": "Sonsonate"
        }, {
          "code": "SV-SU",
          "represent": "Usulután"
        }]
      }, {
        "name": "Equatorial Guinea",
        "code": "GQ",
        "divisions": [{
          "code": "GQ-C",
          "represent": "Región Continental"
        }, {
          "code": "GQ-CS",
          "represent": "Centro Sur"
        }, {
          "code": "GQ-KN",
          "represent": "Kie-Ntem"
        }, {
          "code": "GQ-LI",
          "represent": "Litoral"
        }, {
          "code": "GQ-WN",
          "represent": "Wele-Nzás"
        }, {
          "code": "GQ-I",
          "represent": "Región Insular"
        }, {
          "code": "GQ-AN",
          "represent": "Annobón"
        }, {
          "code": "GQ-BN",
          "represent": "Bioko Norte"
        }, {
          "code": "GQ-BS",
          "represent": "Bioko Sur"
        }]
      }, {
        "name": "Eritrea",
        "code": "ER",
        "divisions": [{
          "code": "ER-AG",
          "represent": "Akele Guzai [Akalä Guzay]"
        }, {
          "code": "ER-AS",
          "represent": "Asmara [Asmära]"
        }, {
          "code": "ER-BA",
          "represent": "Barka"
        }, {
          "code": "ER-DE",
          "represent": "Denkalia [Dänkali]"
        }, {
          "code": "ER-GS",
          "represent": "Gash-Setit [Gaš enna Sätit]"
        }, {
          "code": "ER-HA",
          "represent": "Hamasien [Hamasén]"
        }, {
          "code": "ER-SA",
          "represent": "Sahel"
        }, {
          "code": "ER-SM",
          "represent": "Semhar [Sämhar]"
        }, {
          "code": "ER-SN",
          "represent": "Senhit [Sänhet]"
        }, {
          "code": "ER-SR",
          "represent": "Seraye [Särayé]"
        }]
      }, {
        "name": "Estonia",
        "code": "EE",
        "divisions": [{
          "code": "EE-37",
          "represent": "Harjumaa"
        }, {
          "code": "EE-39",
          "represent": "Hiiumaa"
        }, {
          "code": "EE-44",
          "represent": "Ida-Virumaa"
        }, {
          "code": "EE-49",
          "represent": "Jōgevamaa"
        }, {
          "code": "EE-51",
          "represent": "Järvamaa"
        }, {
          "code": "EE-57",
          "represent": "Läänemaa"
        }, {
          "code": "EE-59",
          "represent": "Lääne-Virumaa"
        }, {
          "code": "EE-65",
          "represent": "Pōlvamaa"
        }, {
          "code": "EE-67",
          "represent": "Pärnumaa"
        }, {
          "code": "EE-70",
          "represent": "Raplamaa"
        }, {
          "code": "EE-74",
          "represent": "Saaremaa"
        }, {
          "code": "EE-78",
          "represent": "Tartumaa"
        }, {
          "code": "EE-82",
          "represent": "Valgamaa"
        }, {
          "code": "EE-84",
          "represent": "Viljandimaa"
        }, {
          "code": "EE-86",
          "represent": "Vōrumaa"
        }]
      }, {
        "name": "Ethiopia",
        "code": "ET",
        "divisions": [{
          "code": "ET-AA",
          "represent": "Addis Ababa [Addis Abeba]"
        }, {
          "code": "ET-AF",
          "represent": "Afar"
        }, {
          "code": "ET-AM",
          "represent": "Amara [Amhara]"
        }, {
          "code": "ET-BE",
          "represent": "Benshangul-Gumaz [Bénishangul]"
        }, {
          "code": "ET-GA",
          "represent": "Gambela Peoples [Gambéla]"
        }, {
          "code": "ET-HA",
          "represent": "Harari People [Harer]"
        }, {
          "code": "ET-OR",
          "represent": "Oromia [Oromo]"
        }, {
          "code": "ET-SO",
          "represent": "Somali"
        }, {
          "code": "ET-SN",
          "represent": "Southern Nations, Nationalities and Peoples"
        }, {
          "code": "ET-TI",
          "represent": "Tigrai [Tegré]"
        }]
      }, {
        "name": "Falkland Islands (Malvinas)",
        "code": "FK",
        "divisions": [{
          "code": "FK-FK",
          "represent": "Falkland Islands (Malvinas)"
        }]
      }, {
        "name": "Faroe Islands",
        "code": "FO",
        "divisions": [{
          "code": "FO-FO",
          "represent": "Faroe Islands"
        }]
      }, {
        "name": "Fiji",
        "code": "FJ",
        "divisions": [{
          "code": "FJ-C",
          "represent": "Central"
        }, {
          "code": "FJ-E",
          "represent": "Eastern"
        }, {
          "code": "FJ-N",
          "represent": "Northern"
        }, {
          "code": "FJ-W",
          "represent": "Western"
        }, {
          "code": "FJ-R",
          "represent": "Rotuma"
        }]
      }, {
        "name": "Finland",
        "code": "FI",
        "divisions": [{
          "code": "FI-AL",
          "represent": "Ahvenanmaan lääni"
        }, {
          "code": "FI-ES",
          "represent": "Etelä-Suomen lääni"
        }, {
          "code": "FI-IS",
          "represent": "Itä-Suomen lääni"
        }, {
          "code": "FI-LL",
          "represent": "Lapin lääni"
        }, {
          "code": "FI-LS",
          "represent": "Länsi-Suomen lääni"
        }, {
          "code": "FI-OL",
          "represent": "Oulun lääni"
        }]
      }, {
        "name": "France",
        "code": "FR",
        "divisions": [{
          "code": "FR-A",
          "represent": "Alsace"
        }, {
          "code": "FR-67",
          "represent": "Bas-Rhin"
        }, {
          "code": "FR-68",
          "represent": "Haut-Rhin"
        }, {
          "code": "FR-B",
          "represent": "Aquitaine"
        }, {
          "code": "FR-79",
          "represent": "Deux-Sèvres"
        }, {
          "code": "FR-24",
          "represent": "Dordogne"
        }, {
          "code": "FR-33",
          "represent": "Gironde"
        }, {
          "code": "FR-40",
          "represent": "Landes"
        }, {
          "code": "FR-47",
          "represent": "Lot-et-Garonne"
        }, {
          "code": "FR-64",
          "represent": "Pyrénées-Atlantiques"
        }, {
          "code": "FR-C",
          "represent": "Auvergne"
        }, {
          "code": "FR-03",
          "represent": "Allier"
        }, {
          "code": "FR-15",
          "represent": "Cantal"
        }, {
          "code": "FR-43",
          "represent": "Haute-Loire"
        }, {
          "code": "FR-63",
          "represent": "Puy-de-Dôme"
        }, {
          "code": "FR-P",
          "represent": "Basse-Normandie"
        }, {
          "code": "FR-14",
          "represent": "Calvados"
        }, {
          "code": "FR-50",
          "represent": "Manche"
        }, {
          "code": "FR-61",
          "represent": "Orne"
        }, {
          "code": "FR-D",
          "represent": "Bourgogne"
        }, {
          "code": "FR-21",
          "represent": "Côte-d'Or"
        }, {
          "code": "FR-58",
          "represent": "Nièvre"
        }, {
          "code": "FR-71",
          "represent": "Saône-et-Loire"
        }, {
          "code": "FR-89",
          "represent": "Yonne"
        }, {
          "code": "FR-E",
          "represent": "Bretagne"
        }, {
          "code": "FR-22",
          "represent": "Cotes-d'Armor"
        }, {
          "code": "FR-29",
          "represent": "Finistère"
        }, {
          "code": "FR-35",
          "represent": "Ille-et-Vilaine"
        }, {
          "code": "FR-56",
          "represent": "Morbihan"
        }, {
          "code": "FR-F",
          "represent": "Centre"
        }, {
          "code": "FR-18",
          "represent": "Cher"
        }, {
          "code": "FR-28",
          "represent": "Eure-et-Loir"
        }, {
          "code": "FR-36",
          "represent": "Indre"
        }, {
          "code": "FR-37",
          "represent": "Indre-et-Loire"
        }, {
          "code": "FR-41",
          "represent": "Loir-et-Cher"
        }, {
          "code": "FR-45",
          "represent": "Loiret"
        }, {
          "code": "FR-G",
          "represent": "Champagne-Ardenne"
        }, {
          "code": "FR-08",
          "represent": "Ardennes"
        }, {
          "code": "FR-10",
          "represent": "Aube"
        }, {
          "code": "FR-52",
          "represent": "Haute-Marne"
        }, {
          "code": "FR-51",
          "represent": "Marne"
        }, {
          "code": "FR-H",
          "represent": "Corse"
        }, {
          "code": "FR-2A",
          "represent": "Corse-du-Sud"
        }, {
          "code": "FR-2B",
          "represent": "Haute-Corse"
        }, {
          "code": "FR-I",
          "represent": "Franche-Comté"
        }, {
          "code": "FR-25",
          "represent": "Doubs"
        }, {
          "code": "FR-70",
          "represent": "Haute-Saône"
        }, {
          "code": "FR-39",
          "represent": "Jura"
        }, {
          "code": "FR-90",
          "represent": "Territoire de Belfort"
        }, {
          "code": "FR-Q",
          "represent": "Haute-Normandie"
        }, {
          "code": "FR-27",
          "represent": "Eure"
        }, {
          "code": "FR-76",
          "represent": "Seine-Maritime"
        }, {
          "code": "FR-J",
          "represent": "Île-de-France"
        }, {
          "code": "FR-91",
          "represent": "Essonne"
        }, {
          "code": "FR-92",
          "represent": "Hauts-de-Seine"
        }, {
          "code": "FR-75",
          "represent": "Paris"
        }, {
          "code": "FR-77",
          "represent": "Seine-et-Marne"
        }, {
          "code": "FR-93",
          "represent": "Seine-Saint-Denis"
        }, {
          "code": "FR-94",
          "represent": "Val-de-Marne"
        }, {
          "code": "FR-95",
          "represent": "Val-d'Oise"
        }, {
          "code": "FR-78",
          "represent": "Yvelines"
        }, {
          "code": "FR-K",
          "represent": "Languedoc-Roussillon"
        }, {
          "code": "FR-11",
          "represent": "Aude"
        }, {
          "code": "FR-30",
          "represent": "Gard"
        }, {
          "code": "FR-34",
          "represent": "Hérault"
        }, {
          "code": "FR-48",
          "represent": "Lozère"
        }, {
          "code": "FR-66",
          "represent": "Pyrénées-Orientales"
        }, {
          "code": "FR-L",
          "represent": "Limousin"
        }, {
          "code": "FR-19",
          "represent": "Corrèze"
        }, {
          "code": "FR-23",
          "represent": "Creuse"
        }, {
          "code": "FR-87",
          "represent": "Haute-Vienne"
        }, {
          "code": "FR-M",
          "represent": "Lorraine"
        }, {
          "code": "FR-54",
          "represent": "Meurthe-et-Moselle"
        }, {
          "code": "FR-55",
          "represent": "Meuse"
        }, {
          "code": "FR-57",
          "represent": "Moselle"
        }, {
          "code": "FR-88",
          "represent": "Vosges"
        }, {
          "code": "FR-N",
          "represent": "Midi-Pyrénées"
        }, {
          "code": "FR-09",
          "represent": "Ariège"
        }, {
          "code": "FR-12",
          "represent": "Aveyron"
        }, {
          "code": "FR-32",
          "represent": "Gers"
        }, {
          "code": "FR-31",
          "represent": "Haute-Garonne"
        }, {
          "code": "FR-65",
          "represent": "Hautes-Pyrénées"
        }, {
          "code": "FR-46",
          "represent": "Lot"
        }, {
          "code": "FR-81",
          "represent": "Tarn"
        }, {
          "code": "FR-82",
          "represent": "Tarn-et-Garonne"
        }, {
          "code": "FR-O",
          "represent": "Nord-Pas-de-Calais"
        }, {
          "code": "FR-59",
          "represent": "Nord"
        }, {
          "code": "FR-62",
          "represent": "Pas-de-Calais"
        }, {
          "code": "FR-R",
          "represent": "Pays de la Loire"
        }, {
          "code": "FR-44",
          "represent": "Loire-Atlantique"
        }, {
          "code": "FR-49",
          "represent": "Maine-et-Loire"
        }, {
          "code": "FR-53",
          "represent": "Mayenne"
        }, {
          "code": "FR-72",
          "represent": "Sarthe"
        }, {
          "code": "FR-85",
          "represent": "Vendée"
        }, {
          "code": "FR-S",
          "represent": "Picardie"
        }, {
          "code": "FR-02",
          "represent": "Aisne"
        }, {
          "code": "FR-60",
          "represent": "Oise"
        }, {
          "code": "FR-80",
          "represent": "Somme"
        }, {
          "code": "FR-T",
          "represent": "Poitou-Charentes"
        }, {
          "code": "FR-16",
          "represent": "Charente"
        }, {
          "code": "FR-17",
          "represent": "Charente-Maritime"
        }, {
          "code": "FR-86",
          "represent": "Vienne"
        }, {
          "code": "FR-U",
          "represent": "Provence-Alpes-Côte d'Azur"
        }, {
          "code": "FR-04",
          "represent": "Alpes-de-Haute-Provence"
        }, {
          "code": "FR-06",
          "represent": "Alpes-Maritimes"
        }, {
          "code": "FR-13",
          "represent": "Bauches-du-Rhône"
        }, {
          "code": "FR-05",
          "represent": "Hautes-Alpes"
        }, {
          "code": "FR-83",
          "represent": "Var"
        }, {
          "code": "FR-84",
          "represent": "Vaucluse"
        }, {
          "code": "FR-V",
          "represent": "Rhône-Alpes"
        }, {
          "code": "FR-01",
          "represent": "Ain"
        }, {
          "code": "FR-07",
          "represent": "Ardèche"
        }, {
          "code": "FR-26",
          "represent": "Drôme"
        }, {
          "code": "FR-74",
          "represent": "Haute-Savoie"
        }, {
          "code": "FR-38",
          "represent": "Isère"
        }, {
          "code": "FR-42",
          "represent": "Loire"
        }, {
          "code": "FR-69",
          "represent": "Rhône"
        }, {
          "code": "FR-73",
          "represent": "Savoie"
        }, {
          "code": "FR-GP",
          "represent": "Guadeloupe"
        }, {
          "code": "FR-GF",
          "represent": "Guyane (francaise)"
        }, {
          "code": "FR-MQ",
          "represent": "Martinique"
        }, {
          "code": "FR-RE",
          "represent": "Réunion"
        }, {
          "code": "FR-YT",
          "represent": "Mayotte"
        }, {
          "code": "FR-PM",
          "represent": "Saint-Pierre-et-Miquelon"
        }, {
          "code": "FR-NC",
          "represent": "Nouvelle-Calédonie"
        }, {
          "code": "FR-PF",
          "represent": "Polynésie française"
        }, {
          "code": "FR-TF",
          "represent": "Terres Australes"
        }, {
          "code": "FR-WF",
          "represent": "Wallis et Futuna"
        }]
      }, {
        "name": "French Guiana",
        "code": "GF",
        "divisions": [{
          "code": "GF-GF",
          "represent": "French Guiana"
        }]
      }, {
        "name": "French Polynesia",
        "code": "PF",
        "divisions": [{
          "code": "PF-PF",
          "represent": "French Polynesia"
        }]
      }, {
        "name": "French Southern Territories",
        "code": "TF",
        "divisions": [{
          "code": "TF-TF",
          "represent": "French Southern Territories"
        }]
      }, {
        "name": "Gabon",
        "code": "GA",
        "divisions": [{
          "code": "GA-1",
          "represent": "Estuaire"
        }, {
          "code": "GA-2",
          "represent": "Haut-Ogooué"
        }, {
          "code": "GA-3",
          "represent": "Moyen-Ogooué"
        }, {
          "code": "GA-4",
          "represent": "Ngounié"
        }, {
          "code": "GA-5",
          "represent": "Nyanga"
        }, {
          "code": "GA-6",
          "represent": "Ogooué-Ivindo"
        }, {
          "code": "GA-7",
          "represent": "Ogooué-Lolo"
        }, {
          "code": "GA-8",
          "represent": "Ogooué-Maritime"
        }, {
          "code": "GA-9",
          "represent": "Woleu-Ntem"
        }]
      }, {
        "name": "Gambia",
        "code": "GM",
        "divisions": [{
          "code": "GM-B",
          "represent": "Banjul"
        }, {
          "code": "GM-L",
          "represent": "Lower River"
        }, {
          "code": "GM-M",
          "represent": "MacCarthy Island"
        }, {
          "code": "GM-N",
          "represent": "North Bank"
        }, {
          "code": "GM-U",
          "represent": "Upper River"
        }, {
          "code": "GM-W",
          "represent": "Western"
        }]
      }, {
        "name": "Georgia",
        "code": "GE",
        "divisions": [{
          "code": "GE-AB",
          "represent": "Ap'khazet'is Avtonomiuri Respublika [Abkhazia]"
        }, {
          "code": "GE-AJ",
          "represent": "Acharis Avtonomiuri Respublika [Ajaria]"
        }, {
          "code": "GE-BUS",
          "represent": "Bat'umi"
        }, {
          "code": "GE-CHI",
          "represent": "Chiat'ura"
        }, {
          "code": "GE-GAG",
          "represent": "Gagra"
        }, {
          "code": "GE-GOR",
          "represent": "Gori"
        }, {
          "code": "GE-KUT",
          "represent": "K'ut'aisi"
        }, {
          "code": "GE-PTI",
          "represent": "P'ot'i"
        }, {
          "code": "GE-RUS",
          "represent": "Rust'avi"
        }, {
          "code": "GE-SUI",
          "represent": "Sokhumi"
        }, {
          "code": "GE-TBS",
          "represent": "T'bilisi"
        }, {
          "code": "GE-TQI",
          "represent": "Tqibuli"
        }, {
          "code": "GE-TQV",
          "represent": "Tqvarch'eli"
        }, {
          "code": "GE-TSQ",
          "represent": "Tsqalmbo"
        }, {
          "code": "GE-ZUG",
          "represent": "Zugdidi"
        }, {
          "code": "GE-01",
          "represent": "Abashis Raioni"
        }, {
          "code": "GE-02",
          "represent": "Adigenis Raioni"
        }, {
          "code": "GE-03",
          "represent": "Akhalgoris Raioni"
        }, {
          "code": "GE-04",
          "represent": "Akhalk'alak'is Raioni"
        }, {
          "code": "GE-05",
          "represent": "Akhalts'ikhis Raioni"
        }, {
          "code": "GE-06",
          "represent": "Akhmetis Raioni"
        }, {
          "code": "GE-07",
          "represent": "Ambrolauris Raioni"
        }, {
          "code": "GE-08",
          "represent": "Aspindzis Raioni"
        }, {
          "code": "GE-09",
          "represent": "Baghdat'is Raioni"
        }, {
          "code": "GE-10",
          "represent": "Bolnisis Raioni"
        }, {
          "code": "GE-11",
          "represent": "Borjomis Raioni"
        }, {
          "code": "GE-12",
          "represent": "Ch'khorotsqus Raioni"
        }, {
          "code": "GE-13",
          "represent": "Ch'okhatauris Raioni"
        }, {
          "code": "GE-14",
          "represent": "Dedop'listsqaros Raioni"
        }, {
          "code": "GE-15",
          "represent": "Dmanisis Raioni"
        }, {
          "code": "GE-16",
          "represent": "Dushet'is Raioni"
        }, {
          "code": "GE-17",
          "represent": "Galis Raioni"
        }, {
          "code": "GE-18",
          "represent": "Gardabnis Raioni"
        }, {
          "code": "GE-19",
          "represent": "Goris Raioni"
        }, {
          "code": "GE-20",
          "represent": "Gudaut'is Raioni"
        }, {
          "code": "GE-21",
          "represent": "Gulrip'shis Raioni"
        }, {
          "code": "GE-22",
          "represent": "Gurjaanis Raioni"
        }, {
          "code": "GE-23",
          "represent": "Javis Raioni"
        }, {
          "code": "GE-24",
          "represent": "K'arelis Raioni"
        }, {
          "code": "GE-25",
          "represent": "Kaspis Raioni"
        }, {
          "code": "GE-26",
          "represent": "K'edis Raioni"
        }, {
          "code": "GE-27",
          "represent": "Kharagaulis Raioni"
        }, {
          "code": "GE-28",
          "represent": "Khashuris Raioni"
        }, {
          "code": "GE-29",
          "represent": "Khelvach'auris Raioni"
        }, {
          "code": "GE-30",
          "represent": "Khobis Raioni"
        }, {
          "code": "GE-31",
          "represent": "Khonis Raioni"
        }, {
          "code": "GE-32",
          "represent": "Khulos Raioni"
        }, {
          "code": "GE-33",
          "represent": "K'obuletis Raioni"
        }, {
          "code": "GE-34",
          "represent": "Lagodekhis Raioni"
        }, {
          "code": "GE-35",
          "represent": "Lanch'khut'is Raioni"
        }, {
          "code": "GE-36",
          "represent": "Lentekhis Raioni"
        }, {
          "code": "GE-37",
          "represent": "Marneulis Raioni"
        }, {
          "code": "GE-38",
          "represent": "Martvilis Raioni"
        }, {
          "code": "GE-39",
          "represent": "Mestiis Raioni"
        }, {
          "code": "GE-40",
          "represent": "Mts'khet'is Raioni"
        }, {
          "code": "GE-41",
          "represent": "Ninotsmindis Raioni"
        }, {
          "code": "GE-42",
          "represent": "Och'amch'iris Raioni"
        }, {
          "code": "GE-43",
          "represent": "Onis Raioni"
        }, {
          "code": "GE-44",
          "represent": "Ozurget'is Raioni"
        }, {
          "code": "GE-45",
          "represent": "Qazbegis Raioni"
        }, {
          "code": "GE-46",
          "represent": "Qvarlis Raioni"
        }, {
          "code": "GE-47",
          "represent": "Sach'kheris Raioni"
        }, {
          "code": "GE-48",
          "represent": "Sagarejos Raioni"
        }, {
          "code": "GE-49",
          "represent": "Samtrediis Raioni"
        }, {
          "code": "GE-50",
          "represent": "Senakis Raioni"
        }, {
          "code": "GE-51",
          "represent": "Shuakhevis Raioni"
        }, {
          "code": "GE-52",
          "represent": "Sighnaghis Raioni"
        }, {
          "code": "GE-53",
          "represent": "Sokhumis Raioni"
        }, {
          "code": "GE-54",
          "represent": "T'elavis Raioni"
        }, {
          "code": "GE-55",
          "represent": "T'erjolis Raioni"
        }, {
          "code": "GE-56",
          "represent": "T'et'ritsqaros Raioni"
        }, {
          "code": "GE-57",
          "represent": "T'ianet'is Raioni"
        }, {
          "code": "GE-58",
          "represent": "Ts'ageris Raioni"
        }, {
          "code": "GE-59",
          "represent": "Tsalenjikhis Raioni"
        }, {
          "code": "GE-60",
          "represent": "Tsalkis Raioni"
        }, {
          "code": "GE-61",
          "represent": "Vanis Raioni"
        }, {
          "code": "GE-62",
          "represent": "Zestap'onis Raioni"
        }, {
          "code": "GE-63",
          "represent": "Zugdidis Raioni"
        }]
      }, {
        "name": "Germany",
        "code": "DE",
        "divisions": [{
          "code": "DE-BW",
          "represent": "Baden-Württemberg"
        }, {
          "code": "DE-BY",
          "represent": "Bayern"
        }, {
          "code": "DE-BE",
          "represent": "Berlin"
        }, {
          "code": "DE-BB",
          "represent": "Brandenburg"
        }, {
          "code": "DE-HB",
          "represent": "Bremen"
        }, {
          "code": "DE-HH",
          "represent": "Hamburg"
        }, {
          "code": "DE-HE",
          "represent": "Hessen"
        }, {
          "code": "DE-MV",
          "represent": "Mecklenburg-Vorpommern"
        }, {
          "code": "DE-NI",
          "represent": "Niedersachsen"
        }, {
          "code": "DE-NW",
          "represent": "Nordrhein-Westfalen"
        }, {
          "code": "DE-RP",
          "represent": "Rheinland-Pfalz"
        }, {
          "code": "DE-SL",
          "represent": "Saarland"
        }, {
          "code": "DE-SN",
          "represent": "Sachsen"
        }, {
          "code": "DE-ST",
          "represent": "Sachsen-Anhalt"
        }, {
          "code": "DE-SH",
          "represent": "Schleswig-Holstein"
        }, {
          "code": "DE-TH",
          "represent": "Thüringen"
        }]
      }, {
        "name": "Ghana",
        "code": "GH",
        "divisions": [{
          "code": "GH-AH",
          "represent": "Ashanti"
        }, {
          "code": "GH-BA",
          "represent": "Brong-Ahafo"
        }, {
          "code": "GH-CP",
          "represent": "Central"
        }, {
          "code": "GH-EP",
          "represent": "Eastern"
        }, {
          "code": "GH-AA",
          "represent": "Greater Accra"
        }, {
          "code": "GH-NP",
          "represent": "Northern"
        }, {
          "code": "GH-UE",
          "represent": "Upper East"
        }, {
          "code": "GH-UW",
          "represent": "Upper West"
        }, {
          "code": "GH-TV",
          "represent": "Volta"
        }, {
          "code": "GH-WP",
          "represent": "Western"
        }]
      }, {
        "name": "Gibraltar",
        "code": "GI",
        "divisions": [{
          "code": "GI-GI",
          "represent": "Gibraltar"
        }]
      }, {
        "name": "United Kingdom",
        "code": "GB",
        "divisions": [{
          "code": "GB-CHA",
          "represent": "Channel Islands"
        }, {
          "code": "GB-GSY",
          "represent": "Guernsey [Guernesey]"
        }, {
          "code": "GB-JSY",
          "represent": "Jersey"
        }, {
          "code": "GB-ENG",
          "represent": "England"
        }, {
          "code": "GB-BDG",
          "represent": "Barking and Dagenham"
        }, {
          "code": "GB-BNE",
          "represent": "Barnet"
        }, {
          "code": "GB-BNS",
          "represent": "Barnsley"
        }, {
          "code": "GB-BAS",
          "represent": "Bath and North East Somerset"
        }, {
          "code": "GB-BDF",
          "represent": "Bedfordshire"
        }, {
          "code": "GB-BEX",
          "represent": "Bexley"
        }, {
          "code": "GB-BIR",
          "represent": "Birmingham (City of)"
        }, {
          "code": "GB-BBD",
          "represent": "Blackburn with Darwen"
        }, {
          "code": "GB-BPL",
          "represent": "Blackpool"
        }, {
          "code": "GB-BOL",
          "represent": "Bolton"
        }, {
          "code": "GB-BMH",
          "represent": "Bournemouth"
        }, {
          "code": "GB-BRC",
          "represent": "Bracknell Forest"
        }, {
          "code": "GB-BRD",
          "represent": "Bradford (City of)"
        }, {
          "code": "GB-BEN",
          "represent": "Brent"
        }, {
          "code": "GB-BNH",
          "represent": "Brighton and Hove"
        }, {
          "code": "GB-BST",
          "represent": "Bristol, City of"
        }, {
          "code": "GB-BRY",
          "represent": "Bromley"
        }, {
          "code": "GB-BKM",
          "represent": "Buckinghamshire"
        }, {
          "code": "GB-BUR",
          "represent": "Bury"
        }, {
          "code": "GB-CLD",
          "represent": "Calderdale"
        }, {
          "code": "GB-CAM",
          "represent": "Cambridgeshire"
        }, {
          "code": "GB-CMD",
          "represent": "Camden"
        }, {
          "code": "GB-CHS",
          "represent": "Cheshire"
        }, {
          "code": "GB-CON",
          "represent": "Cornwall"
        }, {
          "code": "GB-COV",
          "represent": "Coventry (City of)"
        }, {
          "code": "GB-CRY",
          "represent": "Croydon"
        }, {
          "code": "GB-CMA",
          "represent": "Cumbria"
        }, {
          "code": "GB-DAL",
          "represent": "Darlington"
        }, {
          "code": "GB-DER",
          "represent": "Derby (City of)"
        }, {
          "code": "GB-DBY",
          "represent": "Derbyshire"
        }, {
          "code": "GB-DEV",
          "represent": "Devon"
        }, {
          "code": "GB-DNC",
          "represent": "Doncaster"
        }, {
          "code": "GB-DOR",
          "represent": "Dorset"
        }, {
          "code": "GB-DUD",
          "represent": "Dudley"
        }, {
          "code": "GB-DUR",
          "represent": "Durharn"
        }, {
          "code": "GB-EAL",
          "represent": "Ealing"
        }, {
          "code": "GB-ERY",
          "represent": "East Riding of Yorkshire"
        }, {
          "code": "GB-ESX",
          "represent": "East Sussex"
        }, {
          "code": "GB-ENF",
          "represent": "Enfield"
        }, {
          "code": "GB-ESS",
          "represent": "Essex"
        }, {
          "code": "GB-GAT",
          "represent": "Gateshead"
        }, {
          "code": "GB-GLS",
          "represent": "Gloucestershire"
        }, {
          "code": "GB-GRE",
          "represent": "Greenwich"
        }, {
          "code": "GB-HCK",
          "represent": "Hackney"
        }, {
          "code": "GB-HAL",
          "represent": "Haiton"
        }, {
          "code": "GB-HMF",
          "represent": "Hammersmith and Fulham"
        }, {
          "code": "GB-HAM",
          "represent": "Hampshire"
        }, {
          "code": "GB-HRY",
          "represent": "Haringey"
        }, {
          "code": "GB-HRW",
          "represent": "Harrow"
        }, {
          "code": "GB-HPL",
          "represent": "Hartlepool"
        }, {
          "code": "GB-HAV",
          "represent": "Havering"
        }, {
          "code": "GB-HEF",
          "represent": "Herefordshire, County of"
        }, {
          "code": "GB-HRT",
          "represent": "Hertfordshire"
        }, {
          "code": "GB-HIL",
          "represent": "Hillingdon"
        }, {
          "code": "GB-HNS",
          "represent": "Hounslow"
        }, {
          "code": "GB-IOW",
          "represent": "Isle of Wight"
        }, {
          "code": "GB-IOS",
          "represent": "Isles of Scilly"
        }, {
          "code": "GB-ISL",
          "represent": "Islington"
        }, {
          "code": "GB-KEC",
          "represent": "Kensington and Chelsea"
        }, {
          "code": "GB-KEN",
          "represent": "Kent"
        }, {
          "code": "GB-KHL",
          "represent": "Kingston upon Hull, City of"
        }, {
          "code": "GB-KTT",
          "represent": "Kingston upon Thames"
        }, {
          "code": "GB-KIR",
          "represent": "Kirklees"
        }, {
          "code": "GB-KWL",
          "represent": "Knowsley"
        }, {
          "code": "GB-LBH",
          "represent": "Lambeth"
        }, {
          "code": "GB-LAN",
          "represent": "Lancashire"
        }, {
          "code": "GB-LDS",
          "represent": "Leeds (City of)"
        }, {
          "code": "GB-LCE",
          "represent": "Leitester (City of)"
        }, {
          "code": "GB-LEC",
          "represent": "Leicestershire"
        }, {
          "code": "GB-LEW",
          "represent": "Lewisham"
        }, {
          "code": "GB-LIN",
          "represent": "Lincolnshire"
        }, {
          "code": "GB-LIV",
          "represent": "Liverpool (City of)"
        }, {
          "code": "GB-LND",
          "represent": "London, City of"
        }, {
          "code": "GB-LUT",
          "represent": "Luton"
        }, {
          "code": "GB-MAN",
          "represent": "Manchester (City of)"
        }, {
          "code": "GB-MDW",
          "represent": "Medway"
        }, {
          "code": "GB-MRT",
          "represent": "Merton"
        }, {
          "code": "GB-MDB",
          "represent": "Middlesbrough"
        }, {
          "code": "GB-MIK",
          "represent": "Milton Keynes"
        }, {
          "code": "GB-NET",
          "represent": "Newcastle upon Tyne (City of)"
        }, {
          "code": "GB-NWM",
          "represent": "Newham"
        }, {
          "code": "GB-NFK",
          "represent": "Norfolk"
        }, {
          "code": "GB-NEL",
          "represent": "North East Lincolnshire"
        }, {
          "code": "GB-NLN",
          "represent": "North Lincolnshire"
        }, {
          "code": "GB-NSM",
          "represent": "North Somerset"
        }, {
          "code": "GB-NTY",
          "represent": "North Tyneside"
        }, {
          "code": "GB-NYK",
          "represent": "North Yorkshire"
        }, {
          "code": "GB-NTH",
          "represent": "Northamptonshire"
        }, {
          "code": "GB-NBL",
          "represent": "Northumberland"
        }, {
          "code": "GB-NGM",
          "represent": "Nottingham (City of)"
        }, {
          "code": "GB-NTT",
          "represent": "Nottinghamshire"
        }, {
          "code": "GB-OLD",
          "represent": "Oldham"
        }, {
          "code": "GB-OXF",
          "represent": "Oxfordshire"
        }, {
          "code": "GB-PTE",
          "represent": "Peterborough"
        }, {
          "code": "GB-PLY",
          "represent": "Plymouth (City of)"
        }, {
          "code": "GB-POL",
          "represent": "Poole"
        }, {
          "code": "GB-POR",
          "represent": "Portsmouth (City of)"
        }, {
          "code": "GB-RDG",
          "represent": "Reading"
        }, {
          "code": "GB-RDB",
          "represent": "Redbridge"
        }, {
          "code": "GB-RCC",
          "represent": "Redcar and Cleveland"
        }, {
          "code": "GB-RIC",
          "represent": "Richmond upon Thames"
        }, {
          "code": "GB-RCH",
          "represent": "Rochdale"
        }, {
          "code": "GB-ROT",
          "represent": "Rotherharn"
        }, {
          "code": "GB-RUT",
          "represent": "Rutland"
        }, {
          "code": "GB-SHN",
          "represent": "St. Helens"
        }, {
          "code": "GB-SLF",
          "represent": "Salford (City of)"
        }, {
          "code": "GB-SAW",
          "represent": "Sandweil"
        }, {
          "code": "GB-SFT",
          "represent": "Sefton"
        }, {
          "code": "GB-SHF",
          "represent": "Sheffield (City of)"
        }, {
          "code": "GB-SHR",
          "represent": "Shropshire"
        }, {
          "code": "GB-SLG",
          "represent": "Slough"
        }, {
          "code": "GB-SOL",
          "represent": "Solihull"
        }, {
          "code": "GB-SOM",
          "represent": "Somerset"
        }, {
          "code": "GB-SGC",
          "represent": "South Gloucestershire"
        }, {
          "code": "GB-STY",
          "represent": "South Tyneside"
        }, {
          "code": "GB-STH",
          "represent": "Southampton (City of)"
        }, {
          "code": "GB-SOS",
          "represent": "Southend-on-Sea"
        }, {
          "code": "GB-SWK",
          "represent": "Southwark"
        }, {
          "code": "GB-STS",
          "represent": "Staffordshire"
        }, {
          "code": "GB-SKP",
          "represent": "Stockport"
        }, {
          "code": "GB-STT",
          "represent": "Stockton-On-Tees"
        }, {
          "code": "GB-STE",
          "represent": "Stoke-on-Trent (City of)"
        }, {
          "code": "GB-SFK",
          "represent": "Suffolk"
        }, {
          "code": "GB-SND",
          "represent": "Sunderland (City of)"
        }, {
          "code": "GB-SRY",
          "represent": "Surrey"
        }, {
          "code": "GB-STN",
          "represent": "Sutton"
        }, {
          "code": "GB-SWD",
          "represent": "Swindon"
        }, {
          "code": "GB-TAM",
          "represent": "Tameside"
        }, {
          "code": "GB-TFW",
          "represent": "Telford and Wrekin"
        }, {
          "code": "GB-THR",
          "represent": "Thurrock"
        }, {
          "code": "GB-TOB",
          "represent": "Torbay"
        }, {
          "code": "GB-TWH",
          "represent": "Tower Hamlets"
        }, {
          "code": "GB-TRF",
          "represent": "Trafford"
        }, {
          "code": "GB-WKF",
          "represent": "Wakefield (City of)"
        }, {
          "code": "GB-WLL",
          "represent": "Walsall"
        }, {
          "code": "GB-WFT",
          "represent": "Waltham Forest"
        }, {
          "code": "GB-WND",
          "represent": "Wandsworth"
        }, {
          "code": "GB-WRT",
          "represent": "Warrington"
        }, {
          "code": "GB-WAR",
          "represent": "Warwickshire"
        }, {
          "code": "GB-WBK",
          "represent": "West Berkshire"
        }, {
          "code": "GB-WSX",
          "represent": "West Sussex"
        }, {
          "code": "GB-WSM",
          "represent": "Westminster (City of)"
        }, {
          "code": "GB-WGN",
          "represent": "Wigan"
        }, {
          "code": "GB-WIL",
          "represent": "Wiltshire"
        }, {
          "code": "GB-WNM",
          "represent": "Windsor and Maidenhead"
        }, {
          "code": "GB-WRL",
          "represent": "Wirral"
        }, {
          "code": "GB-WOK",
          "represent": "Wokingham"
        }, {
          "code": "GB-WLV",
          "represent": "Wolverhampton"
        }, {
          "code": "GB-WOR",
          "represent": "Worcestershire"
        }, {
          "code": "GB-YOR",
          "represent": "York (City of)"
        }, {
          "code": "GB-IOM",
          "represent": "Isle of Man"
        }, {
          "code": "GB-NIR",
          "represent": "Northern Ireland"
        }, {
          "code": "GB-ANT",
          "represent": "Antrim"
        }, {
          "code": "GB-ARD",
          "represent": "Ards"
        }, {
          "code": "GB-ARM",
          "represent": "Armagh"
        }, {
          "code": "GB-BLA",
          "represent": "Ballymena"
        }, {
          "code": "GB-BLY",
          "represent": "Ballymoney"
        }, {
          "code": "GB-BNB",
          "represent": "Banbridge"
        }, {
          "code": "GB-BFS",
          "represent": "Belfast (City of)"
        }, {
          "code": "GB-CKF",
          "represent": "Carrickfergus"
        }, {
          "code": "GB-CSR",
          "represent": "Castlereagh"
        }, {
          "code": "GB-CLR",
          "represent": "Coleraine"
        }, {
          "code": "GB-CKT",
          "represent": "Cookstown"
        }, {
          "code": "GB-CGV",
          "represent": "Craigavon"
        }, {
          "code": "GB-DRY",
          "represent": "Derry (City of)"
        }, {
          "code": "GB-DOW",
          "represent": "Down"
        }, {
          "code": "GB-DGN",
          "represent": "Dungannon"
        }, {
          "code": "GB-FER",
          "represent": "Fermanagh"
        }, {
          "code": "GB-LRN",
          "represent": "Larne"
        }, {
          "code": "GB-LMV",
          "represent": "Limavady"
        }, {
          "code": "GB-LSB",
          "represent": "Lisburn"
        }, {
          "code": "GB-MFT",
          "represent": "Magherafelt"
        }, {
          "code": "GB-MYL",
          "represent": "Moyle"
        }, {
          "code": "GB-NYM",
          "represent": "Newry and Mourne"
        }, {
          "code": "GB-NTA",
          "represent": "Newtownabbey"
        }, {
          "code": "GB-NDN",
          "represent": "North Down"
        }, {
          "code": "GB-OMH",
          "represent": "Omagh"
        }, {
          "code": "GB-STB",
          "represent": "Strabane"
        }, {
          "code": "GB-SCT",
          "represent": "Scotland"
        }, {
          "code": "GB-ABE",
          "represent": "Aberdeen City"
        }, {
          "code": "GB-ABD",
          "represent": "Aberdeenshire"
        }, {
          "code": "GB-ANS",
          "represent": "Angus"
        }, {
          "code": "GB-AGB",
          "represent": "Argyll and Bute"
        }, {
          "code": "GB-CLK",
          "represent": "Clackmannanshire"
        }, {
          "code": "GB-DGY",
          "represent": "Dumfries and Galloway"
        }, {
          "code": "GB-DND",
          "represent": "Dundee City"
        }, {
          "code": "GB-EAY",
          "represent": "East Ayrshire"
        }, {
          "code": "GB-EDU",
          "represent": "East Dunbartonshire"
        }, {
          "code": "GB-ELN",
          "represent": "East Lothian"
        }, {
          "code": "GB-ERW",
          "represent": "East Renfrewshire"
        }, {
          "code": "GB-EDH",
          "represent": "Edinburgh, City of"
        }, {
          "code": "GB-ELS",
          "represent": "Eilean Siar"
        }, {
          "code": "GB-FAL",
          "represent": "Falkirk"
        }, {
          "code": "GB-FIF",
          "represent": "Fife"
        }, {
          "code": "GB-GLG",
          "represent": "Glasgow City"
        }, {
          "code": "GB-HLD",
          "represent": "Highland"
        }, {
          "code": "GB-IVC",
          "represent": "Inverclyde"
        }, {
          "code": "GB-MLN",
          "represent": "Midlothian"
        }, {
          "code": "GB-MRY",
          "represent": "Moray"
        }, {
          "code": "GB-NAY",
          "represent": "North Ayrshire"
        }, {
          "code": "GB-NLK",
          "represent": "North Lanarkshire"
        }, {
          "code": "GB-ORK",
          "represent": "Orkney Islands"
        }, {
          "code": "GB-PKN",
          "represent": "Perth and Kinross"
        }, {
          "code": "GB-RFW",
          "represent": "Renfrewshire"
        }, {
          "code": "GB-SCB",
          "represent": "Scottish Borders, The"
        }, {
          "code": "GB-ZET",
          "represent": "Shetland Islands"
        }, {
          "code": "GB-SAY",
          "represent": "South Ayrshire"
        }, {
          "code": "GB-SLK",
          "represent": "South Lanarkshire"
        }, {
          "code": "GB-STG",
          "represent": "Stirling"
        }, {
          "code": "GB-WDU",
          "represent": "West Dunbartonshire"
        }, {
          "code": "GB-WLN",
          "represent": "West Lothian"
        }, {
          "code": "GB-WLS",
          "represent": "Wales [Cymru]"
        }, {
          "code": "GB-BGW",
          "represent": "Blaenau Gwent"
        }, {
          "code": "GB-BGE",
          "represent": "Bridgend [Pen-y-bont ar Ogwr GB-POG]"
        }, {
          "code": "GB-CAY",
          "represent": "Caerphilly [Caerffili GB-CAF]"
        }, {
          "code": "GB-CRF",
          "represent": "Cardiff (City of) [Caerdydd GB-CRD]"
        }, {
          "code": "GB-CMN",
          "represent": "Carmarthenshire [Sir Gaerfyrddin GB-GFY]"
        }, {
          "code": "GB-CGN",
          "represent": "Ceredigion [Sir Ceredigion]"
        }, {
          "code": "GB-CWY",
          "represent": "Conwy"
        }, {
          "code": "GB-DEN",
          "represent": "Denbighshire [Sir Ddinbych GB-DDB]"
        }, {
          "code": "GB-FLN",
          "represent": "Flintshire [Sir y Fflint GB-FFL]"
        }, {
          "code": "GB-GWN",
          "represent": "Gwynedd"
        }, {
          "code": "GB-AGY",
          "represent": "Isle of Anglesey [Sir Ynys Man GB-YNM]"
        }, {
          "code": "GB-MTY",
          "represent": "Merthyr Tydfil [Merthyr Tudful GB-MTU]"
        }, {
          "code": "GB-MON",
          "represent": "Monmouthshire [Sir Fynwy GB-FYN]"
        }, {
          "code": "GB-NTL",
          "represent": "Neath Port Talbot [Castell-nedd Port Talbot GB-CTL]"
        }, {
          "code": "GB-NWP",
          "represent": "Newport [Casnewydd GB-CNW]"
        }, {
          "code": "GB-PEM",
          "represent": "Pembrokeshire [Sir Benfro CB-BNF]"
        }, {
          "code": "GB-POW",
          "represent": "Powys"
        }, {
          "code": "GB-RCT",
          "represent": "Rhondda, Cynon, Taff [Rhondda, Cynon, Taf]"
        }, {
          "code": "GB-SWA",
          "represent": "Swansea (City of) [Abertawe GB-ATA]"
        }, {
          "code": "GB-TOF",
          "represent": "Torfaen [Tor-faen]"
        }, {
          "code": "GB-VGL",
          "represent": "Vale of Glamorgan, The [Bro Morgannwg GB-BMG]"
        }, {
          "code": "GB-WRX",
          "represent": "Wrexham [Wrecsam GB-WRC]"
        }]
      }, {
        "name": "Greece",
        "code": "GR",
        "divisions": [{
          "code": "GR-I",
          "represent": "Anatoliki Makedonia kai Thraki"
        }, {
          "code": "GR-52",
          "represent": "Drama"
        }, {
          "code": "GR-71",
          "represent": "Evros"
        }, {
          "code": "GR-55",
          "represent": "Kavalla"
        }, {
          "code": "GR-73",
          "represent": "Rodopi"
        }, {
          "code": "GR-72",
          "represent": "Xanthi"
        }, {
          "code": "GR-IX",
          "represent": "Attiki"
        }, {
          "code": "GR-A1",
          "represent": "Attiki"
        }, {
          "code": "GR-VII",
          "represent": "Dytiki Ellada"
        }, {
          "code": "GR-13",
          "represent": "Achaïa"
        }, {
          "code": "GR-01",
          "represent": "Aitolia-Akarnania"
        }, {
          "code": "GR-14",
          "represent": "Ileia"
        }, {
          "code": "GR-III",
          "represent": "Dytiki Makedonia"
        }, {
          "code": "GR-63",
          "represent": "Florina"
        }, {
          "code": "GR-51",
          "represent": "Grevena"
        }, {
          "code": "GR-56",
          "represent": "Kastoria"
        }, {
          "code": "GR-58",
          "represent": "Kozani"
        }, {
          "code": "GR-VI",
          "represent": "Ionioi Nisoi"
        }, {
          "code": "GR-23",
          "represent": "Kefallinia"
        }, {
          "code": "GR-22",
          "represent": "Kerkyra"
        }, {
          "code": "GR-24",
          "represent": "Lefkas"
        }, {
          "code": "GR-21",
          "represent": "Zakynthos"
        }, {
          "code": "GR-IV",
          "represent": "Ipeiros"
        }, {
          "code": "GR-31",
          "represent": "Arta"
        }, {
          "code": "GR-33",
          "represent": "Ioannina"
        }, {
          "code": "GR-34",
          "represent": "Preveza"
        }, {
          "code": "GR-32",
          "represent": "Thesprotia"
        }, {
          "code": "GR-II",
          "represent": "Kentriki Makedonia"
        }, {
          "code": "GR-64",
          "represent": "Chalkidiki"
        }, {
          "code": "GR-53",
          "represent": "Imathia"
        }, {
          "code": "GR-57",
          "represent": "Kilkis"
        }, {
          "code": "GR-59",
          "represent": "Pella"
        }, {
          "code": "GR-61",
          "represent": "Pieria"
        }, {
          "code": "GR-62",
          "represent": "Serrai"
        }, {
          "code": "GR-54",
          "represent": "Thessaloniki"
        }, {
          "code": "GR-XIII",
          "represent": "Kriti"
        }, {
          "code": "GR-94",
          "represent": "Chania"
        }, {
          "code": "GR-91",
          "represent": "Irakleion"
        }, {
          "code": "GR-92",
          "represent": "Lasithion"
        }, {
          "code": "GR-93",
          "represent": "Rethymnon"
        }, {
          "code": "GR-XII",
          "represent": "Notio Aigaio"
        }, {
          "code": "GR-81",
          "represent": "Dodekanisos"
        }, {
          "code": "GR-82",
          "represent": "Kyklades"
        }, {
          "code": "GR-X",
          "represent": "Peloponnisos"
        }, {
          "code": "GR-11",
          "represent": "Argolis"
        }, {
          "code": "GR-12",
          "represent": "Arkadia"
        }, {
          "code": "GR-15",
          "represent": "Korinthia"
        }, {
          "code": "GR-16",
          "represent": "Lakonia"
        }, {
          "code": "GR-17",
          "represent": "Messinia"
        }, {
          "code": "GR-VIII",
          "represent": "Sterea Ellada"
        }, {
          "code": "GR-05",
          "represent": "Evrytania"
        }, {
          "code": "GR-04",
          "represent": "Evvoia"
        }, {
          "code": "GR-07",
          "represent": "Fokis"
        }, {
          "code": "GR-06",
          "represent": "Fthiotis"
        }, {
          "code": "GR-03",
          "represent": "Voiotia"
        }, {
          "code": "GR-V",
          "represent": "Thessalia"
        }, {
          "code": "GR-41",
          "represent": "Karditsa"
        }, {
          "code": "GR-42",
          "represent": "Larisa"
        }, {
          "code": "GR-43",
          "represent": "Magnisia"
        }, {
          "code": "GR-44",
          "represent": "Trikala"
        }, {
          "code": "GR-XI",
          "represent": "Voreio Aigaio"
        }, {
          "code": "GR-85",
          "represent": "Chios"
        }, {
          "code": "GR-83",
          "represent": "Lesvos"
        }, {
          "code": "GR-84",
          "represent": "Samos"
        }]
      }, {
        "name": "Greenland",
        "code": "GL",
        "divisions": [{
          "code": "GL-GL",
          "represent": "Greenland"
        }]
      }, {
        "name": "Grenada",
        "code": "GD",
        "divisions": [{
          "code": "GD-GD",
          "represent": "Grenada"
        }]
      }, {
        "name": "Guadeloupe",
        "code": "GP",
        "divisions": [{
          "code": "GP-GP",
          "represent": "Guadeloupe"
        }]
      }, {
        "name": "Guam",
        "code": "GU",
        "divisions": [{
          "code": "GU-GU",
          "represent": "Guam"
        }]
      }, {
        "name": "Guatemala",
        "code": "GT",
        "divisions": [{
          "code": "GT-AV",
          "represent": "Alta Verapaz"
        }, {
          "code": "GT-BV",
          "represent": "Baja Verapaz"
        }, {
          "code": "GT-CM",
          "represent": "Chimaltenango"
        }, {
          "code": "GT-CQ",
          "represent": "Chiquimula"
        }, {
          "code": "GT-PR",
          "represent": "El Progreso"
        }, {
          "code": "GT-ES",
          "represent": "Escuintla"
        }, {
          "code": "GT-GU",
          "represent": "Guatemala"
        }, {
          "code": "GT-HU",
          "represent": "Huehuetenango"
        }, {
          "code": "GT-IZ",
          "represent": "Izabal"
        }, {
          "code": "GT-JA",
          "represent": "Jalapa"
        }, {
          "code": "GT-JU",
          "represent": "Jutiapa"
        }, {
          "code": "GT-PE",
          "represent": "Petén"
        }, {
          "code": "GT-QZ",
          "represent": "Quezaltenango"
        }, {
          "code": "GT-QC",
          "represent": "Quiché"
        }, {
          "code": "GT-RE",
          "represent": "Retalhuleu"
        }, {
          "code": "GT-SA",
          "represent": "Sacatepéquez"
        }, {
          "code": "GT-SM",
          "represent": "San Marcos"
        }, {
          "code": "GT-SR",
          "represent": "Santa Rosa"
        }, {
          "code": "GT-SO",
          "represent": "Sololá"
        }, {
          "code": "GT-SU",
          "represent": "Suchitepéquez"
        }, {
          "code": "GT-TO",
          "represent": "Totonicapán"
        }, {
          "code": "GT-ZA",
          "represent": "Zacapa"
        }]
      }, {
        "name": "Guinea",
        "code": "GN",
        "divisions": [{
          "code": "GN-B",
          "represent": "Bake, Gouvernorat de"
        }, {
          "code": "GN-BF",
          "represent": "Boffa"
        }, {
          "code": "GN-BK",
          "represent": "Boké"
        }, {
          "code": "GN-FR",
          "represent": "Fria"
        }, {
          "code": "GN-GA",
          "represent": "Gaoual"
        }, {
          "code": "GN-KD",
          "represent": "Kindia"
        }, {
          "code": "GN-C",
          "represent": "Conakry, Gouvernorat de"
        }, {
          "code": "GN-F",
          "represent": "Faranah, Gouvernorat de"
        }, {
          "code": "GN-DB",
          "represent": "Dabola"
        }, {
          "code": "GN-DI",
          "represent": "Dinguiraye"
        }, {
          "code": "GN-FA",
          "represent": "Faranah"
        }, {
          "code": "GN-KS",
          "represent": "Kissidougou"
        }, {
          "code": "GN-K",
          "represent": "Kankan, Gouvernorat de"
        }, {
          "code": "GN-KA",
          "represent": "Kankan"
        }, {
          "code": "GN-KE",
          "represent": "Kérouané"
        }, {
          "code": "GN-KO",
          "represent": "Kouroussa"
        }, {
          "code": "GN-MD",
          "represent": "Mandiana"
        }, {
          "code": "GN-SI",
          "represent": "Siguiri"
        }, {
          "code": "GN-D",
          "represent": "Kindia, Gouvernorat de"
        }, {
          "code": "GN-CO",
          "represent": "Coyah"
        }, {
          "code": "GN-DU",
          "represent": "Dubréka"
        }, {
          "code": "GN-FO",
          "represent": "Forécariah"
        }, {
          "code": "GN-TE",
          "represent": "Télimélé"
        }, {
          "code": "GN-L",
          "represent": "Labé, Gouvernorat de"
        }, {
          "code": "GN-KB",
          "represent": "Koubia"
        }, {
          "code": "GN-LA",
          "represent": "Labé"
        }, {
          "code": "GN-LE",
          "represent": "Lélouma"
        }, {
          "code": "GN-ML",
          "represent": "Mali"
        }, {
          "code": "GN-TO",
          "represent": "Tougué"
        }, {
          "code": "GN-M",
          "represent": "Mamou, Gouvernorat de"
        }, {
          "code": "GN-DL",
          "represent": "Dalaba"
        }, {
          "code": "GN-MM",
          "represent": "Mamou"
        }, {
          "code": "GN-PI",
          "represent": "Pita"
        }, {
          "code": "GN-N",
          "represent": "Nzérékoré, Gouvernorat de"
        }, {
          "code": "GN-BE",
          "represent": "Beyla"
        }, {
          "code": "GN-GU",
          "represent": "Guékédou"
        }, {
          "code": "GN-LO",
          "represent": "Lola"
        }, {
          "code": "GN-MC",
          "represent": "Macenta"
        }, {
          "code": "GN-NZ",
          "represent": "Nzérékoré"
        }, {
          "code": "GN-YO",
          "represent": "Yomou"
        }]
      }, {
        "name": "Guinea-Bissau",
        "code": "GW",
        "divisions": [{
          "code": "GW-BS",
          "represent": "Bissau"
        }, {
          "code": "GW-BA",
          "represent": "Bafatá"
        }, {
          "code": "GW-BM",
          "represent": "Biombo"
        }, {
          "code": "GW-BL",
          "represent": "Bolama"
        }, {
          "code": "GW-CA",
          "represent": "Cacheu"
        }, {
          "code": "GW-GA",
          "represent": "Gabú"
        }, {
          "code": "GW-OI",
          "represent": "Oio"
        }, {
          "code": "GW-QU",
          "represent": "Quinara"
        }]
      }, {
        "name": "Guyana",
        "code": "GY",
        "divisions": [{
          "code": "GY-BA",
          "represent": "Barima-Waini"
        }, {
          "code": "GY-CU",
          "represent": "Cuyuni-Mazaruni"
        }, {
          "code": "GY-DE",
          "represent": "Demerara-Mahaica"
        }, {
          "code": "GY-EB",
          "represent": "East Berbice-Corentyne"
        }, {
          "code": "GY-ES",
          "represent": "Essequibo Islands-West Demerara"
        }, {
          "code": "GY-MA",
          "represent": "Mahaica-Berbice"
        }, {
          "code": "GY-PM",
          "represent": "Pomeroon-Supenaam"
        }, {
          "code": "GY-PT",
          "represent": "Potaro-Siparuni"
        }, {
          "code": "GY-UD",
          "represent": "Upper Demerara-Berbice"
        }, {
          "code": "GY-UT",
          "represent": "Upper Takutu-Upper Essequibo"
        }]
      }, {
        "name": "Haiti",
        "code": "HT",
        "divisions": [{
          "code": "HT-AR",
          "represent": "Artibonite"
        }, {
          "code": "HT-CE",
          "represent": "Centre"
        }, {
          "code": "HT-GA",
          "represent": "Grande-Anse"
        }, {
          "code": "HT-ND",
          "represent": "Nord"
        }, {
          "code": "HT-NE",
          "represent": "Nord-Est"
        }, {
          "code": "HT-NO",
          "represent": "Nord-Ouest"
        }, {
          "code": "HT-OU",
          "represent": "Ouest"
        }, {
          "code": "HT-SD",
          "represent": "Sud"
        }, {
          "code": "HT-SE",
          "represent": "Sud-Est"
        }]
      }, {
        "name": "Heard & McDonald Islands",
        "code": "HM",
        "divisions": [{
          "code": "HM-HM",
          "represent": "Heard & McDonald Islands"
        }]
      }, {
        "name": "Vatican City (Holy See)",
        "code": "VA",
        "divisions": [{
          "code": "VA-VA",
          "represent": "Vatican City (Holy See)"
        }]
      }, {
        "name": "Honduras",
        "code": "HN",
        "divisions": [{
          "code": "HN-AT",
          "represent": "Atlántida"
        }, {
          "code": "HN-CL",
          "represent": "Colón"
        }, {
          "code": "HN-CM",
          "represent": "Comayagua"
        }, {
          "code": "HN-CP",
          "represent": "Copán"
        }, {
          "code": "HN-CR",
          "represent": "Cortés"
        }, {
          "code": "HN-CH",
          "represent": "Choluteca"
        }, {
          "code": "HN-EP",
          "represent": "El Paraíso"
        }, {
          "code": "HN-FM",
          "represent": "Francisco Morazán"
        }, {
          "code": "HN-GD",
          "represent": "Gracias a Dios"
        }, {
          "code": "HN-IN",
          "represent": "Intibucá"
        }, {
          "code": "HN-IB",
          "represent": "Islas de la Bahía"
        }, {
          "code": "HN-LP",
          "represent": "La Paz"
        }, {
          "code": "HN-LE",
          "represent": "Lempira"
        }, {
          "code": "HN-OC",
          "represent": "Ocotepeque"
        }, {
          "code": "HN-OL",
          "represent": "Olancho"
        }, {
          "code": "HN-SB",
          "represent": "Santa Bárbara"
        }, {
          "code": "HN-VA",
          "represent": "Valle"
        }, {
          "code": "HN-YO",
          "represent": "Yoro"
        }]
      }, {
        "name": "Hong Kong",
        "code": "HK",
        "divisions": [{
          "code": "HK-HK",
          "represent": "Hong Kong"
        }]
      }, {
        "name": "Hungary",
        "code": "HU",
        "divisions": [{
          "code": "HU-BU",
          "represent": "Budapest"
        }, {
          "code": "HU-BK",
          "represent": "Bács-Kiskun"
        }, {
          "code": "HU-BA",
          "represent": "Baranya"
        }, {
          "code": "HU-BE",
          "represent": "Békés"
        }, {
          "code": "HU-BZ",
          "represent": "Borsod-Abaúj-Zemplén"
        }, {
          "code": "HU-CS",
          "represent": "Csongrád"
        }, {
          "code": "HU-FE",
          "represent": "Fejér"
        }, {
          "code": "HU-GS",
          "represent": "Gyór-Moson-Sopron"
        }, {
          "code": "HU-HB",
          "represent": "Hajdú-Bihar"
        }, {
          "code": "HU-HE",
          "represent": "Heves"
        }, {
          "code": "HU-JN",
          "represent": "Jasz-Nagykun-Szolnok"
        }, {
          "code": "HU-KE",
          "represent": "Komárom-Esztergom"
        }, {
          "code": "HU-NO",
          "represent": "Nógrád"
        }, {
          "code": "HU-PE",
          "represent": "Pest"
        }, {
          "code": "HU-SO",
          "represent": "Somogy"
        }, {
          "code": "HU-SZ",
          "represent": "Szabolcs-Szatmár-Bereg"
        }, {
          "code": "HU-TO",
          "represent": "Tolna"
        }, {
          "code": "HU-VA",
          "represent": "Vas"
        }, {
          "code": "HU-VE",
          "represent": "Veszprém"
        }, {
          "code": "HU-ZA",
          "represent": "Zala"
        }, {
          "code": "HU-BC",
          "represent": "Békéscsaba"
        }, {
          "code": "HU-DE",
          "represent": "Debrecen"
        }, {
          "code": "HU-DU",
          "represent": "Dunaújváros"
        }, {
          "code": "HU-EG",
          "represent": "Eger"
        }, {
          "code": "HU-GY",
          "represent": "Gyór"
        }, {
          "code": "HU-HV",
          "represent": "Hódmezóvásárhely"
        }, {
          "code": "HU-KV",
          "represent": "Kaposvár"
        }, {
          "code": "HU-KM",
          "represent": "Kecskemét"
        }, {
          "code": "HU-MI",
          "represent": "Miskolc"
        }, {
          "code": "HU-NK",
          "represent": "Nagykanizsa"
        }, {
          "code": "HU-NY",
          "represent": "Nyíregyháza"
        }, {
          "code": "HU-PS",
          "represent": "Pécs"
        }, {
          "code": "HU-ST",
          "represent": "Salgótarján"
        }, {
          "code": "HU-SN",
          "represent": "Sopron"
        }, {
          "code": "HU-SD",
          "represent": "Szeged"
        }, {
          "code": "HU-SF",
          "represent": "Székesfehérvár"
        }, {
          "code": "HU-SS",
          "represent": "Szekszárd"
        }, {
          "code": "HU-SK",
          "represent": "Szolnok"
        }, {
          "code": "HU-SH",
          "represent": "Szombathely"
        }, {
          "code": "HU-TB",
          "represent": "Tatabánya"
        }, {
          "code": "HU-VM",
          "represent": "Veszprém"
        }, {
          "code": "HU-ZE",
          "represent": "Zalaegerszeg"
        }]
      }, {
        "name": "Iceland",
        "code": "IS",
        "divisions": [{
          "code": "IS-7",
          "represent": "Austurland"
        }, {
          "code": "IS-1",
          "represent": "Höfudborgarsvædi utan Reykjavíkur"
        }, {
          "code": "IS-6",
          "represent": "Nordurland eystra"
        }, {
          "code": "IS-5",
          "represent": "Nordurland vestra"
        }, {
          "code": "IS-0",
          "represent": "Reykjavīk"
        }, {
          "code": "IS-8",
          "represent": "Sudurland"
        }, {
          "code": "IS-2",
          "represent": "Sudurnes"
        }, {
          "code": "IS-4",
          "represent": "Vestfirdir"
        }, {
          "code": "IS-3",
          "represent": "Vesturland"
        }]
      }, {
        "name": "India",
        "code": "IN",
        "divisions": [{
          "code": "IN-AP",
          "represent": "Andhra Pradesh"
        }, {
          "code": "IN-AR",
          "represent": "Arunachal Pradesh"
        }, {
          "code": "IN-AS",
          "represent": "Assam"
        }, {
          "code": "IN-BR",
          "represent": "Bihar"
        }, {
          "code": "IN-GA",
          "represent": "Goa"
        }, {
          "code": "IN-GJ",
          "represent": "Gujarat"
        }, {
          "code": "IN-HR",
          "represent": "Haryana"
        }, {
          "code": "IN-HP",
          "represent": "Himachal Pradesh"
        }, {
          "code": "IN-JK",
          "represent": "Jammu and Kashmir"
        }, {
          "code": "IN-KA",
          "represent": "Karnataka"
        }, {
          "code": "IN-KL",
          "represent": "Kerala"
        }, {
          "code": "IN-MP",
          "represent": "Madhya Pradesh"
        }, {
          "code": "IN-MH",
          "represent": "Maharashtra"
        }, {
          "code": "IN-MN",
          "represent": "Manipur"
        }, {
          "code": "IN-ML",
          "represent": "Meghalaya"
        }, {
          "code": "IN-MZ",
          "represent": "Mizoram"
        }, {
          "code": "IN-NL",
          "represent": "Nagaland"
        }, {
          "code": "IN-OR",
          "represent": "Orissa"
        }, {
          "code": "IN-PB",
          "represent": "Punjab"
        }, {
          "code": "IN-RJ",
          "represent": "Rajasthan"
        }, {
          "code": "IN-SK",
          "represent": "Sikkim"
        }, {
          "code": "IN-TN",
          "represent": "Tamil Nadu"
        }, {
          "code": "IN-TS",
          "represent": "Telangana"
        }, {
          "code": "IN-TR",
          "represent": "Tripura"
        }, {
          "code": "IN-UP",
          "represent": "Uttar Pradesh"
        }, {
          "code": "IN-WB",
          "represent": "West Bengal"
        }, {
          "code": "IN-AN",
          "represent": "Andaman and Nicobar Islands"
        }, {
          "code": "IN-CH",
          "represent": "Chandigarh"
        }, {
          "code": "IN-DN",
          "represent": "Dadra and Nagar Haveli"
        }, {
          "code": "IN-DD",
          "represent": "Daman and Diu"
        }, {
          "code": "IN-DL",
          "represent": "Delhi"
        }, {
          "code": "IN-LD",
          "represent": "Lakshadweep"
        }, {
          "code": "IN-PY",
          "represent": "Pondicherry"
        }, {
          "code": "IN-TG",
          "represent": "Telangana"
        }]
      }, {
        "name": "Indonesia",
        "code": "ID",
        "divisions": [{
          "code": "ID-IJU",
          "represent": "Irian Jaya"
        }, {
          "code": "ID-IJ",
          "represent": "Irian Jaya"
        }, {
          "code": "ID-JWU",
          "represent": "Jawa"
        }, {
          "code": "ID-JB",
          "represent": "Jawa Barat"
        }, {
          "code": "ID-JT",
          "represent": "Jawa Tengah"
        }, {
          "code": "ID-JI",
          "represent": "Jawa Timur"
        }, {
          "code": "ID-JK",
          "represent": "Jakarta Raya"
        }, {
          "code": "ID-YO",
          "represent": "Yogyakarta"
        }, {
          "code": "ID-KAU",
          "represent": "Kalimantan"
        }, {
          "code": "ID-KB",
          "represent": "Kalimantan Barat"
        }, {
          "code": "ID-KS",
          "represent": "Kalimantan Selatan"
        }, {
          "code": "ID-KT",
          "represent": "Kalimantan Tengah"
        }, {
          "code": "ID-KI",
          "represent": "Kalimantan Timur"
        }, {
          "code": "ID-MAU",
          "represent": "Maluku"
        }, {
          "code": "ID-MA",
          "represent": "Maluku"
        }, {
          "code": "ID-NUU",
          "represent": "Nusa Tenggara"
        }, {
          "code": "ID-BA",
          "represent": "Bali"
        }, {
          "code": "ID-NB",
          "represent": "Nusa Tenggara Barat"
        }, {
          "code": "ID-NT",
          "represent": "Nusa Tenggara Timur"
        }, {
          "code": "ID-TT",
          "represent": "Timor Timur"
        }, {
          "code": "ID-SLU",
          "represent": "Sulawesi"
        }, {
          "code": "ID-SN",
          "represent": "Sulawesi Selatan"
        }, {
          "code": "ID-ST",
          "represent": "Sulawesi Tengah"
        }, {
          "code": "ID-SG",
          "represent": "Sulawesi Tenggara"
        }, {
          "code": "ID-SA",
          "represent": "Sulawesi Utara"
        }, {
          "code": "ID-SMU",
          "represent": "Sumatera"
        }, {
          "code": "ID-BE",
          "represent": "Bengkulu"
        }, {
          "code": "ID-JA",
          "represent": "Jambi"
        }, {
          "code": "ID-LA",
          "represent": "Lampung"
        }, {
          "code": "ID-RI",
          "represent": "Riau"
        }, {
          "code": "ID-SB",
          "represent": "Sumatera Barat"
        }, {
          "code": "ID-SS",
          "represent": "Sumatera Selatan"
        }, {
          "code": "ID-SU",
          "represent": "Sumatera Utara"
        }, {
          "code": "ID-AC",
          "represent": "Aceh"
        }]
      }, {
        "name": "Iran",
        "code": "IR",
        "divisions": [{
          "code": "IR-03",
          "represent": "Ardabīl"
        }, {
          "code": "IR-02",
          "represent": "Āzarbāyjān-e-Gharbī"
        }, {
          "code": "IR-01",
          "represent": "Āzarbāyjān-e-Sharqī"
        }, {
          "code": "IR-06",
          "represent": "Būshehr"
        }, {
          "code": "IR-08",
          "represent": "Chahār Maḩāll vā Bakhtīārī"
        }, {
          "code": "IR-04",
          "represent": "Eşfahān"
        }, {
          "code": "IR-14",
          "represent": "Fārs"
        }, {
          "code": "IR-19",
          "represent": "Gīlān"
        }, {
          "code": "IR-24",
          "represent": "Hamadān"
        }, {
          "code": "IR-23",
          "represent": "Hormozgān"
        }, {
          "code": "IR-05",
          "represent": "Īlām"
        }, {
          "code": "IR-15",
          "represent": "Kermān"
        }, {
          "code": "IR-17",
          "represent": "Kermānshāhān"
        }, {
          "code": "IR-09",
          "represent": "Khorāsān"
        }, {
          "code": "IR-10",
          "represent": "Khūzestān"
        }, {
          "code": "IR-18",
          "represent": "Kohkīlūyeh va Būyer Aḩmadī"
        }, {
          "code": "IR-16",
          "represent": "Kordestān"
        }, {
          "code": "IR-20",
          "represent": "Lorestān"
        }, {
          "code": "IR-22",
          "represent": "Markazī"
        }, {
          "code": "IR-21",
          "represent": "Māzandarān"
        }, {
          "code": "IR-26",
          "represent": "Qom"
        }, {
          "code": "IR-12",
          "represent": "Semnān"
        }, {
          "code": "IR-13",
          "represent": "Sīstān va Balūchestān"
        }, {
          "code": "IR-07",
          "represent": "Tehrān"
        }, {
          "code": "IR-25",
          "represent": "Yazd"
        }, {
          "code": "IR-11",
          "represent": "Zanjān"
        }]
      }, {
        "name": "Iraq",
        "code": "IQ",
        "divisions": [{
          "code": "IQ-AN",
          "represent": "Al Anbār"
        }, {
          "code": "IQ-BA",
          "represent": "Al Başrah"
        }, {
          "code": "IQ-MU",
          "represent": "Al Muthanná"
        }, {
          "code": "IQ-QA",
          "represent": "Al Qādisīyah"
        }, {
          "code": "IQ-NA",
          "represent": "An Najaf"
        }, {
          "code": "IQ-AR",
          "represent": "Arbīl"
        }, {
          "code": "IQ-SU",
          "represent": "As Sulaymānīyah"
        }, {
          "code": "IQ-TS",
          "represent": "At Ta'mīm"
        }, {
          "code": "IQ-BB",
          "represent": "Bābil"
        }, {
          "code": "IQ-BG",
          "represent": "Baghdād"
        }, {
          "code": "IQ-DA",
          "represent": "Dahūk"
        }, {
          "code": "IQ-DQ",
          "represent": "Dhī Qār"
        }, {
          "code": "IQ-DI",
          "represent": "Diyālá"
        }, {
          "code": "IQ-KA",
          "represent": "Karbalā'"
        }, {
          "code": "IQ-MA",
          "represent": "Maysān"
        }, {
          "code": "IQ-NI",
          "represent": "Nīnawá"
        }, {
          "code": "IQ-SD",
          "represent": "Şalāḩ ad Dīn"
        }, {
          "code": "IQ-WA",
          "represent": "Wāsiţ"
        }]
      }, {
        "name": "Ireland",
        "code": "IE",
        "divisions": [{
          "code": "IE-CP",
          "represent": "Connaught (Connacht)"
        }, {
          "code": "IE-G",
          "represent": "Galway (Gaillimh)"
        }, {
          "code": "IE-LM",
          "represent": "Leitrim (Liatroim)"
        }, {
          "code": "IE-MO",
          "represent": "Mayo (Maigh Eo)"
        }, {
          "code": "IE-RN",
          "represent": "Roscommon (Ros Comáin)"
        }, {
          "code": "IE-SO",
          "represent": "Sligo (Sligeach)"
        }, {
          "code": "IE-LP",
          "represent": "Leinster (Laighin)"
        }, {
          "code": "IE-CW",
          "represent": "Carlow (Ceatharlach)"
        }, {
          "code": "IE-D",
          "represent": "Dublin (Baile Átha Cliath)"
        }, {
          "code": "IE-KE",
          "represent": "Kildare (Cill Dara)"
        }, {
          "code": "IE-KK",
          "represent": "Kilkenny (Cill Chainnigh)"
        }, {
          "code": "IE-LS",
          "represent": "Laois (Laois)"
        }, {
          "code": "IE-LD",
          "represent": "Longford (An Longfort)"
        }, {
          "code": "IE-LH",
          "represent": "Louth (Lú)"
        }, {
          "code": "IE-MH",
          "represent": "Meath (An Mhí)"
        }, {
          "code": "IE-OY",
          "represent": "Offaly (Uíbh Fhailí)"
        }, {
          "code": "IE-WH",
          "represent": "Westmeath (An Iarmhí)"
        }, {
          "code": "IE-WX",
          "represent": "Wexford (Loch Garman)"
        }, {
          "code": "IE-WW",
          "represent": "Wicklow (Cill Mhantáin)"
        }, {
          "code": "IE-M",
          "represent": "Munster (An Mhumhain)"
        }, {
          "code": "IE-UP",
          "represent": "Ulster (Ulaidh)"
        }, {
          "code": "IE-CN",
          "represent": "Cavan (An Cabhán)"
        }, {
          "code": "IE-DL",
          "represent": "Donegal (Dún na nGall)"
        }, {
          "code": "IE-MN",
          "represent": "Monaghan (Muineachán)"
        }]
      }, {
        "name": "Israel",
        "code": "IL",
        "divisions": [{
          "code": "IL-D",
          "represent": "HaDarom (El Janūbī)"
        }, {
          "code": "IL-M",
          "represent": "HaMerkaz (El Awsat)"
        }, {
          "code": "IL-2",
          "represent": "HaZafon (Esh Shamālī)"
        }, {
          "code": "IL-HA",
          "represent": "Hefa (Heifā)"
        }, {
          "code": "IL-TA",
          "represent": "Tel-Aviv (Tell Abīb)"
        }, {
          "code": "IL-JM",
          "represent": "Yerushalayim (Al Quds)"
        }]
      }, {
        "name": "Italy",
        "code": "IT",
        "divisions": [{
          "code": "IT-65",
          "represent": "Abruzzo"
        }, {
          "code": "IT-CH",
          "represent": "Chieti"
        }, {
          "code": "IT-AQ",
          "represent": "L'Aquila"
        }, {
          "code": "IT-PE",
          "represent": "Pescara"
        }, {
          "code": "IT-TE",
          "represent": "Teramo"
        }, {
          "code": "IT-77",
          "represent": "Basilicata"
        }, {
          "code": "IT-MT",
          "represent": "Matera"
        }, {
          "code": "IT-PZ",
          "represent": "Potenza"
        }, {
          "code": "IT-78",
          "represent": "Calabria"
        }, {
          "code": "IT-CZ",
          "represent": "Catanzaro"
        }, {
          "code": "IT-CS",
          "represent": "Cosenza"
        }, {
          "code": "IT-KR",
          "represent": "Crotone"
        }, {
          "code": "IT-RC",
          "represent": "Reggio Calabria"
        }, {
          "code": "IT-W",
          "represent": "Vibo Valentia"
        }, {
          "code": "IT-72",
          "represent": "Campania"
        }, {
          "code": "IT-AV",
          "represent": "Avellino"
        }, {
          "code": "IT-BN",
          "represent": "Benevento"
        }, {
          "code": "IT-CE",
          "represent": "Caserta"
        }, {
          "code": "IT-NA",
          "represent": "Napoli"
        }, {
          "code": "IT-SA",
          "represent": "Salerno"
        }, {
          "code": "IT-45",
          "represent": "Emilia-Romagna"
        }, {
          "code": "IT-BO",
          "represent": "Bologna"
        }, {
          "code": "IT-FE",
          "represent": "Ferrara"
        }, {
          "code": "IT-FO",
          "represent": "Forlì"
        }, {
          "code": "IT-MO",
          "represent": "Modena"
        }, {
          "code": "IT-PR",
          "represent": "Parma"
        }, {
          "code": "IT-PC",
          "represent": "Piacenza"
        }, {
          "code": "IT-RA",
          "represent": "Ravenna"
        }, {
          "code": "IT-RE",
          "represent": "Reggio Emilia"
        }, {
          "code": "IT-RN",
          "represent": "Rimini"
        }, {
          "code": "IT-36",
          "represent": "Friuli-Venezia Giulia"
        }, {
          "code": "IT-GO",
          "represent": "Gorizia"
        }, {
          "code": "IT-PN",
          "represent": "Pordenone"
        }, {
          "code": "IT-TS",
          "represent": "Trieste"
        }, {
          "code": "IT-UD",
          "represent": "Udine"
        }, {
          "code": "IT-62",
          "represent": "Lazio"
        }, {
          "code": "IT-FR",
          "represent": "Frosinone"
        }, {
          "code": "IT-LT",
          "represent": "Latina"
        }, {
          "code": "IT-RI",
          "represent": "Rieti"
        }, {
          "code": "IT-RM",
          "represent": "Roma"
        }, {
          "code": "IT-VT",
          "represent": "Viterbo"
        }, {
          "code": "IT-42",
          "represent": "Liguria"
        }, {
          "code": "IT-GE",
          "represent": "Genova"
        }, {
          "code": "IT-IM",
          "represent": "Imperia"
        }, {
          "code": "IT-SP",
          "represent": "La Spezia"
        }, {
          "code": "IT-SV",
          "represent": "Savona"
        }, {
          "code": "IT-25",
          "represent": "Lombardia"
        }, {
          "code": "IT-BG",
          "represent": "Bergamo"
        }, {
          "code": "IT-BS",
          "represent": "Brescia"
        }, {
          "code": "IT-CO",
          "represent": "Como"
        }, {
          "code": "IT-CR",
          "represent": "Cremona"
        }, {
          "code": "IT-LC",
          "represent": "Lecco"
        }, {
          "code": "IT-LO",
          "represent": "Lodi"
        }, {
          "code": "IT-MN",
          "represent": "Mantova"
        }, {
          "code": "IT-MI",
          "represent": "Milano"
        }, {
          "code": "IT-PV",
          "represent": "Pavia"
        }, {
          "code": "IT-SO",
          "represent": "Sondrio"
        }, {
          "code": "IT-VA",
          "represent": "Varese"
        }, {
          "code": "IT-57",
          "represent": "Marche"
        }, {
          "code": "IT-AN",
          "represent": "Ancona"
        }, {
          "code": "IT-AP",
          "represent": "Ascoli Piceno"
        }, {
          "code": "IT-MC",
          "represent": "Macerata"
        }, {
          "code": "IT-PS",
          "represent": "Pesaro"
        }, {
          "code": "IT-67",
          "represent": "Molise"
        }, {
          "code": "IT-CB",
          "represent": "Campobasso"
        }, {
          "code": "IT-IS",
          "represent": "Isernia"
        }, {
          "code": "IT-21",
          "represent": "Piemonte"
        }, {
          "code": "IT-AL",
          "represent": "Alessandria"
        }, {
          "code": "IT-AT",
          "represent": "Asti"
        }, {
          "code": "IT-BI",
          "represent": "Biella"
        }, {
          "code": "IT-CN",
          "represent": "Cuneo"
        }, {
          "code": "IT-NO",
          "represent": "Novara"
        }, {
          "code": "IT-TO",
          "represent": "Torino"
        }, {
          "code": "IT-VB",
          "represent": "Verbano-Cusio-Ossola"
        }, {
          "code": "IT-VC",
          "represent": "Vercelli"
        }, {
          "code": "IT-75",
          "represent": "Puglia"
        }, {
          "code": "IT-BA",
          "represent": "Bari"
        }, {
          "code": "IT-BR",
          "represent": "Brindisi"
        }, {
          "code": "IT-FG",
          "represent": "Foggia"
        }, {
          "code": "IT-LE",
          "represent": "Lecce"
        }, {
          "code": "IT-TA",
          "represent": "Taranto"
        }, {
          "code": "IT-88",
          "represent": "Sardegna"
        }, {
          "code": "IT-CA",
          "represent": "Cagliari"
        }, {
          "code": "IT-NU",
          "represent": "Nuoro"
        }, {
          "code": "IT-OR",
          "represent": "Oristano"
        }, {
          "code": "IT-SS",
          "represent": "Sassari"
        }, {
          "code": "IT-82",
          "represent": "Sicilia"
        }, {
          "code": "IT-AG",
          "represent": "Agrigento"
        }, {
          "code": "IT-CL",
          "represent": "Caltanissetta"
        }, {
          "code": "IT-CT",
          "represent": "Catania"
        }, {
          "code": "IT-EN",
          "represent": "Enna"
        }, {
          "code": "IT-ME",
          "represent": "Mesaina"
        }, {
          "code": "IT-PA",
          "represent": "Palermo"
        }, {
          "code": "IT-RG",
          "represent": "Ragusa"
        }, {
          "code": "IT-SR",
          "represent": "Siracusa"
        }, {
          "code": "IT-TP",
          "represent": "Trapani"
        }, {
          "code": "IT-52",
          "represent": "Toscana"
        }, {
          "code": "IT-AR",
          "represent": "Arezzo"
        }, {
          "code": "IT-FI",
          "represent": "Firenze"
        }, {
          "code": "IT-GR",
          "represent": "Grosseto"
        }, {
          "code": "IT-LI",
          "represent": "Livorno"
        }, {
          "code": "IT-LU",
          "represent": "Lucca"
        }, {
          "code": "IT-MS",
          "represent": "Massa"
        }, {
          "code": "IT-PI",
          "represent": "Pisa"
        }, {
          "code": "IT-PT",
          "represent": "Pistoia"
        }, {
          "code": "IT-PO",
          "represent": "Prato"
        }, {
          "code": "IT-SI",
          "represent": "Siena"
        }, {
          "code": "IT-32",
          "represent": "Trentino-Alte Adige (Trentino-Südtirol)"
        }, {
          "code": "IT-BZ",
          "represent": "Bolzano (Bozen)"
        }, {
          "code": "IT-TN",
          "represent": "Trento"
        }, {
          "code": "IT-55",
          "represent": "Umbria"
        }, {
          "code": "IT-PG",
          "represent": "Perugia"
        }, {
          "code": "IT-TR",
          "represent": "Terni"
        }, {
          "code": "IT-23",
          "represent": "Valle d'Aosta (Vallée d'Aoste)"
        }, {
          "code": "IT-AO",
          "represent": "Aosta (Aoste)"
        }, {
          "code": "IT-34",
          "represent": "Veneto"
        }, {
          "code": "IT-BL",
          "represent": "Belluno"
        }, {
          "code": "IT-PD",
          "represent": "Padova"
        }, {
          "code": "IT-RO",
          "represent": "Rovigo"
        }, {
          "code": "IT-TV",
          "represent": "Treviso"
        }, {
          "code": "IT-VE",
          "represent": "Venezia"
        }, {
          "code": "IT-VR",
          "represent": "Verona"
        }, {
          "code": "IT-VI",
          "represent": "Vicenza"
        }]
      }, {
        "name": "Jamaica",
        "code": "JM",
        "divisions": [{
          "code": "JM-13",
          "represent": "Clarendon"
        }, {
          "code": "JM-09",
          "represent": "Hanover"
        }, {
          "code": "JM-01",
          "represent": "Kingston"
        }, {
          "code": "JM-12",
          "represent": "Manchester"
        }, {
          "code": "JM-04",
          "represent": "Portland"
        }, {
          "code": "JM-02",
          "represent": "Saint Andrew"
        }, {
          "code": "JM-06",
          "represent": "Saint Ann"
        }, {
          "code": "JM-14",
          "represent": "Saint Catherine"
        }, {
          "code": "JM-11",
          "represent": "Saint Elizabeth"
        }, {
          "code": "JM-08",
          "represent": "Saint James"
        }, {
          "code": "JM-05",
          "represent": "Saint Mary"
        }, {
          "code": "JM-03",
          "represent": "Saint Thomas"
        }, {
          "code": "JM-07",
          "represent": "Trelawny"
        }, {
          "code": "JM-10",
          "represent": "Westmoreland"
        }]
      }, {
        "name": "Japan",
        "code": "JP",
        "divisions": [{
          "code": "JP-23",
          "represent": "Aiti [Aichi]"
        }, {
          "code": "JP-05",
          "represent": "Akita"
        }, {
          "code": "JP-02",
          "represent": "Aomori"
        }, {
          "code": "JP-38",
          "represent": "Ehime"
        }, {
          "code": "JP-21",
          "represent": "Gihu [Gifu]"
        }, {
          "code": "JP-10",
          "represent": "Gunma"
        }, {
          "code": "JP-34",
          "represent": "Hirosima [Hiroshima]"
        }, {
          "code": "JP-01",
          "represent": "Hokkaidô [Hokkaido]"
        }, {
          "code": "JP-18",
          "represent": "Hukui [Fukui]"
        }, {
          "code": "JP-40",
          "represent": "Hukuoka [Fukuoka]"
        }, {
          "code": "JP-07",
          "represent": "Hukusima [Fukushima]"
        }, {
          "code": "JP-28",
          "represent": "Hyôgo [Hyogo]"
        }, {
          "code": "JP-08",
          "represent": "Ibaraki"
        }, {
          "code": "JP-17",
          "represent": "Isikawa [Ishikawa]"
        }, {
          "code": "JP-03",
          "represent": "Iwate"
        }, {
          "code": "JP-37",
          "represent": "Kagawa"
        }, {
          "code": "JP-46",
          "represent": "Kagosima [Kagoshima]"
        }, {
          "code": "JP-14",
          "represent": "Kanagawa"
        }, {
          "code": "JP-39",
          "represent": "Kôti [Kochi]"
        }, {
          "code": "JP-43",
          "represent": "Kumamoto"
        }, {
          "code": "JP-26",
          "represent": "Kyôto [Kyoto]"
        }, {
          "code": "JP-24",
          "represent": "Mie"
        }, {
          "code": "JP-04",
          "represent": "Miyagi"
        }, {
          "code": "JP-45",
          "represent": "Miyazaki"
        }, {
          "code": "JP-20",
          "represent": "Nagano"
        }, {
          "code": "JP-42",
          "represent": "Nagasaki"
        }, {
          "code": "JP-29",
          "represent": "Nara"
        }, {
          "code": "JP-15",
          "represent": "Niigata"
        }, {
          "code": "JP-44",
          "represent": "Ôita [Oita]"
        }, {
          "code": "JP-33",
          "represent": "Okayama"
        }, {
          "code": "JP-47",
          "represent": "Okinawa"
        }, {
          "code": "JP-27",
          "represent": "Ôsaka [Osaka]"
        }, {
          "code": "JP-41",
          "represent": "Saga"
        }, {
          "code": "JP-11",
          "represent": "Saitama"
        }, {
          "code": "JP-25",
          "represent": "Siga [Shiga]"
        }, {
          "code": "JP-22",
          "represent": "Sizuoka [Shizuoka]"
        }, {
          "code": "JP-12",
          "represent": "Tiba [Chiba]"
        }, {
          "code": "JP-09",
          "represent": "Totigi [Tochigi]"
        }, {
          "code": "JP-36",
          "represent": "Tokusima [Tokushima]"
        }, {
          "code": "JP-13",
          "represent": "Tôkyô [Tokyo]"
        }, {
          "code": "JP-31",
          "represent": "Tottori"
        }, {
          "code": "JP-16",
          "represent": "Toyama"
        }, {
          "code": "JP-30",
          "represent": "Wakayama"
        }, {
          "code": "JP-06",
          "represent": "Yamagata"
        }, {
          "code": "JP-35",
          "represent": "Yamaguti [Yamaguchi]"
        }, {
          "code": "JP-19",
          "represent": "Yamanasi [Yamanashi]"
        }]
      }, {
        "name": "Jordan",
        "code": "JO",
        "divisions": [{
          "code": "JO-AJ",
          "represent": "‘Ajlūn"
        }, {
          "code": "JO-AQ",
          "represent": "Al 'Aqaba"
        }, {
          "code": "JO-BA",
          "represent": "Al Balqā'"
        }, {
          "code": "JO-KA",
          "represent": "Al Karak"
        }, {
          "code": "JO-MA",
          "represent": "Al Mafraq"
        }, {
          "code": "JO-AM",
          "represent": "‘Ammān"
        }, {
          "code": "JO-AT",
          "represent": "Aţ Ţafīlah"
        }, {
          "code": "JO-AZ",
          "represent": "Az Zarqā'"
        }, {
          "code": "JO-IR",
          "represent": "Irbid"
        }, {
          "code": "JO-JA",
          "represent": "Jarash"
        }, {
          "code": "JO-MN",
          "represent": "Ma‘ān"
        }, {
          "code": "JO-MD",
          "represent": "Mādaba"
        }]
      }, {
        "name": "Kazakhstan",
        "code": "KZ",
        "divisions": [{
          "code": "KZ-ALA",
          "represent": "Almaty"
        }, {
          "code": "KZ-BAY",
          "represent": "Bayqonyr (Baykonyr)"
        }, {
          "code": "KZ-ALM",
          "represent": "Almaty oblysy (Almatinskaya oblast')"
        }, {
          "code": "KZ-AKM",
          "represent": "Aqmola oblysy (Akmolinskaya oblast')"
        }, {
          "code": "KZ-AKT",
          "represent": "Aqtöbe oblysy (Aktyubinskaya oblast')"
        }, {
          "code": "KZ-ATY",
          "represent": "Atyraü oblysy (Atyrauskaya oblast')"
        }, {
          "code": "KZ-ZAP",
          "represent": "Batys Kazakstan oblysy (Zapadno-Kazakhstanskaya oblast')"
        }, {
          "code": "KZ-MAN",
          "represent": "Mangghystaū oblysy (Mangystauskaya oblast')"
        }, {
          "code": "KZ-YUZ",
          "represent": "Ongtüstik Kazakstan oblysy (Yuzhno-Kazakhstanskaya oblast')"
        }, {
          "code": "KZ-PAV",
          "represent": "Pavlodar oblysy (Pavlodarskaya oblast')"
        }, {
          "code": "KZ-KAR",
          "represent": "Qaraghandy oblysy (Karagandinskaya oblast')"
        }, {
          "code": "KZ-KUS",
          "represent": "Qostanay oblysy (Kustanayskaya oblast')"
        }, {
          "code": "KZ-KZY",
          "represent": "Qyzylorda oblysy (Kzylordinskaya oblast')"
        }, {
          "code": "KZ-VOS",
          "represent": "Shyghys Kazakstan oblysy (Vostochno-Kazakhstanskaya oblast')"
        }, {
          "code": "KZ-SEV",
          "represent": "Soltüstik Kazakstan oblysy (Severo-Kazakhstanskaya oblast')"
        }, {
          "code": "KZ-ZHA",
          "represent": "Zhambyl oblysy (Zhambylskaya Oblast')"
        }]
      }, {
        "name": "Kenya",
        "code": "KE",
        "divisions": [{
          "code": "KE-110",
          "represent": "Nairobi Municipality"
        }, {
          "code": "KE-200",
          "represent": "Central (Kati)"
        }, {
          "code": "KE-300",
          "represent": "Coast (Pwani)"
        }, {
          "code": "KE-400",
          "represent": "Eastern (Mashariki)"
        }, {
          "code": "KE-500",
          "represent": "North-Eastern (Kaskazini Mashariki)"
        }, {
          "code": "KE-600",
          "represent": "Nyanza"
        }, {
          "code": "KE-700",
          "represent": "Rift Valley"
        }, {
          "code": "KE-900",
          "represent": "Western (Magharibi)"
        }]
      }, {
        "name": "Kiribati",
        "code": "KI",
        "divisions": [{
          "code": "KI-G",
          "represent": "Gilbert Islands"
        }, {
          "code": "KI-L",
          "represent": "Line Islands"
        }, {
          "code": "KI-P",
          "represent": "Phoenix Islands"
        }]
      }, {
        "name": "Korea (North)",
        "code": "KP",
        "divisions": [{
          "code": "KP-KAE",
          "represent": "Kaesong-si"
        }, {
          "code": "KP-NAM",
          "represent": "Nampo-si"
        }, {
          "code": "KP-PYO",
          "represent": "Pyongyang-si"
        }, {
          "code": "KP-CHA",
          "represent": "Chagang-do"
        }, {
          "code": "KP-HAB",
          "represent": "Hamgyongbuk-do"
        }, {
          "code": "KP-HAN",
          "represent": "Hamgyongnam-do"
        }, {
          "code": "KP-HWB",
          "represent": "Hwanghaebuk-do"
        }, {
          "code": "KP-HWN",
          "represent": "Hwanghaenam-do"
        }, {
          "code": "KP-KAN",
          "represent": "Kangwon-do"
        }, {
          "code": "KP-PYB",
          "represent": "Pyonganbuk-do"
        }, {
          "code": "KP-PYN",
          "represent": "Pyongannam-do"
        }, {
          "code": "KP-YAN",
          "represent": "Yanggang-do"
        }]
      }, {
        "name": "Korea (South)",
        "code": "KR",
        "divisions": [{
          "code": "KR-11",
          "represent": "Seoul Teugbyeolsi [ Seoul-T’ŭkpyŏlshi]"
        }, {
          "code": "KR-26",
          "represent": "Busan Gwang'yeogsi [Pusan-Kwangyŏkshi]"
        }, {
          "code": "KR-27",
          "represent": "Daegu Gwang'yeogsi [Taegu-Kwangyŏkshi)"
        }, {
          "code": "KR-30",
          "represent": "Daejeon Gwang'yeogsi [Taejŏn-Kwangyŏkshi]"
        }, {
          "code": "KR-29",
          "represent": "Gwangju Gwang'yeogsi [Kwangju-Kwangyŏkshi]"
        }, {
          "code": "KR-28",
          "represent": "Incheon Gwang'yeogsi [Inchŏn-Kwangyŏkshi]"
        }, {
          "code": "KR-31",
          "represent": "Ulsan Gwang'yeogsi [Ulsan-Kwangyŏkshi]"
        }, {
          "code": "KR-43",
          "represent": "Chungcheongbugdo [Ch'ungch'ŏngbuk-do]"
        }, {
          "code": "KR-44",
          "represent": "Chungcheongnamdo [Ch'ungch'ŏngnam-do]"
        }, {
          "code": "KR-42",
          "represent": "Gang'weondo [Kang-won-do]"
        }, {
          "code": "KR-41",
          "represent": "Gyeonggido [Kyŏnggi-do]"
        }, {
          "code": "KR-47",
          "represent": "Gyeongsangbugdo [Kyŏngsangbuk-do]"
        }, {
          "code": "KR-48",
          "represent": "Gyeongsangnamdo [Kyŏngsangnam-do]"
        }, {
          "code": "KR-49",
          "represent": "Jejudo [Cheju-do]"
        }, {
          "code": "KR-45",
          "represent": "Jeonrabugdo [Chŏllabuk-do)"
        }, {
          "code": "KR-46",
          "represent": "Jeonranamdo [Chŏllanam-do]"
        }]
      }, {
        "name": "Kuwait",
        "code": "KW",
        "divisions": [{
          "code": "KW-AH",
          "represent": "Al Aḩmadi"
        }, {
          "code": "KW-FA",
          "represent": "Al Farwānīyah"
        }, {
          "code": "KW-JA",
          "represent": "Al Jahrah"
        }, {
          "code": "KW-KU",
          "represent": "Al Kuwayt"
        }, {
          "code": "KW-HA",
          "represent": "Ḩawallī"
        }]
      }, {
        "name": "Kyrgyzstan",
        "code": "KG",
        "divisions": [{
          "code": "KG-C",
          "represent": "Chu (Chuyskaya oblast')"
        }, {
          "code": "KG-J",
          "represent": "Jalal-Abad (Dzhalal-Abadskaya oblast')"
        }, {
          "code": "KG-N",
          "represent": "Naryn (Narynskaya Oblast’)"
        }, {
          "code": "KG-O",
          "represent": "Osh (Oshskaya oblast')"
        }, {
          "code": "KG-T",
          "represent": "Talas (Talasskaya oblast')"
        }, {
          "code": "KG-Y",
          "represent": "Ysyk-Köl (Issyk-Kul'skaya oblast')"
        }]
      }, {
        "name": "Laos",
        "code": "LA",
        "divisions": [{
          "code": "LA-VT",
          "represent": "Vientiane"
        }, {
          "code": "LA-AT",
          "represent": "Attapu [Attopeu]"
        }, {
          "code": "LA-BK",
          "represent": "Bokèo"
        }, {
          "code": "LA-BL",
          "represent": "Bolikhamxai [Borikhane]"
        }, {
          "code": "LA-CH",
          "represent": "Champasak [Champassak]"
        }, {
          "code": "LA-HO",
          "represent": "Houaphan"
        }, {
          "code": "LA-KH",
          "represent": "Khammouan"
        }, {
          "code": "LA-LM",
          "represent": "Louang Namtha"
        }, {
          "code": "LA-LP",
          "represent": "Louangphabang [Louang Prabang]"
        }, {
          "code": "LA-OU",
          "represent": "Oudômxai [Oudomsai]"
        }, {
          "code": "LA-PH",
          "represent": "Phôngsali [Phong Saly]"
        }, {
          "code": "LA-SL",
          "represent": "Salavan [Saravane]"
        }, {
          "code": "LA-SV",
          "represent": "Savannakhét"
        }, {
          "code": "LA-VI",
          "represent": "Vientiane"
        }, {
          "code": "LA-XA",
          "represent": "Xaignabouli [Sayaboury]"
        }, {
          "code": "LA-XE",
          "represent": "Xékong [Sékong]"
        }, {
          "code": "LA-XI",
          "represent": "Xiangkhoang [Xieng Khouang]"
        }]
      }, {
        "name": "Latvia",
        "code": "LV",
        "divisions": [{
          "code": "LV-AI",
          "represent": "Aizkraukles Aprinkis"
        }, {
          "code": "LV-AL",
          "represent": "Alūksnes Aprinkis"
        }, {
          "code": "LV-BL",
          "represent": "Balvu Aprinkis"
        }, {
          "code": "LV-BU",
          "represent": "Bauskas Aprinkis"
        }, {
          "code": "LV-CE",
          "represent": "Cēsu Aprinkis"
        }, {
          "code": "LV-DA",
          "represent": "Daugavpils Aprinkis"
        }, {
          "code": "LV-DO",
          "represent": "Dobeles Aprinkis"
        }, {
          "code": "LV-GU",
          "represent": "Gulbenes Aprinkis"
        }, {
          "code": "LV-JL",
          "represent": "Jelgavas Aprinkis"
        }, {
          "code": "LV-JK",
          "represent": "Jēkabpils Aprinkis"
        }, {
          "code": "LV-KR",
          "represent": "Krāslavas Aprinkis"
        }, {
          "code": "LV-KU",
          "represent": "Kuldīgas Aprinkis"
        }, {
          "code": "LV-LM",
          "represent": "Limbažu Aprinkis"
        }, {
          "code": "LV-LE",
          "represent": "Liepājas Aprinkis"
        }, {
          "code": "LV-LU",
          "represent": "Ludzas Aprinkis"
        }, {
          "code": "LV-MA",
          "represent": "Madonas Aprinkis"
        }, {
          "code": "LV-OG",
          "represent": "Ogres Aprinkis"
        }, {
          "code": "LV-PR",
          "represent": "Preilu Aprinkis"
        }, {
          "code": "LV-RE",
          "represent": "Rēzeknes Aprinkis"
        }, {
          "code": "LV-RI",
          "represent": "Rīgas Aprinkis"
        }, {
          "code": "LV-SA",
          "represent": "Saldus Aprinkis"
        }, {
          "code": "LV-TA",
          "represent": "Talsu Aprinkis"
        }, {
          "code": "LV-TU",
          "represent": "Tukuma Aprinkis"
        }, {
          "code": "LV-VK",
          "represent": "Valkas Aprinkis"
        }, {
          "code": "LV-VM",
          "represent": "Valmieras Aprinkis"
        }, {
          "code": "LV-VE",
          "represent": "Ventspils Aprinkis"
        }, {
          "code": "LV-DGV",
          "represent": "Daugavpils"
        }, {
          "code": "LV-JEL",
          "represent": "Jelgava"
        }, {
          "code": "LV-JUR",
          "represent": "Jūrmala"
        }, {
          "code": "LV-LPX",
          "represent": "Liepāja"
        }, {
          "code": "LV-REZ",
          "represent": "Rēzekne"
        }, {
          "code": "LV-RIX",
          "represent": "Rīga"
        }, {
          "code": "LV-VEN",
          "represent": "Ventspils"
        }]
      }, {
        "name": "Lebanon",
        "code": "LB",
        "divisions": [{
          "code": "LB-BA",
          "represent": "Beiroût (Bayrūt)"
        }, {
          "code": "LB-BI",
          "represent": "El Béqaa (Al Biqā')"
        }, {
          "code": "LB-JL",
          "represent": "Jabal Loubnâne (Jabal Lubnān)"
        }, {
          "code": "LB-AS",
          "represent": "Loubnâne ech Chemâli (Ash Shamāl)"
        }, {
          "code": "LB-JA",
          "represent": "Loubnâne ej Jnoûbi (Al Janūb)"
        }, {
          "code": "LB-NA",
          "represent": "Nabatîyé (An Nabaţīyah"
        }]
      }, {
        "name": "Lesotho",
        "code": "LS",
        "divisions": [{
          "code": "LS-D",
          "represent": "Berea"
        }, {
          "code": "LS-B",
          "represent": "Butha-Buthe"
        }, {
          "code": "LS-C",
          "represent": "Leribe"
        }, {
          "code": "LS-E",
          "represent": "Mafeteng"
        }, {
          "code": "LS-A",
          "represent": "Maseru"
        }, {
          "code": "LS-F",
          "represent": "Mohale's Hoek"
        }, {
          "code": "LS-J",
          "represent": "Mokhotlong"
        }, {
          "code": "LS-H",
          "represent": "Qacha's Nek"
        }, {
          "code": "LS-G",
          "represent": "Quthing"
        }, {
          "code": "LS-K",
          "represent": "Thaba-Tseka"
        }]
      }, {
        "name": "Liberia",
        "code": "LR",
        "divisions": [{
          "code": "LR-BM",
          "represent": "Bomi"
        }, {
          "code": "LR-BG",
          "represent": "Bong"
        }, {
          "code": "LR-GB",
          "represent": "Grand Bassa"
        }, {
          "code": "LR-CM",
          "represent": "Grand Cape Mount"
        }, {
          "code": "LR-GG",
          "represent": "Grand Gedeh"
        }, {
          "code": "LR-GK",
          "represent": "Grand Kru"
        }, {
          "code": "LR-LO",
          "represent": "Lofa"
        }, {
          "code": "LR-MG",
          "represent": "Margibi"
        }, {
          "code": "LR-MY",
          "represent": "Maryland"
        }, {
          "code": "LR-MO",
          "represent": "Montserrado"
        }, {
          "code": "LR-NI",
          "represent": "Nimba"
        }, {
          "code": "LR-RI",
          "represent": "Rivercess"
        }, {
          "code": "LR-SI",
          "represent": "Sinoe"
        }]
      }, {
        "name": "Libya",
        "code": "LY",
        "divisions": [{
          "code": "LY-BU",
          "represent": "Al Buţnān"
        }, {
          "code": "LY-JA",
          "represent": "Al Jabal al Akhḑar"
        }, {
          "code": "LY-JG",
          "represent": "Al Jabal al Gharbī"
        }, {
          "code": "LY-Ju",
          "represent": "Al Jufrah"
        }, {
          "code": "LY-WA",
          "represent": "Al Wāḩah"
        }, {
          "code": "LY-Wu",
          "represent": "Al Wusţá"
        }, {
          "code": "LY-ZA",
          "represent": "Az Zāwiyah"
        }, {
          "code": "LY-BA",
          "represent": "Banghāzī"
        }, {
          "code": "LY-FA",
          "represent": "Fazzān"
        }, {
          "code": "LY-MI",
          "represent": "Mişrātah"
        }, {
          "code": "LY-NA",
          "represent": "Naggaza"
        }, {
          "code": "LY-SF",
          "represent": "Sawfajjin"
        }, {
          "code": "LY-TB",
          "represent": "Ţarābulus"
        }]
      }, {
        "name": "Liechtenstein",
        "code": "LI",
        "divisions": [{
          "code": "LI-LI",
          "represent": "Liechtenstein"
        }]
      }, {
        "name": "Lithuania",
        "code": "LT",
        "divisions": [{
          "code": "LT-AL",
          "represent": "Alytaus Apskritis"
        }, {
          "code": "LT-KU",
          "represent": "Kauno Apskritis"
        }, {
          "code": "LT-KL",
          "represent": "Klaipėdos Apskritis"
        }, {
          "code": "LT-MR",
          "represent": "Marijampolės Apskritis"
        }, {
          "code": "LT-PN",
          "represent": "Panevėžio Apskritis"
        }, {
          "code": "LT-SA",
          "represent": "Šiauliu Apskritis"
        }, {
          "code": "LT-TA",
          "represent": "Tauragės Apskritis"
        }, {
          "code": "LT-TE",
          "represent": "Telšiu Apskritis"
        }, {
          "code": "LT-UT",
          "represent": "Utenos Apskritis"
        }, {
          "code": "LT-VL",
          "represent": "Vilniaus Apskritis"
        }]
      }, {
        "name": "Luxembourg",
        "code": "LU",
        "divisions": [{
          "code": "LU-D",
          "represent": "Diekirch"
        }, {
          "code": "LU-G",
          "represent": "Grevenmacher"
        }, {
          "code": "LU-L",
          "represent": "Luxembourg (Luxemburg)"
        }]
      }, {
        "name": "Macau",
        "code": "MO",
        "divisions": [{
          "code": "MO-MO",
          "represent": "Macau"
        }]
      }, {
        "name": "Macedonia",
        "code": "MK",
        "divisions": [{
          "code": "MK-MK",
          "represent": "Macedonia"
        }]
      }, {
        "name": "Madagascar",
        "code": "MG",
        "divisions": [{
          "code": "MG-T",
          "represent": "Antananarivo"
        }, {
          "code": "MG-D",
          "represent": "Antsiranana"
        }, {
          "code": "MG-F",
          "represent": "Fianarantsoa"
        }, {
          "code": "MG-M",
          "represent": "Mahajanga"
        }, {
          "code": "MG-A",
          "represent": "Toamasina"
        }, {
          "code": "MG-U",
          "represent": "Toliara"
        }]
      }, {
        "name": "Malawi",
        "code": "MW",
        "divisions": [{
          "code": "MW-C",
          "represent": "Central"
        }, {
          "code": "MW-DE",
          "represent": "Dedza"
        }, {
          "code": "MW-DO",
          "represent": "Dowa"
        }, {
          "code": "MW-KS",
          "represent": "Kasungu"
        }, {
          "code": "MW-LI",
          "represent": "Lilongwe"
        }, {
          "code": "MW-MC",
          "represent": "Mchinji"
        }, {
          "code": "MW-NK",
          "represent": "Nkhotakota"
        }, {
          "code": "MW-NU",
          "represent": "Ntcheu"
        }, {
          "code": "MW-NI",
          "represent": "Ntchisi"
        }, {
          "code": "MW-SA",
          "represent": "Salima"
        }, {
          "code": "MW-N",
          "represent": "Northern"
        }, {
          "code": "MW-CT",
          "represent": "Chitipa"
        }, {
          "code": "MW-KR",
          "represent": "Karonga"
        }, {
          "code": "MW-MZ",
          "represent": "Mzimba"
        }, {
          "code": "MW-NB",
          "represent": "Nkhata Bay"
        }, {
          "code": "MW-RU",
          "represent": "Rumphi"
        }, {
          "code": "MW-S",
          "represent": "Southern"
        }, {
          "code": "MW-BL",
          "represent": "Blantyre"
        }, {
          "code": "MW-CK",
          "represent": "Chikwawa"
        }, {
          "code": "MW-CR",
          "represent": "Chiradzulu"
        }, {
          "code": "MW-MH",
          "represent": "Machinga"
        }, {
          "code": "MW-MG",
          "represent": "Mangochi"
        }, {
          "code": "MW-MU",
          "represent": "Mulanje"
        }, {
          "code": "MW-MW",
          "represent": "Mwanza"
        }, {
          "code": "MW-NS",
          "represent": "Nsanje"
        }, {
          "code": "MW-TH",
          "represent": "Thyolo"
        }, {
          "code": "MW-ZO",
          "represent": "Zomba"
        }]
      }, {
        "name": "Malaysia",
        "code": "MY",
        "divisions": [{
          "code": "MY-W",
          "represent": "Wilayah Persekutuan Kuala Lumpur"
        }, {
          "code": "MY-L",
          "represent": "Wilayah Persekutuan Labuan"
        }, {
          "code": "MY-J",
          "represent": "Johor"
        }, {
          "code": "MY-K",
          "represent": "Kedah"
        }, {
          "code": "MY-D",
          "represent": "Kelantan"
        }, {
          "code": "MY-M",
          "represent": "Melaka"
        }, {
          "code": "MY-N",
          "represent": "Negeri Sembilan"
        }, {
          "code": "MY-C",
          "represent": "Pahang"
        }, {
          "code": "MY-A",
          "represent": "Perak"
        }, {
          "code": "MY-R",
          "represent": "Perlis"
        }, {
          "code": "MY-P",
          "represent": "Pulau Pinang"
        }, {
          "code": "MY-SA",
          "represent": "Sabah"
        }, {
          "code": "MY-SK",
          "represent": "Sarawak"
        }, {
          "code": "MY-B",
          "represent": "Selangor"
        }, {
          "code": "MY-T",
          "represent": "Terengganu"
        }]
      }, {
        "name": "Maldives",
        "code": "MV",
        "divisions": [{
          "code": "MV-MLE",
          "represent": "Male"
        }, {
          "code": "MV-02",
          "represent": "Alif"
        }, {
          "code": "MV-20",
          "represent": "Baa"
        }, {
          "code": "MV-17",
          "represent": "Dhaalu"
        }, {
          "code": "MV-14",
          "represent": "Faafu"
        }, {
          "code": "MV-27",
          "represent": "Gaaf Alif"
        }, {
          "code": "MV-28",
          "represent": "Gaafu Dhaalu"
        }, {
          "code": "MV-29",
          "represent": "Gnaviyani"
        }, {
          "code": "MV-07",
          "represent": "Haa Alif"
        }, {
          "code": "MV-23",
          "represent": "Haa Dhaalu"
        }, {
          "code": "MV-26",
          "represent": "Kaafu"
        }, {
          "code": "MV-05",
          "represent": "Laamu"
        }, {
          "code": "MV-03",
          "represent": "Lhaviyani"
        }, {
          "code": "MV-12",
          "represent": "Meemu"
        }, {
          "code": "MV-25",
          "represent": "Noonu"
        }, {
          "code": "MV-13",
          "represent": "Raa"
        }, {
          "code": "MV-01",
          "represent": "Seenu"
        }, {
          "code": "MV-24",
          "represent": "Shaviyani"
        }, {
          "code": "MV-08",
          "represent": "Thaa"
        }, {
          "code": "MV-04",
          "represent": "Vaavu"
        }]
      }, {
        "name": "Mali",
        "code": "ML",
        "divisions": [{
          "code": "ML-BKO",
          "represent": "Bamako"
        }, {
          "code": "ML-7",
          "represent": "Gao"
        }, {
          "code": "ML-1",
          "represent": "Kayes"
        }, {
          "code": "ML-8",
          "represent": "Kidal"
        }, {
          "code": "ML-2",
          "represent": "Koulikoro"
        }, {
          "code": "ML-5",
          "represent": "Mopti"
        }, {
          "code": "ML-4",
          "represent": "Ségou"
        }, {
          "code": "ML-3",
          "represent": "Sikasso"
        }, {
          "code": "ML-6",
          "represent": "Tombouctou"
        }]
      }, {
        "name": "Malta",
        "code": "MT",
        "divisions": [{
          "code": "MT-MT",
          "represent": "Malta"
        }]
      }, {
        "name": "Marshall Islands",
        "code": "MH",
        "divisions": [{
          "code": "MH-L",
          "represent": "Ralik chain"
        }, {
          "code": "MH-ALL",
          "represent": "Ailinglapalap"
        }, {
          "code": "MH-EBO",
          "represent": "Ebon"
        }, {
          "code": "MH-ENI",
          "represent": "Eniwetok"
        }, {
          "code": "MH-JAL",
          "represent": "Jaluit"
        }, {
          "code": "MH-KIL",
          "represent": "Kili"
        }, {
          "code": "MH-KWA",
          "represent": "Kwajalein"
        }, {
          "code": "MH-LAE",
          "represent": "Lae"
        }, {
          "code": "MH-LIB",
          "represent": "Lib"
        }, {
          "code": "MH-NMK",
          "represent": "Namorik"
        }, {
          "code": "MH-NMU",
          "represent": "Namu"
        }, {
          "code": "MH-RON",
          "represent": "Rongelap"
        }, {
          "code": "MH-UJA",
          "represent": "Ujae"
        }, {
          "code": "MH-UJL",
          "represent": "Ujelang"
        }, {
          "code": "MH-WTH",
          "represent": "Wotho"
        }, {
          "code": "MH-T",
          "represent": "Ratak chain"
        }, {
          "code": "MH-ALK",
          "represent": "Ailuk"
        }, {
          "code": "MH-ARN",
          "represent": "Arno"
        }, {
          "code": "MH-AUR",
          "represent": "Aur"
        }, {
          "code": "MH-LIK",
          "represent": "Likiep"
        }, {
          "code": "MH-MAJ",
          "represent": "Majuro"
        }, {
          "code": "MH-MAL",
          "represent": "Maloelap"
        }, {
          "code": "MH-MEJ",
          "represent": "Mejit"
        }, {
          "code": "MH-MIL",
          "represent": "Mili"
        }, {
          "code": "MH-UTI",
          "represent": "Utirik"
        }, {
          "code": "MH-WTJ",
          "represent": "Wotje"
        }]
      }, {
        "name": "Martinique",
        "code": "MQ",
        "divisions": [{
          "code": "MQ-MQ",
          "represent": "Martinique"
        }]
      }, {
        "name": "Mauritania",
        "code": "MR",
        "divisions": [{
          "code": "MR-NKC",
          "represent": "Nouakchott"
        }, {
          "code": "MR-07",
          "represent": "Adrar"
        }, {
          "code": "MR-03",
          "represent": "Assaba"
        }, {
          "code": "MR-05",
          "represent": "Brakna"
        }, {
          "code": "MR-08",
          "represent": "Dakhlet Nouādhibou"
        }, {
          "code": "MR-04",
          "represent": "Gorgol"
        }, {
          "code": "MR-10",
          "represent": "Guidimaka"
        }, {
          "code": "MR-01",
          "represent": "Hodh ech Chargui"
        }, {
          "code": "MR-02",
          "represent": "Hodh el Gharbi"
        }, {
          "code": "MR-12",
          "represent": "Inchiri"
        }, {
          "code": "MR-09",
          "represent": "Tagant"
        }, {
          "code": "MR-11",
          "represent": "Tiris Zemmour"
        }, {
          "code": "MR-06",
          "represent": "Trarza"
        }]
      }, {
        "name": "Mauritius",
        "code": "MU",
        "divisions": [{
          "code": "MU-BR",
          "represent": "Beau Bassin-Rose Hill"
        }, {
          "code": "MU-CU",
          "represent": "Curepipe"
        }, {
          "code": "MU-PL",
          "represent": "Port Louis"
        }, {
          "code": "MU-QB",
          "represent": "Quatre Bornes"
        }, {
          "code": "MU-VP",
          "represent": "Vacoas-Phoenix"
        }, {
          "code": "MU-BL",
          "represent": "Black River"
        }, {
          "code": "MU-FL",
          "represent": "Flacq"
        }, {
          "code": "MU-GP",
          "represent": "Grand Port"
        }, {
          "code": "MU-MO",
          "represent": "Moka"
        }, {
          "code": "MU-PA",
          "represent": "Pamplemousses"
        }, {
          "code": "MU-PW",
          "represent": "Plaines Wilhems"
        }, {
          "code": "MU-RR",
          "represent": "Rivière du Rempart"
        }, {
          "code": "MU-SA",
          "represent": "Savanne"
        }, {
          "code": "MU-AG",
          "represent": "Agalega Islands"
        }, {
          "code": "MU-CC",
          "represent": "Cargados Carajos Shoals [Saint Brandon Islands]"
        }, {
          "code": "MU-RO",
          "represent": "Rodrigues Island"
        }]
      }, {
        "name": "Mayotte",
        "code": "YT",
        "divisions": [{
          "code": "YT-YT",
          "represent": "Mayotte"
        }]
      }, {
        "name": "Mexico",
        "code": "MX",
        "divisions": [{
          "code": "MX-DIF",
          "represent": "Distrito Federal"
        }, {
          "code": "MX-AGU",
          "represent": "Aguascalientes"
        }, {
          "code": "MX-BCN",
          "represent": "Baja California"
        }, {
          "code": "MX-BCS",
          "represent": "Baja California Sur"
        }, {
          "code": "MX-CAM",
          "represent": "Campeche"
        }, {
          "code": "MX-COA",
          "represent": "Coahuila"
        }, {
          "code": "MX-COL",
          "represent": "Colima"
        }, {
          "code": "MX-CHP",
          "represent": "Chiapas"
        }, {
          "code": "MX-CHH",
          "represent": "Chihuahua"
        }, {
          "code": "MX-DUR",
          "represent": "Durango"
        }, {
          "code": "MX-GRO",
          "represent": "Guerrero"
        }, {
          "code": "MX-GUA",
          "represent": "Guanajuato"
        }, {
          "code": "MX-HID",
          "represent": "Hidalgo"
        }, {
          "code": "MX-JAL",
          "represent": "Jalisco"
        }, {
          "code": "MX-MEX",
          "represent": "México"
        }, {
          "code": "MX-MIC",
          "represent": "Michoacán"
        }, {
          "code": "MX-MOR",
          "represent": "Morelos"
        }, {
          "code": "MX-NAY",
          "represent": "Nayarit"
        }, {
          "code": "MX-NLE",
          "represent": "Nuevo León"
        }, {
          "code": "MX-OAX",
          "represent": "Oaxaca"
        }, {
          "code": "MX-PUE",
          "represent": "Puebla"
        }, {
          "code": "MX-QUE",
          "represent": "Queretaro"
        }, {
          "code": "MX-ROO",
          "represent": "Quintana Roo"
        }, {
          "code": "MX-SLP",
          "represent": "San Luis Potosí"
        }, {
          "code": "MX-SIN",
          "represent": "Sinaloa"
        }, {
          "code": "MX-SON",
          "represent": "Sonora"
        }, {
          "code": "MX-TAB",
          "represent": "Tabasco"
        }, {
          "code": "MX-TAM",
          "represent": "Tamaulipas"
        }, {
          "code": "MX-TLA",
          "represent": "Tlaxcala"
        }, {
          "code": "MX-VER",
          "represent": "Veracruz"
        }, {
          "code": "MX-YUC",
          "represent": "Yucatán"
        }, {
          "code": "MX-ZAC",
          "represent": "Zacatecas"
        }]
      }, {
        "name": "Micronesia",
        "code": "FM",
        "divisions": [{
          "code": "FM-TRK",
          "represent": "chuuk"
        }, {
          "code": "FM-KSA",
          "represent": "Kosrae"
        }, {
          "code": "FM-PNI",
          "represent": "Pohnpei"
        }, {
          "code": "FM-YAP",
          "represent": "Yap"
        }]
      }, {
        "name": "Moldova",
        "code": "MD",
        "divisions": [{
          "code": "MD-BAL",
          "represent": "Bălţi"
        }, {
          "code": "MD-CAH",
          "represent": "Cahul"
        }, {
          "code": "MD-CHI",
          "represent": "Chişinău"
        }, {
          "code": "MD-DUB",
          "represent": "Dubăsari"
        }, {
          "code": "MD-ORH",
          "represent": "Orhei"
        }, {
          "code": "MD-RIB",
          "represent": "Rîbniţa"
        }, {
          "code": "MD-SOC",
          "represent": "Soroca"
        }, {
          "code": "MD-TIG",
          "represent": "Tighina"
        }, {
          "code": "MD-TIR",
          "represent": "Tiraspol"
        }, {
          "code": "MD-UNG",
          "represent": "Ungheni"
        }, {
          "code": "MD-ANE",
          "represent": "Anenii Noi"
        }, {
          "code": "MD-BAS",
          "represent": "Basarabeasca"
        }, {
          "code": "MD-BRI",
          "represent": "Brinceni"
        }, {
          "code": "MD-CHL",
          "represent": "Cahul"
        }, {
          "code": "MD-CAM",
          "represent": "Camenca"
        }, {
          "code": "MD-CAN",
          "represent": "Cantemir"
        }, {
          "code": "MD-CAI",
          "represent": "Căinari"
        }, {
          "code": "MD-CAL",
          "represent": "Călăraşi"
        }, {
          "code": "MD-CAS",
          "represent": "Căuşeni"
        }, {
          "code": "MD-CIA",
          "represent": "Ciadîr-Lunga"
        }, {
          "code": "MD-CIM",
          "represent": "Cimişlia"
        }, {
          "code": "MD-COM",
          "represent": "Comrat"
        }, {
          "code": "MD-CRI",
          "represent": "Criuleni"
        }, {
          "code": "MD-DON",
          "represent": "Donduşeni"
        }, {
          "code": "MD-DRO",
          "represent": "Drochia"
        }, {
          "code": "MD-DBI",
          "represent": "Dubăsari"
        }, {
          "code": "MD-EDI",
          "represent": "Edineţ"
        }, {
          "code": "MD-FAL",
          "represent": "Făleşti"
        }, {
          "code": "MD-FLO",
          "represent": "Floreşti"
        }, {
          "code": "MD-GLO",
          "represent": "Glodeni"
        }, {
          "code": "MD-GRI",
          "represent": "Grigoriopol"
        }, {
          "code": "MD-HIN",
          "represent": "Hînceşti"
        }, {
          "code": "MD-IAL",
          "represent": "Ialoveni"
        }, {
          "code": "MD-LEO",
          "represent": "Leova"
        }, {
          "code": "MD-NIS",
          "represent": "Nisporeni"
        }, {
          "code": "MD-OCN",
          "represent": "Ocniţa"
        }, {
          "code": "MD-OHI",
          "represent": "Orhei"
        }, {
          "code": "MD-REZ",
          "represent": "Rezina"
        }, {
          "code": "MD-RIT",
          "represent": "Rîbniţa"
        }, {
          "code": "MD-RIS",
          "represent": "Rîşcani"
        }, {
          "code": "MD-SIN",
          "represent": "Sîngerei"
        }, {
          "code": "MD-SLO",
          "represent": "Slobozia"
        }, {
          "code": "MD-SOA",
          "represent": "Soroca"
        }, {
          "code": "MD-STR",
          "represent": "Străşeni"
        }, {
          "code": "MD-SOL",
          "represent": "Şoldăneşti"
        }, {
          "code": "MD-STE",
          "represent": "Ştefan Vodă"
        }, {
          "code": "MD-TAR",
          "represent": "Taraclia"
        }, {
          "code": "MD-TEL",
          "represent": "Teleneşti"
        }, {
          "code": "MD-UGI",
          "represent": "Ungheni"
        }, {
          "code": "MD-VUL",
          "represent": "Vulcăneşti"
        }]
      }, {
        "name": "Monaco",
        "code": "MC",
        "divisions": [{
          "code": "MC-MC",
          "represent": "Monaco"
        }]
      }, {
        "name": "Mongolia",
        "code": "MN",
        "divisions": [{
          "code": "MN-1",
          "represent": "Ulaanbaatar"
        }, {
          "code": "MN-073",
          "represent": "Arhangay"
        }, {
          "code": "MN-069",
          "represent": "Bayanhongor"
        }, {
          "code": "MN-071",
          "represent": "Bayan-Ölgiy"
        }, {
          "code": "MN-067",
          "represent": "Bulgan"
        }, {
          "code": "MN-037",
          "represent": "Darhan uul"
        }, {
          "code": "MN-061",
          "represent": "Dornod"
        }, {
          "code": "MN-063",
          "represent": "Dornogovĭ"
        }, {
          "code": "MN-059",
          "represent": "Dundgovĭ"
        }, {
          "code": "MN-057",
          "represent": "Dzavhan"
        }, {
          "code": "MN-065",
          "represent": "Govĭ-Altay"
        }, {
          "code": "MN-064",
          "represent": "Govĭ-Sümber"
        }, {
          "code": "MN-039",
          "represent": "Hentiy"
        }, {
          "code": "MN-043",
          "represent": "Hovd"
        }, {
          "code": "MN-041",
          "represent": "Hövsgöl"
        }, {
          "code": "MN-053",
          "represent": "Ömnögovĭ"
        }, {
          "code": "MN-035",
          "represent": "Orhon"
        }, {
          "code": "MN-055",
          "represent": "Övörhangay"
        }, {
          "code": "MN-049",
          "represent": "Selenge"
        }, {
          "code": "MN-051",
          "represent": "Sühbaatar"
        }, {
          "code": "MN-047",
          "represent": "Töv"
        }, {
          "code": "MN-046",
          "represent": "Uvs"
        }]
      }, {
        "name": "Montserrat",
        "code": "MS",
        "divisions": [{
          "code": "MS-MS",
          "represent": "Montserrat"
        }]
      }, {
        "name": "Morocco",
        "code": "MA",
        "divisions": [{
          "code": "MA-CE",
          "represent": "Centre"
        }, {
          "code": "MA-AZI",
          "represent": "Azilal"
        }, {
          "code": "MA-BEM",
          "represent": "Beni Mellal"
        }, {
          "code": "MA-BES",
          "represent": "Ben Slimane"
        }, {
          "code": "MA-BOM",
          "represent": "Boulemane"
        }, {
          "code": "MA-CAS",
          "represent": "Casablanca [Dar el Beïda]"
        }, {
          "code": "MA-JDI",
          "represent": "El Jadida"
        }, {
          "code": "MA-KHO",
          "represent": "Khouribga"
        }, {
          "code": "MA-SET",
          "represent": "Settat"
        }, {
          "code": "MA-CN",
          "represent": "Centre-Nord"
        }, {
          "code": "MA-HOC",
          "represent": "Al Hoceïma"
        }, {
          "code": "MA-FES",
          "represent": "Fès"
        }, {
          "code": "MA-SEF",
          "represent": "Sefrou"
        }, {
          "code": "MA-TAO",
          "represent": "Taounate"
        }, {
          "code": "MA-TAZ",
          "represent": "Taza"
        }, {
          "code": "MA-CS",
          "represent": "Centre-Sud"
        }, {
          "code": "MA-HAJ",
          "represent": "El Hajeb"
        }, {
          "code": "MA-ERR",
          "represent": "Errachidia"
        }, {
          "code": "MA-IFR",
          "represent": "Ifrane"
        }, {
          "code": "MA-KHN",
          "represent": "Khenifra"
        }, {
          "code": "MA-MEK",
          "represent": "Meknès"
        }, {
          "code": "MA-ES",
          "represent": "Est"
        }, {
          "code": "MA-BER",
          "represent": "Berkane"
        }, {
          "code": "MA-FIG",
          "represent": "Figuig"
        }, {
          "code": "MA-IRA",
          "represent": "Jrada"
        }, {
          "code": "MA-NAD",
          "represent": "Nador"
        }, {
          "code": "MA-OUJ",
          "represent": "Oujda"
        }, {
          "code": "MA-NO",
          "represent": "Nord-Ouest"
        }, {
          "code": "MA-CHE",
          "represent": "Chefchaouene"
        }, {
          "code": "MA-KEN",
          "represent": "Kénitra"
        }, {
          "code": "MA-KHE",
          "represent": "Khemisset"
        }, {
          "code": "MA-LAR",
          "represent": "Larache"
        }, {
          "code": "MA-RBA",
          "represent": "Rabat-Salé"
        }, {
          "code": "MA-SIK",
          "represent": "Sidi Kacem"
        }, {
          "code": "MA-TNG",
          "represent": "Tanger"
        }, {
          "code": "MA-TET",
          "represent": "Tétouan"
        }, {
          "code": "MA-SU",
          "represent": "Sud"
        }, {
          "code": "MA-AGD",
          "represent": "Agadir"
        }, {
          "code": "MA-BAH",
          "represent": "Aït Baha"
        }, {
          "code": "MA-MEL",
          "represent": "Aït Melloul"
        }, {
          "code": "MA-ASZ",
          "represent": "Assa-Zag"
        }, {
          "code": "MA-BOD",
          "represent": "Boujdour (EH)"
        }, {
          "code": "MA-ESM",
          "represent": "Es Semara (EH)"
        }, {
          "code": "MA-GUE",
          "represent": "Guelmim"
        }, {
          "code": "MA-LAA",
          "represent": "Laayoune (EH)"
        }, {
          "code": "MA-OUA",
          "represent": "Ouarzazate"
        }, {
          "code": "MA-OUD",
          "represent": "Oued ed Dahab (EH)"
        }, {
          "code": "MA-TNT",
          "represent": "Tan-Tan"
        }, {
          "code": "MA-TAR",
          "represent": "Taroudannt"
        }, {
          "code": "MA-TAT",
          "represent": "Tata"
        }, {
          "code": "MA-TIZ",
          "represent": "Tiznit"
        }, {
          "code": "MA-TS",
          "represent": "Tensift"
        }, {
          "code": "MA-HAO",
          "represent": "Al Haouz"
        }, {
          "code": "MA-CHI",
          "represent": "Chichaoua"
        }, {
          "code": "MA-ESI",
          "represent": "Essaouira"
        }, {
          "code": "MA-KES",
          "represent": "Kelaat Sraghna"
        }, {
          "code": "MA-MAR",
          "represent": "Marrakech"
        }, {
          "code": "MA-SAF",
          "represent": "Safi"
        }]
      }, {
        "name": "Mozambique",
        "code": "MZ",
        "divisions": [{
          "code": "MZ-MPM",
          "represent": "Maputo"
        }, {
          "code": "MZ-P",
          "represent": "Cabo Delgado"
        }, {
          "code": "MZ-G",
          "represent": "Gaza"
        }, {
          "code": "MZ-I",
          "represent": "Inhambane"
        }, {
          "code": "MZ-B",
          "represent": "Manica"
        }, {
          "code": "MZ-L",
          "represent": "Maputo"
        }, {
          "code": "MZ-N",
          "represent": "Nampula"
        }, {
          "code": "MZ-A",
          "represent": "Niassa"
        }, {
          "code": "MZ-S",
          "represent": "Sofala"
        }, {
          "code": "MZ-T",
          "represent": "Tete"
        }, {
          "code": "MZ-Q",
          "represent": "Zambézia"
        }]
      }, {
        "name": "Myanmar",
        "code": "MM",
        "divisions": [{
          "code": "MM-07",
          "represent": "Ayeyarwady"
        }, {
          "code": "MM-02",
          "represent": "Bago"
        }, {
          "code": "MM-03",
          "represent": "Magway"
        }, {
          "code": "MM-04",
          "represent": "Mandalay"
        }, {
          "code": "MM-01",
          "represent": "Sagaing"
        }, {
          "code": "MM-05",
          "represent": "Tanintharyi"
        }, {
          "code": "MM-06",
          "represent": "Yangon"
        }, {
          "code": "MM-14",
          "represent": "Chin"
        }, {
          "code": "MM-11",
          "represent": "Kachin"
        }, {
          "code": "MM-12",
          "represent": "Kayah"
        }, {
          "code": "MM-13",
          "represent": "Kayin"
        }, {
          "code": "MM-15",
          "represent": "Mon"
        }, {
          "code": "MM-16",
          "represent": "Rakhine"
        }, {
          "code": "MM-17",
          "represent": "Shan"
        }]
      }, {
        "name": "Namibia",
        "code": "NA",
        "divisions": [{
          "code": "NA-CA",
          "represent": "Caprivi"
        }, {
          "code": "NA-ER",
          "represent": "Erongo"
        }, {
          "code": "NA-HA",
          "represent": "Hardap"
        }, {
          "code": "NA-KA",
          "represent": "Karas"
        }, {
          "code": "NA-KH",
          "represent": "Khomas"
        }, {
          "code": "NA-KU",
          "represent": "Kunene"
        }, {
          "code": "NA-OW",
          "represent": "Ohangwena"
        }, {
          "code": "NA-OK",
          "represent": "Okavango"
        }, {
          "code": "NA-OH",
          "represent": "Omaheke"
        }, {
          "code": "NA-OS",
          "represent": "Omusati"
        }, {
          "code": "NA-ON",
          "represent": "Oshana"
        }, {
          "code": "NA-OT",
          "represent": "Oshikoto"
        }, {
          "code": "NA-OD",
          "represent": "Otjozondjupa"
        }]
      }, {
        "name": "Nauru",
        "code": "NR",
        "divisions": [{
          "code": "NR-NR",
          "represent": "Nauru"
        }]
      }, {
        "name": "Nepal",
        "code": "NP",
        "divisions": [{
          "code": "NP-1",
          "represent": "Madhyamanchal"
        }, {
          "code": "NP-BA",
          "represent": "Bagmati"
        }, {
          "code": "NP-JA",
          "represent": "Janakpur"
        }, {
          "code": "NP-NA",
          "represent": "Narayani"
        }, {
          "code": "NP-2",
          "represent": "Madhya Pashchimanchal"
        }, {
          "code": "NP-BH",
          "represent": "Bheri"
        }, {
          "code": "NP-KA",
          "represent": "Karnali"
        }, {
          "code": "NP-RA",
          "represent": "Rapti"
        }, {
          "code": "NP-3",
          "represent": "Pashchimanchal"
        }, {
          "code": "NP-DH",
          "represent": "Dhawalagiri"
        }, {
          "code": "NP-GA",
          "represent": "Gandaki"
        }, {
          "code": "NP-LU",
          "represent": "Lumbini"
        }, {
          "code": "NP-4",
          "represent": "Purwanchal"
        }, {
          "code": "NP-KO",
          "represent": "Kosi [Koshi]"
        }, {
          "code": "NP-ME",
          "represent": "Mechi"
        }, {
          "code": "NP-SA",
          "represent": "Sagarmatha"
        }, {
          "code": "NP-5",
          "represent": "Sudur Pashchimanchal"
        }, {
          "code": "NP-MA",
          "represent": "Mahakali"
        }, {
          "code": "NP-SE",
          "represent": "Seti"
        }]
      }, {
        "name": "Netherlands",
        "code": "NL",
        "divisions": [{
          "code": "NL-DR",
          "represent": "Drenthe"
        }, {
          "code": "NL-FL",
          "represent": "Flevoland"
        }, {
          "code": "NL-FR",
          "represent": "Friesland"
        }, {
          "code": "NL-GE",
          "represent": "Gelderland"
        }, {
          "code": "NL-GR",
          "represent": "Groningen"
        }, {
          "code": "NL-LI",
          "represent": "Limburg"
        }, {
          "code": "NL-NB",
          "represent": "Noord-Brabant"
        }, {
          "code": "NL-NH",
          "represent": "Noord-Holland"
        }, {
          "code": "NL-OV",
          "represent": "Overijssel"
        }, {
          "code": "NL-UT",
          "represent": "Utrecht"
        }, {
          "code": "NL-ZE",
          "represent": "Zeeland"
        }, {
          "code": "NL-ZH",
          "represent": "Zuid-Holland"
        }]
      }, {
        "name": "Netherlands Antilles",
        "code": "AN",
        "divisions": [{
          "code": "AN-AN",
          "represent": "Netherlands Antilles"
        }]
      }, {
        "name": "New Caledonia",
        "code": "NC",
        "divisions": [{
          "code": "NC-NC",
          "represent": "New Caledonia"
        }]
      }, {
        "name": "New Zealand",
        "code": "NZ",
        "divisions": [{
          "code": "NZ-N",
          "represent": "North Island"
        }, {
          "code": "NZ-AUK",
          "represent": "Auckland"
        }, {
          "code": "NZ-BOP",
          "represent": "Bay of Plenty"
        }, {
          "code": "NZ-GIS",
          "represent": "Gisborne"
        }, {
          "code": "NZ-HKB",
          "represent": "Hawkes's Bay"
        }, {
          "code": "NZ-MWT",
          "represent": "Manawatu-Wanganui"
        }, {
          "code": "NZ-NTL",
          "represent": "Northland"
        }, {
          "code": "NZ-TKI",
          "represent": "Taranaki"
        }, {
          "code": "NZ-WKO",
          "represent": "Waikato"
        }, {
          "code": "NZ-WGN",
          "represent": "Wellington"
        }, {
          "code": "NZ-S",
          "represent": "South Island"
        }, {
          "code": "NZ-CAN",
          "represent": "Canterbury"
        }, {
          "code": "NZ-MBH",
          "represent": "Marlborough"
        }, {
          "code": "NZ-NSN",
          "represent": "Nelson"
        }, {
          "code": "NZ-OTA",
          "represent": "Otago"
        }, {
          "code": "NZ-STL",
          "represent": "Southland"
        }, {
          "code": "NZ-TAS",
          "represent": "Tasman"
        }, {
          "code": "NZ-WTC",
          "represent": "West Coast"
        }]
      }, {
        "name": "Nicaragua",
        "code": "NI",
        "divisions": [{
          "code": "NI-BO",
          "represent": "Boaco"
        }, {
          "code": "NI-CA",
          "represent": "Carazo"
        }, {
          "code": "NI-CI",
          "represent": "Chinandega"
        }, {
          "code": "NI-CO",
          "represent": "Chontales"
        }, {
          "code": "NI-ES",
          "represent": "Estelí"
        }, {
          "code": "NI-GR",
          "represent": "Granada"
        }, {
          "code": "NI-JI",
          "represent": "Jinotega"
        }, {
          "code": "NI-LE",
          "represent": "León"
        }, {
          "code": "NI-MD",
          "represent": "Madriz"
        }, {
          "code": "NI-MN",
          "represent": "Manaqua"
        }, {
          "code": "NI-MS",
          "represent": "Masaya"
        }, {
          "code": "NI-MT",
          "represent": "Matagalpa"
        }, {
          "code": "NI-NS",
          "represent": "Nueva Segovia"
        }, {
          "code": "NI-SJ",
          "represent": "Río San Juan"
        }, {
          "code": "NI-RI",
          "represent": "Rivas"
        }, {
          "code": "NI-ZE",
          "represent": "Zelaya"
        }]
      }, {
        "name": "Niger",
        "code": "NE",
        "divisions": [{
          "code": "NE-8",
          "represent": "Niamey"
        }, {
          "code": "NE-1",
          "represent": "Agadez"
        }, {
          "code": "NE-2",
          "represent": "Diffa"
        }, {
          "code": "NE-3",
          "represent": "Dosso"
        }, {
          "code": "NE-4",
          "represent": "Maradi"
        }, {
          "code": "NE-5",
          "represent": "Tahoua"
        }, {
          "code": "NE-6",
          "represent": "Tillaberi"
        }, {
          "code": "NE-7",
          "represent": "Zinder"
        }]
      }, {
        "name": "Nigeria",
        "code": "NG",
        "divisions": [{
          "code": "NG-FC",
          "represent": "Abuja Capital Territory"
        }, {
          "code": "NG-AB",
          "represent": "Abia"
        }, {
          "code": "NG-AD",
          "represent": "Adamawa"
        }, {
          "code": "NG-AK",
          "represent": "Akwa Ibom"
        }, {
          "code": "NG-AN",
          "represent": "Anambra"
        }, {
          "code": "NG-BA",
          "represent": "Bauchi"
        }, {
          "code": "NG-BE",
          "represent": "Benue"
        }, {
          "code": "NG-BO",
          "represent": "Borno"
        }, {
          "code": "NG-CR",
          "represent": "Cross River"
        }, {
          "code": "NG-DE",
          "represent": "Delta"
        }, {
          "code": "NG-ED",
          "represent": "Edo"
        }, {
          "code": "NG-EN",
          "represent": "Enugu"
        }, {
          "code": "NG-IM",
          "represent": "Imo"
        }, {
          "code": "NG-JI",
          "represent": "Jigawa"
        }, {
          "code": "NG-KD",
          "represent": "Kaduna"
        }, {
          "code": "NG-KN",
          "represent": "Kano"
        }, {
          "code": "NG-KT",
          "represent": "Katsina"
        }, {
          "code": "NG-KE",
          "represent": "Kebbi"
        }, {
          "code": "NG-KO",
          "represent": "Kogi"
        }, {
          "code": "NG-KW",
          "represent": "Kwara"
        }, {
          "code": "NG-LA",
          "represent": "Lagos"
        }, {
          "code": "NG-NI",
          "represent": "Niger"
        }, {
          "code": "NG-OG",
          "represent": "Ogun"
        }, {
          "code": "NG-ON",
          "represent": "Ondo"
        }, {
          "code": "NG-OS",
          "represent": "Osun"
        }, {
          "code": "NG-OY",
          "represent": "Oyo"
        }, {
          "code": "NG-PL",
          "represent": "Plateau"
        }, {
          "code": "NG-RI",
          "represent": "Rivers"
        }, {
          "code": "NG-SO",
          "represent": "Sokoto"
        }, {
          "code": "NG-TA",
          "represent": "Taraba"
        }, {
          "code": "NG-YO",
          "represent": "Yobe"
        }]
      }, {
        "name": "Niue",
        "code": "NU",
        "divisions": [{
          "code": "NU-NU",
          "represent": "Niue"
        }]
      }, {
        "name": "Norfolk Island",
        "code": "NF",
        "divisions": [{
          "code": "NF-NF",
          "represent": "Norfolk Island"
        }]
      }, {
        "name": "Northern Mariana Islands",
        "code": "MP",
        "divisions": [{
          "code": "MP-MP",
          "represent": "Northern Mariana Islands"
        }]
      }, {
        "name": "Norway",
        "code": "NO",
        "divisions": [{
          "code": "NO-02",
          "represent": "Akershus"
        }, {
          "code": "NO-09",
          "represent": "Aust-Agder"
        }, {
          "code": "NO-06",
          "represent": "Buskerud"
        }, {
          "code": "NO-20",
          "represent": "Finnmark"
        }, {
          "code": "NO-04",
          "represent": "Hedmark"
        }, {
          "code": "NO-12",
          "represent": "Hordaland"
        }, {
          "code": "NO-15",
          "represent": "Møre og Romsdal"
        }, {
          "code": "NO-18",
          "represent": "Nordland"
        }, {
          "code": "NO-17",
          "represent": "Nord-Trøndelag"
        }, {
          "code": "NO-05",
          "represent": "Oppland"
        }, {
          "code": "NO-03",
          "represent": "Oslo"
        }, {
          "code": "NO-11",
          "represent": "Rogaland"
        }, {
          "code": "NO-14",
          "represent": "Sogn og Fjordane"
        }, {
          "code": "NO-16",
          "represent": "Sør-Trøndelag"
        }, {
          "code": "NO-08",
          "represent": "Telemark"
        }, {
          "code": "NO-19",
          "represent": "Troms"
        }, {
          "code": "NO-10",
          "represent": "Vest-Agder"
        }, {
          "code": "NO-07",
          "represent": "Vestfold"
        }, {
          "code": "NO-01",
          "represent": "Østfold"
        }, {
          "code": "NO-22",
          "represent": "Jan Mayen (Arctic Region)"
        }, {
          "code": "NO-21",
          "represent": "Svalbard (Arctic Region)"
        }]
      }, {
        "name": "Oman",
        "code": "OM",
        "divisions": [{
          "code": "OM-DA",
          "represent": "Ad Dākhilīyah"
        }, {
          "code": "OM-BA",
          "represent": "Al Bāţinah"
        }, {
          "code": "OM-JA",
          "represent": "Al Janūbīyah [Zufār]"
        }, {
          "code": "OM-WU",
          "represent": "Al Wusţā"
        }, {
          "code": "OM-SH",
          "represent": "Ash Sharqīyah"
        }, {
          "code": "OM-ZA",
          "represent": "Az Zāhirah"
        }, {
          "code": "OM-MA",
          "represent": "Masqaţ"
        }, {
          "code": "OM-MU",
          "represent": "Musandam"
        }]
      }, {
        "name": "Pakistan",
        "code": "PK",
        "divisions": [{
          "code": "PK-IS",
          "represent": "Islamabad"
        }, {
          "code": "PK-BA",
          "represent": "Baluchistan (Balochistān)"
        }, {
          "code": "PK-NW",
          "represent": "North-West Frontier"
        }, {
          "code": "PK-PB",
          "represent": "Punjab"
        }, {
          "code": "PK-SD",
          "represent": "Sind (Sindh)"
        }, {
          "code": "PK-TA",
          "represent": "Federally Administered Tribal Areas"
        }, {
          "code": "PK-JK",
          "represent": "Azad Kashmir"
        }, {
          "code": "PK-NA",
          "represent": "Northern Areas"
        }]
      }, {
        "name": "Palau",
        "code": "PW",
        "divisions": [{
          "code": "PW-PW",
          "represent": "Palau"
        }]
      }, {
        "name": "Panama",
        "code": "PA",
        "divisions": [{
          "code": "PA-1",
          "represent": "Botas del Toro"
        }, {
          "code": "PA-2",
          "represent": "Coclé"
        }, {
          "code": "PA-3",
          "represent": "Colón"
        }, {
          "code": "PA-4",
          "represent": "Chiriquī"
        }, {
          "code": "PA-5",
          "represent": "Darién"
        }, {
          "code": "PA-6",
          "represent": "Herrera"
        }, {
          "code": "PA-7",
          "represent": "Los Santos"
        }, {
          "code": "PA-8",
          "represent": "Panamá"
        }, {
          "code": "PA-9",
          "represent": "Veraguas"
        }, {
          "code": "PA-0",
          "represent": "Comarca de San Blas"
        }]
      }, {
        "name": "Papua New Guinea",
        "code": "PG",
        "divisions": [{
          "code": "PG-NCD",
          "represent": "National Capital District (Port Moresby)"
        }, {
          "code": "PG-CPM",
          "represent": "Central"
        }, {
          "code": "PG-CPK",
          "represent": "Chimbu"
        }, {
          "code": "PG-EHG",
          "represent": "Eastern Highlands"
        }, {
          "code": "PG-EBR",
          "represent": "East New Britain"
        }, {
          "code": "PG-ESW",
          "represent": "East Sepik"
        }, {
          "code": "PG-EPW",
          "represent": "Enga"
        }, {
          "code": "PG-GPK",
          "represent": "Gulf"
        }, {
          "code": "PG-MPM",
          "represent": "Madang"
        }, {
          "code": "PG-MRL",
          "represent": "Manus"
        }, {
          "code": "PG-MBA",
          "represent": "Milne Bay"
        }, {
          "code": "PG-MPL",
          "represent": "Morobe"
        }, {
          "code": "PG-NIK",
          "represent": "New Ireland"
        }, {
          "code": "PG-NPP",
          "represent": "Northern"
        }, {
          "code": "PG-NSA",
          "represent": "North Solomons"
        }, {
          "code": "PG-SAN",
          "represent": "Sandaun [West Sepik]"
        }, {
          "code": "PG-SHM",
          "represent": "Southern Highlands"
        }, {
          "code": "PG-WPD",
          "represent": "Western"
        }, {
          "code": "PG-WHM",
          "represent": "Western Highlands"
        }, {
          "code": "PG-WBK",
          "represent": "West New Britain"
        }]
      }, {
        "name": "Paraguay",
        "code": "PY",
        "divisions": [{
          "code": "PY-ASU",
          "represent": "Asunción"
        }, {
          "code": "PY-16",
          "represent": "Alto Paraguay"
        }, {
          "code": "PY-10",
          "represent": "Alto Parang"
        }, {
          "code": "PY-13",
          "represent": "Amambay"
        }, {
          "code": "PY-19",
          "represent": "Boquerón"
        }, {
          "code": "PY-5",
          "represent": "Caaguazú"
        }, {
          "code": "PY-6",
          "represent": "Caazapá"
        }, {
          "code": "PY-14",
          "represent": "Canindeyú"
        }, {
          "code": "PY-11",
          "represent": "Central"
        }, {
          "code": "PY-1",
          "represent": "Concepción"
        }, {
          "code": "PY-3",
          "represent": "Cordillera"
        }, {
          "code": "PY-4",
          "represent": "Guairá"
        }, {
          "code": "PY-7",
          "represent": "Itapúa"
        }, {
          "code": "PY-8",
          "represent": "Misiones"
        }, {
          "code": "PY-12",
          "represent": "Neembucú"
        }, {
          "code": "PY-9",
          "represent": "Paraguarī"
        }, {
          "code": "PY-15",
          "represent": "Presidente Hayes"
        }, {
          "code": "PY-2",
          "represent": "San Pedro"
        }]
      }, {
        "name": "Peru",
        "code": "PE",
        "divisions": [{
          "code": "PE-CAL",
          "represent": "El Callao"
        }, {
          "code": "PE-AMA",
          "represent": "Amazonas"
        }, {
          "code": "PE-ANC",
          "represent": "Ancash"
        }, {
          "code": "PE-APU",
          "represent": "Apurímac"
        }, {
          "code": "PE-ARE",
          "represent": "Arequipa"
        }, {
          "code": "PE-AYA",
          "represent": "Ayacucho"
        }, {
          "code": "PE-CAJ",
          "represent": "Cajamarca"
        }, {
          "code": "PE-CUS",
          "represent": "Cuzco [Cusco]"
        }, {
          "code": "PE-HUV",
          "represent": "Huancavelica"
        }, {
          "code": "PE-HUC",
          "represent": "Huánuco"
        }, {
          "code": "PE-ICA",
          "represent": "Ica"
        }, {
          "code": "PE-JUN",
          "represent": "Junín"
        }, {
          "code": "PE-LAL",
          "represent": "La Libertad"
        }, {
          "code": "PE-LAM",
          "represent": "Lambayeque"
        }, {
          "code": "PE-LIM",
          "represent": "Lima"
        }, {
          "code": "PE-LOR",
          "represent": "Loreto"
        }, {
          "code": "PE-MDD",
          "represent": "Madre de Dios"
        }, {
          "code": "PE-MOQ",
          "represent": "Moquegua"
        }, {
          "code": "PE-PAS",
          "represent": "Pasco"
        }, {
          "code": "PE-PIU",
          "represent": "Piura"
        }, {
          "code": "PE-PUN",
          "represent": "Puno"
        }, {
          "code": "PE-SAM",
          "represent": "San Martín"
        }, {
          "code": "PE-TAC",
          "represent": "Tacna"
        }, {
          "code": "PE-TUM",
          "represent": "Tumbes"
        }, {
          "code": "PE-UCA",
          "represent": "Ucayali"
        }]
      }, {
        "name": "Philippines",
        "code": "PH",
        "divisions": [{
          "code": "PH-PH",
          "represent": "Philippines"
        }]
      }, {
        "name": "Pitcairn",
        "code": "PN",
        "divisions": [{
          "code": "PN-PN",
          "represent": "Pitcairn"
        }]
      }, {
        "name": "Poland",
        "code": "PL",
        "divisions": [{
          "code": "PL-BP",
          "represent": "Biała Podlaska"
        }, {
          "code": "PL-BK",
          "represent": "Białystok"
        }, {
          "code": "PL-BB",
          "represent": "Bielsko"
        }, {
          "code": "PL-BY",
          "represent": "Bydgoszcz"
        }, {
          "code": "PL-CH",
          "represent": "Chełm"
        }, {
          "code": "PL-CI",
          "represent": "Ciechanów"
        }, {
          "code": "PL-CZ",
          "represent": "Czestochowa"
        }, {
          "code": "PL-EL",
          "represent": "Elblag"
        }, {
          "code": "PL-GD",
          "represent": "Gdańsk"
        }, {
          "code": "PL-GO",
          "represent": "Gorzów"
        }, {
          "code": "PL-JG",
          "represent": "Jelenia Gera"
        }, {
          "code": "PL-KL",
          "represent": "Kalisz"
        }, {
          "code": "PL-KA",
          "represent": "Katowice"
        }, {
          "code": "PL-KI",
          "represent": "Kielce"
        }, {
          "code": "PL-KN",
          "represent": "Konin"
        }, {
          "code": "PL-KO",
          "represent": "Koszalin"
        }, {
          "code": "PL-KR",
          "represent": "Kraków"
        }, {
          "code": "PL-KS",
          "represent": "Krosno"
        }, {
          "code": "PL-LG",
          "represent": "Legnica"
        }, {
          "code": "PL-LE",
          "represent": "Leszno"
        }, {
          "code": "PL-LU",
          "represent": "Lublin"
        }, {
          "code": "PL-LO",
          "represent": "Łomia"
        }, {
          "code": "PL-LD",
          "represent": "Łódź"
        }, {
          "code": "PL-NS",
          "represent": "Nowy Sacz"
        }, {
          "code": "PL-OL",
          "represent": "Olsztyn"
        }, {
          "code": "PL-OP",
          "represent": "Opole"
        }, {
          "code": "PL-OS",
          "represent": "Ostrołeka"
        }, {
          "code": "PL-PI",
          "represent": "Piła"
        }, {
          "code": "PL-PT",
          "represent": "Piotrków"
        }, {
          "code": "PL-PL",
          "represent": "Płock"
        }, {
          "code": "PL-PO",
          "represent": "Poznań"
        }, {
          "code": "PL-PR",
          "represent": "Przemyśl"
        }, {
          "code": "PL-RA",
          "represent": "Radom"
        }, {
          "code": "PL-RZ",
          "represent": "Rzeszów"
        }, {
          "code": "PL-SE",
          "represent": "Siedlce"
        }, {
          "code": "PL-SI",
          "represent": "Sieradz"
        }, {
          "code": "PL-SK",
          "represent": "Skierniewice"
        }, {
          "code": "PL-SL",
          "represent": "Słupsk"
        }, {
          "code": "PL-SU",
          "represent": "Suwałki"
        }, {
          "code": "PL-SZ",
          "represent": "Szczecin"
        }, {
          "code": "PL-TG",
          "represent": "Tarnobrzeg"
        }, {
          "code": "PL-TA",
          "represent": "Tarnów"
        }, {
          "code": "PL-TO",
          "represent": "Toruń"
        }, {
          "code": "PL-WB",
          "represent": "Wałbrzych"
        }, {
          "code": "PL-WA",
          "represent": "Warszawa"
        }, {
          "code": "PL-WL",
          "represent": "Włocławek"
        }, {
          "code": "PL-WR",
          "represent": "Wrocław"
        }, {
          "code": "PL-ZA",
          "represent": "Zamość"
        }, {
          "code": "PL-ZG",
          "represent": "Zielona Góra"
        }]
      }, {
        "name": "Portugal",
        "code": "PT",
        "divisions": [{
          "code": "PT-01",
          "represent": "Aveiro"
        }, {
          "code": "PT-02",
          "represent": "Beja"
        }, {
          "code": "PT-03",
          "represent": "Braga"
        }, {
          "code": "PT-04",
          "represent": "Bragança"
        }, {
          "code": "PT-05",
          "represent": "Castelo Branco"
        }, {
          "code": "PT-06",
          "represent": "Coimbra"
        }, {
          "code": "PT-07",
          "represent": "Évora"
        }, {
          "code": "PT-08",
          "represent": "Faro"
        }, {
          "code": "PT-09",
          "represent": "Guarda"
        }, {
          "code": "PT-10",
          "represent": "Leiria"
        }, {
          "code": "PT-11",
          "represent": "Lisboa"
        }, {
          "code": "PT-12",
          "represent": "Portalegre"
        }, {
          "code": "PT-13",
          "represent": "Porto"
        }, {
          "code": "PT-14",
          "represent": "Santarém"
        }, {
          "code": "PT-15",
          "represent": "Setúbal"
        }, {
          "code": "PT-16",
          "represent": "Viana do Castelo"
        }, {
          "code": "PT-17",
          "represent": "Vila Real"
        }, {
          "code": "PT-18",
          "represent": "Viseu"
        }, {
          "code": "PT-20",
          "represent": "Regiāo Autónoma dos Açores"
        }, {
          "code": "PT-30",
          "represent": "Regiāo Autónoma da Madeira"
        }]
      }, {
        "name": "Puerto Rico",
        "code": "PR",
        "divisions": [{
          "code": "PR-PR",
          "represent": "Puerto Rico"
        }]
      }, {
        "name": "Qatar",
        "code": "QA",
        "divisions": [{
          "code": "QA-DA",
          "represent": "Ad Dawḩah"
        }, {
          "code": "QA-GH",
          "represent": "Al Ghuwayrīyah"
        }, {
          "code": "QA-JU",
          "represent": "Al Jumaylīyah"
        }, {
          "code": "QA-KH",
          "represent": "Al Khawr"
        }, {
          "code": "QA-WA",
          "represent": "Al Wakrah"
        }, {
          "code": "QA-RA",
          "represent": "Ar Rayyān"
        }, {
          "code": "QA-JB",
          "represent": "Jarīyān al Bāţnah"
        }, {
          "code": "QA-MS",
          "represent": "Madīnat ash Shamāl"
        }, {
          "code": "QA-US",
          "represent": "Umm Şalāl"
        }]
      }, {
        "name": "Reunion",
        "code": "RE",
        "divisions": [{
          "code": "RE-RE",
          "represent": "Reunion"
        }]
      }, {
        "name": "Romania",
        "code": "RO",
        "divisions": [{
          "code": "RO-B",
          "represent": "Bucureşti"
        }, {
          "code": "RO-AB",
          "represent": "Alba"
        }, {
          "code": "RO-AR",
          "represent": "Arad"
        }, {
          "code": "RO-AG",
          "represent": "Argeş"
        }, {
          "code": "RO-BC",
          "represent": "Bacău"
        }, {
          "code": "RO-BH",
          "represent": "Bihor"
        }, {
          "code": "RO-BN",
          "represent": "Bistriţa-Năsăud"
        }, {
          "code": "RO-BT",
          "represent": "Botoşani"
        }, {
          "code": "RO-BV",
          "represent": "Braşov"
        }, {
          "code": "RO-BR",
          "represent": "Brăila"
        }, {
          "code": "RO-BZ",
          "represent": "Buzău"
        }, {
          "code": "RO-CS",
          "represent": "Caraş-Severin"
        }, {
          "code": "RO-CL",
          "represent": "Călăraşi"
        }, {
          "code": "RO-CJ",
          "represent": "Cluj"
        }, {
          "code": "RO-CT",
          "represent": "Constanţa"
        }, {
          "code": "RO-CV",
          "represent": "Covasna"
        }, {
          "code": "RO-DB",
          "represent": "Dâmboviţa"
        }, {
          "code": "RO-DJ",
          "represent": "Dolj"
        }, {
          "code": "RO-GL",
          "represent": "Galaţi"
        }, {
          "code": "RO-GR",
          "represent": "Giurgiu"
        }, {
          "code": "RO-GJ",
          "represent": "Gorj"
        }, {
          "code": "RO-HR",
          "represent": "Harghita"
        }, {
          "code": "RO-HD",
          "represent": "Hunedoara"
        }, {
          "code": "RO-IL",
          "represent": "Ialomiţa"
        }, {
          "code": "RO-IS",
          "represent": "Iaşi"
        }, {
          "code": "RO-MM",
          "represent": "Maramureş"
        }, {
          "code": "RO-MH",
          "represent": "Mehedinţi"
        }, {
          "code": "RO-MS",
          "represent": "Mureş"
        }, {
          "code": "RO-NT",
          "represent": "Neamţ"
        }, {
          "code": "RO-OT",
          "represent": "Olt"
        }, {
          "code": "RO-PH",
          "represent": "Prahova"
        }, {
          "code": "RO-SM",
          "represent": "Satu Mare"
        }, {
          "code": "RO-SJ",
          "represent": "Sălaj"
        }, {
          "code": "RO-SB",
          "represent": "Sibiu"
        }, {
          "code": "RO-SV",
          "represent": "Suceava"
        }, {
          "code": "RO-TR",
          "represent": "Teleorman"
        }, {
          "code": "RO-TM",
          "represent": "Timiş"
        }, {
          "code": "RO-TL",
          "represent": "Tulcea"
        }, {
          "code": "RO-VS",
          "represent": "Vaslui"
        }, {
          "code": "RO-VL",
          "represent": "Vâlcea"
        }, {
          "code": "RO-VN",
          "represent": "Vrancea"
        }]
      }, {
        "name": "Russian Federation",
        "code": "RU",
        "divisions": [{
          "code": "RU-AD",
          "represent": "Adygeya, Respublika"
        }, {
          "code": "RU-AL",
          "represent": "Altay, Respublika"
        }, {
          "code": "RU-BA",
          "represent": "Bashkortostan, Respublika"
        }, {
          "code": "RU-BU",
          "represent": "Buryatiya, Respublika"
        }, {
          "code": "RU-CE",
          "represent": "Chechenskaya Respublika"
        }, {
          "code": "RU-CU",
          "represent": "Chuvashskaya Respublika"
        }, {
          "code": "RU-DA",
          "represent": "Dagestan, Respublika"
        }, {
          "code": "RU-IN",
          "represent": "Ingushskaya Respublika"
        }, {
          "code": "RU-KB",
          "represent": "Kabardino-Balkarskaya Respublika"
        }, {
          "code": "RU-KL",
          "represent": "Kalmykiya, Respublika"
        }, {
          "code": "RU-KC",
          "represent": "Karachayevo-Cherkesskaya Respublika"
        }, {
          "code": "RU-KR",
          "represent": "Kareliya, Respublika"
        }, {
          "code": "RU-KK",
          "represent": "Khakasiya, Respublika"
        }, {
          "code": "RU-KO",
          "represent": "Komi, Respublika"
        }, {
          "code": "RU-ME",
          "represent": "Mariy El, Respublika"
        }, {
          "code": "RU-MO",
          "represent": "Mordoviya, Respublika"
        }, {
          "code": "RU-SA",
          "represent": "Sakha, Respublika [Yakutiya]"
        }, {
          "code": "RU-SE",
          "represent": "Severnaya Osetiya, Respublika [Alaniya]"
        }, {
          "code": "RU-TA",
          "represent": "Tatarstan, Respublika"
        }, {
          "code": "RU-TY",
          "represent": "Tyva, Respublika [Tuva]"
        }, {
          "code": "RU-UD",
          "represent": "Udmurtskaya Respublika"
        }, {
          "code": "RU-ALT",
          "represent": "Altayskiy kray"
        }, {
          "code": "RU-KHA",
          "represent": "Khabarovskiy kray"
        }, {
          "code": "RU-KDA",
          "represent": "Krasnodarskiy kray"
        }, {
          "code": "RU-KYA",
          "represent": "Krasnoyarskiy kray"
        }, {
          "code": "RU-PRI",
          "represent": "Primorskiy kray"
        }, {
          "code": "RU-STA",
          "represent": "Stavropol 'skiy kray"
        }, {
          "code": "RU-AMU",
          "represent": "Amurskaya Oblast'"
        }, {
          "code": "RU-ARK",
          "represent": "Arkhangel'skaya Oblast'"
        }, {
          "code": "RU-AST",
          "represent": "Astrakhanskaya Oblast'"
        }, {
          "code": "RU-BEL",
          "represent": "Belgorodskaya Oblast'"
        }, {
          "code": "RU-BRY",
          "represent": "Bryanskaya Oblast'"
        }, {
          "code": "RU-CHE",
          "represent": "Chelyabinskaya Oblast'"
        }, {
          "code": "RU-CHI",
          "represent": "Chitinskaya Oblast'"
        }, {
          "code": "RU-IRK",
          "represent": "Irkutskaya Oblast'"
        }, {
          "code": "RU-IVA",
          "represent": "Ivanovskaya Oblast'"
        }, {
          "code": "RU-KGD",
          "represent": "Kaliningradskaya Oblast'"
        }, {
          "code": "RU-KLU",
          "represent": "Kaluzhskaya Oblast'"
        }, {
          "code": "RU-KAM",
          "represent": "Kamchatskaya Oblast'"
        }, {
          "code": "RU-KEM",
          "represent": "Kemerovskaya Oblast'"
        }, {
          "code": "RU-KIR",
          "represent": "Kirovskaya Oblast'"
        }, {
          "code": "RU-KOS",
          "represent": "Kostromskaya Oblast'"
        }, {
          "code": "RU-KGN",
          "represent": "Kurganskaya Oblast'"
        }, {
          "code": "RU-KRS",
          "represent": "Kurskaya Oblast'"
        }, {
          "code": "RU-LEN",
          "represent": "Leningradskaya Oblast'"
        }, {
          "code": "RU-LIP",
          "represent": "Lipetskaya Oblast'"
        }, {
          "code": "RU-MAG",
          "represent": "Magadanskaya Oblast'"
        }, {
          "code": "RU-MOS",
          "represent": "Moskovskaya Oblast'"
        }, {
          "code": "RU-MUR",
          "represent": "Murmanskaya Oblast'"
        }, {
          "code": "RU-NIZ",
          "represent": "Nizhegorodskaya Oblast'"
        }, {
          "code": "RU-NGR",
          "represent": "Novgorodskaya Oblast'"
        }, {
          "code": "RU-NVS",
          "represent": "Novosibirskaya Oblast'"
        }, {
          "code": "RU-OMS",
          "represent": "Omskaya Oblast'"
        }, {
          "code": "RU-ORE",
          "represent": "Orenburgskaya Oblast'"
        }, {
          "code": "RU-ORL",
          "represent": "Orlovskaya Oblast'"
        }, {
          "code": "RU-PNZ",
          "represent": "Penzenskaya Oblast'"
        }, {
          "code": "RU-PER",
          "represent": "Permskaya Oblast'"
        }, {
          "code": "RU-PSK",
          "represent": "Pskovskaya Oblast'"
        }, {
          "code": "RU-ROS",
          "represent": "Rostovskaya Oblast'"
        }, {
          "code": "RU-RYA",
          "represent": "Ryazanskaya Oblast'"
        }, {
          "code": "RU-SAK",
          "represent": "Sakhalinskaya Oblast'"
        }, {
          "code": "RU-SAM",
          "represent": "Samarskaya Oblast’"
        }, {
          "code": "RU-SAR",
          "represent": "Saratovskaya Oblast'"
        }, {
          "code": "RU-SMO",
          "represent": "Smolenskaya Oblast'"
        }, {
          "code": "RU-SVE",
          "represent": "Sverdlovskaya Oblast'"
        }, {
          "code": "RU-TAM",
          "represent": "Tambovskaya Oblast'"
        }, {
          "code": "RU-TOM",
          "represent": "Tomskaya Oblast’"
        }, {
          "code": "RU-TUL",
          "represent": "Tul'skaya Oblast'"
        }, {
          "code": "RU-TVE",
          "represent": "Tverskaya Oblast'"
        }, {
          "code": "RU-TYU",
          "represent": "Tyumenskaya Oblast'"
        }, {
          "code": "RU-ULY",
          "represent": "Ul'yanovskaya Oblast'"
        }, {
          "code": "RU-VLA",
          "represent": "Vladimirskaya Oblast'"
        }, {
          "code": "RU-VGG",
          "represent": "Volgogradskaya Oblast'"
        }, {
          "code": "RU-VLG",
          "represent": "Vologodskaya Oblast'"
        }, {
          "code": "RU-VOR",
          "represent": "Voronezhskaya Oblast'"
        }, {
          "code": "RU-YAR",
          "represent": "Yaroslavskaya Oblast'"
        }, {
          "code": "RU-MOW",
          "represent": "Moskva"
        }, {
          "code": "RU-SPE",
          "represent": "Sankt-Peterburg"
        }, {
          "code": "RU-YEV",
          "represent": "Yevreyskaya avtonomnaya oblast'"
        }, {
          "code": "RU-AGB",
          "represent": "Aginskiy Buryatskiy avtonomnyy okrug"
        }, {
          "code": "RU-CHU",
          "represent": "Chukotskiy avtonomnyy okrug"
        }, {
          "code": "RU-EVE",
          "represent": "Evenkiyskiy avtonomnyy okrug"
        }, {
          "code": "RU-KHM",
          "represent": "Khanty-Mansiyskiy avtonomnyy okrug"
        }, {
          "code": "RU-KOP",
          "represent": "Komi-Permyatskiy avtonomnyy okrug"
        }, {
          "code": "RU-KOR",
          "represent": "Koryakskiy avtonomnyy okrug"
        }, {
          "code": "RU-NEN",
          "represent": "Nenetskiy avtonomnyy okrug"
        }, {
          "code": "RU-TAY",
          "represent": "Taymyrskiy (Dolgano-Nenetskiy) avtonomnyy okrug"
        }, {
          "code": "RU-UOB",
          "represent": "Ust’-Ordynskiy Buryatskiy avtonomnyy okrug"
        }, {
          "code": "RU-YAN",
          "represent": "Yamalo-Nenetskiy avtonomnyy okrug"
        }]
      }, {
        "name": "Rwanda",
        "code": "RW",
        "divisions": [{
          "code": "RW-C",
          "represent": "Butare"
        }, {
          "code": "RW-I",
          "represent": "Byumba"
        }, {
          "code": "RW-E",
          "represent": "Cyangugu"
        }, {
          "code": "RW-D",
          "represent": "Gikongoro"
        }, {
          "code": "RW-G",
          "represent": "Gisenyi"
        }, {
          "code": "RW-B",
          "represent": "Gitarama"
        }, {
          "code": "RW-J",
          "represent": "Kibungo"
        }, {
          "code": "RW-F",
          "represent": "Kibuye"
        }, {
          "code": "RW-K",
          "represent": "Kigali-Rural (Kigali y’ Icyaro)"
        }, {
          "code": "RW-L",
          "represent": "Kigali-Ville (Kilgali Ngari)"
        }, {
          "code": "RW-M",
          "represent": "Mutara"
        }, {
          "code": "RW-H",
          "represent": "Ruhengeri"
        }]
      }, {
        "name": "St. Helena",
        "code": "SH",
        "divisions": [{
          "code": "SH-SH",
          "represent": "Saint Helena"
        }, {
          "code": "SH-AC",
          "represent": "Ascension"
        }, {
          "code": "SH-TA",
          "represent": "Tristan da Cunha"
        }]
      }, {
        "name": "Saint Kitts & Nevis",
        "code": "KN",
        "divisions": [{
          "code": "KN-KN",
          "represent": "Saint Kitts & Nevis"
        }]
      }, {
        "name": "Saint Lucia",
        "code": "LC",
        "divisions": [{
          "code": "LC-LC",
          "represent": "Saint Lucia"
        }]
      }, {
        "name": "St. Pierre & Miquelon",
        "code": "PM",
        "divisions": [{
          "code": "PM-PM",
          "represent": "St. Pierre & Miquelon"
        }]
      }, {
        "name": "St. Vincent & the Grenadines",
        "code": "VC",
        "divisions": [{
          "code": "VC-VC",
          "represent": "St. Vincent & the Grenadines"
        }]
      }, {
        "name": "Samoa",
        "code": "WS",
        "divisions": [{
          "code": "WS-AA",
          "represent": "A'ana"
        }, {
          "code": "WS-AL",
          "represent": "Aiga-i-le-Tai"
        }, {
          "code": "WS-AT",
          "represent": "Atua"
        }, {
          "code": "WS-FA",
          "represent": "Fa'asaleleaga"
        }, {
          "code": "WS-GE",
          "represent": "Gaga'emauga"
        }, {
          "code": "WS-GI",
          "represent": "Gagaifomauga"
        }, {
          "code": "WS-PA",
          "represent": "Palauli"
        }, {
          "code": "WS-SA",
          "represent": "Satupa'itea"
        }, {
          "code": "WS-TU",
          "represent": "Tuamasaga"
        }, {
          "code": "WS-VF",
          "represent": "Va'a-o-Fonoti"
        }, {
          "code": "WS-VS",
          "represent": "Vaisigano"
        }]
      }, {
        "name": "San Marino",
        "code": "SM",
        "divisions": [{
          "code": "SM-SM",
          "represent": "San Marino"
        }]
      }, {
        "name": "Sao Tome & Principe",
        "code": "ST",
        "divisions": [{
          "code": "ST-P",
          "represent": "Príncipe"
        }, {
          "code": "ST-S",
          "represent": "Sāo Tomé"
        }]
      }, {
        "name": "Saudi Arabia",
        "code": "SA",
        "divisions": [{
          "code": "SA-11",
          "represent": "Al Bāḩah"
        }, {
          "code": "SA-08",
          "represent": "Al Ḩudūd ash Shamālīyah"
        }, {
          "code": "SA-12",
          "represent": "Al Jawf"
        }, {
          "code": "SA-03",
          "represent": "Al Madīnah"
        }, {
          "code": "SA-05",
          "represent": "Al Qaşim"
        }, {
          "code": "SA-O1",
          "represent": "Ar Riyāḑ"
        }, {
          "code": "SA-04",
          "represent": "Ash Sharqīyah"
        }, {
          "code": "SA-14",
          "represent": "‘Asīr"
        }, {
          "code": "SA-06",
          "represent": "Ḩā'il"
        }, {
          "code": "SA-09",
          "represent": "Jīzān"
        }, {
          "code": "SA-02",
          "represent": "Makkah"
        }, {
          "code": "SA-10",
          "represent": "Najrān"
        }, {
          "code": "SA-07",
          "represent": "Tabūk"
        }]
      }, {
        "name": "Senegal",
        "code": "SN",
        "divisions": [{
          "code": "SN-DK",
          "represent": "Dakar"
        }, {
          "code": "SN-DB",
          "represent": "Diourbel"
        }, {
          "code": "SN-FK",
          "represent": "Fatick"
        }, {
          "code": "SN-KL",
          "represent": "Kaolack"
        }, {
          "code": "SN-KD",
          "represent": "Kolda"
        }, {
          "code": "SN-LG",
          "represent": "Louga"
        }, {
          "code": "SN-SL",
          "represent": "Saint-Louis"
        }, {
          "code": "SN-TC",
          "represent": "Tambacounda"
        }, {
          "code": "SN-TH",
          "represent": "Thiès"
        }, {
          "code": "SN-ZG",
          "represent": "Ziguinchor"
        }]
      }, {
        "name": "Serbia",
        "code": "RS",
        "divisions": [{
          'code': 'RS-00',
          'represent': 'Beograd'
        }, {
          'code': 'RS-14',
          'represent': 'Borski okrug'
        }, {
          'code': 'RS-11',
          'represent': 'Braničevski okrug'
        }, {
          'code': 'RS-23',
          'represent': 'Jablanički okrug'
        }, {
          'code': 'RS-04',
          'represent': 'Južnobanatski okrug'
        }, {
          'code': 'RS-06',
          'represent': 'Južnobački okrug'
        }, {
          'code': 'RS-09',
          'represent': 'Kolubarski okrug'
        }, {
          'code': 'RS-KM',
          'represent': 'Kosovo-Metohija'
        }, {
          'code': 'RS-25',
          'represent': 'Kosovski okrug'
        }, {
          'code': 'RS-28',
          'represent': 'Kosovsko-Mitrovački okrug'
        }, {
          'code': 'RS-29',
          'represent': 'Kosovsko-Pomoravski okrug'
        }, {
          'code': 'RS-08',
          'represent': 'Mačvanski okrug'
        }, {
          'code': 'RS-17',
          'represent': 'Moravički okrug'
        }, {
          'code': 'RS-20',
          'represent': 'Nišavski okrug'
        }, {
          'code': 'RS-26',
          'represent': 'Pećki okrug'
        }, {
          'code': 'RS-22',
          'represent': 'Pirotski okrug'
        }, {
          'code': 'RS-10',
          'represent': 'Podunavski okrug'
        }, {
          'code': 'RS-13',
          'represent': 'Pomoravski okrug'
        }, {
          'code': 'RS-27',
          'represent': 'Prizrenski okrug'
        }, {
          'code': 'RS-24',
          'represent': 'Pčinjski okrug'
        }, {
          'code': 'RS-19',
          'represent': 'Rasinski okrug'
        }, {
          'code': 'RS-18',
          'represent': 'Raški okrug'
        }, {
          'code': 'RS-03',
          'represent': 'Severnobanatski okrug'
        }, {
          'code': 'RS-01',
          'represent': 'Severnobački okrug'
        }, {
          'code': 'RS-02',
          'represent': 'Srednjebanatski okrug'
        }, {
          'code': 'RS-07',
          'represent': 'Sremski okrug'
        }, {
          'code': 'RS-21',
          'represent': 'Toplički okrug'
        }, {
          'code': 'RS-VO',
          'represent': 'Vojvodina'
        }, {
          'code': 'RS-15',
          'represent': 'Zaječarski okrug'
        }, {
          'code': 'RS-05',
          'represent': 'Zapadnobački okrug'
        }, {
          'code': 'RS-16',
          'represent': 'Zlatiborski okrug'
        }, {
          'code': 'RS-12',
          'represent': 'Šumadijski okrug'
        }]
      }, {
        "name": "Seychelles",
        "code": "SC",
        "divisions": [{
          "code": "SC-SC",
          "represent": "Seychelles"
        }]
      }, {
        "name": "Sierra Leone",
        "code": "SL",
        "divisions": [{
          "code": "SL-W",
          "represent": "Western Area (Freetown)"
        }, {
          "code": "SL-E",
          "represent": "Eastern"
        }, {
          "code": "SL-N",
          "represent": "Northern"
        }, {
          "code": "SL-S",
          "represent": "Southern"
        }]
      }, {
        "name": "Singapore",
        "code": "SG",
        "divisions": [{
          "code": "SG-SG",
          "represent": "Singapore"
        }]
      }, {
        "name": "Slovak Republic",
        "code": "SK",
        "divisions": [{
          "code": "SK-BC",
          "represent": "Banskobystrický kraj"
        }, {
          "code": "SK-BL",
          "represent": "Bratislavský kraj"
        }, {
          "code": "SK-KI",
          "represent": "Košický kraj"
        }, {
          "code": "SK-NI",
          "represent": "Nitriansky kraj"
        }, {
          "code": "SK-PV",
          "represent": "Prešovský kraj"
        }, {
          "code": "SK-TC",
          "represent": "Trenčiansky kraj"
        }, {
          "code": "SK-TA",
          "represent": "Trnavský kraj"
        }, {
          "code": "SK-ZI",
          "represent": "Žilinský kraj"
        }]
      }, {
        "name": "Slovenia",
        "code": "SI",
        "divisions": [{
          "code": "SI-07",
          "represent": "Dolenjska"
        }, {
          "code": "SI-09",
          "represent": "Gorenjska"
        }, {
          "code": "SI-11",
          "represent": "Goriška"
        }, {
          "code": "SI-03",
          "represent": "Koroška"
        }, {
          "code": "SI-10",
          "represent": "Notranjsko-kraška"
        }, {
          "code": "SI-12",
          "represent": "Obalno-kraška"
        }, {
          "code": "SI-08",
          "represent": "Osrednjeslovenska"
        }, {
          "code": "SI-02",
          "represent": "Podravska"
        }, {
          "code": "SI-01",
          "represent": "Pomurska"
        }, {
          "code": "SI-04",
          "represent": "Savinjska"
        }, {
          "code": "SI-06",
          "represent": "Spodnjeposavska"
        }, {
          "code": "SI-05",
          "represent": "Zasavska"
        }]
      }, {
        "name": "Solomon Islands",
        "code": "SB",
        "divisions": [{
          "code": "SB-CT",
          "represent": "Capital Territory (Honiara)"
        }, {
          "code": "SB-CE",
          "represent": "Central"
        }, {
          "code": "SB-GU",
          "represent": "Guadalcanal"
        }, {
          "code": "SB-IS",
          "represent": "Isabel"
        }, {
          "code": "SB-MK",
          "represent": "Makira"
        }, {
          "code": "SB-ML",
          "represent": "Malaita"
        }, {
          "code": "SB-TE",
          "represent": "Temotu"
        }, {
          "code": "SB-WE",
          "represent": "Western"
        }]
      }, {
        "name": "Somalia",
        "code": "SO",
        "divisions": [{
          "code": "SO-AW",
          "represent": "Awdal"
        }, {
          "code": "SO-BK",
          "represent": "Bakool"
        }, {
          "code": "SO-BN",
          "represent": "Banaadir"
        }, {
          "code": "SO-BR",
          "represent": "Bari"
        }, {
          "code": "SO-BY",
          "represent": "BaY"
        }, {
          "code": "SO-GA",
          "represent": "Galguduud"
        }, {
          "code": "SO-GE",
          "represent": "Gedo"
        }, {
          "code": "SO-HI",
          "represent": "Hiiraan"
        }, {
          "code": "SO-JD",
          "represent": "Jubbada Dhexe"
        }, {
          "code": "SO-JH",
          "represent": "Jubbada Hoose"
        }, {
          "code": "SO-MU",
          "represent": "Mudug"
        }, {
          "code": "SO-NU",
          "represent": "Nugaal"
        }, {
          "code": "SO-SA",
          "represent": "Sanaag"
        }, {
          "code": "SO-SD",
          "represent": "Shabeellaha Dhexe"
        }, {
          "code": "SO-SH",
          "represent": "Shabeellaha Hoose"
        }, {
          "code": "SO-SO",
          "represent": "Sool"
        }, {
          "code": "SO-TO",
          "represent": "Togdheer"
        }, {
          "code": "SO-WO",
          "represent": "Woqooyi Galbeed"
        }]
      }, {
        "name": "South Africa",
        "code": "ZA",
        "divisions": [{
          "code": "ZA-EC",
          "represent": "Eastern Cape (Oos-Kaap)"
        }, {
          "code": "ZA-FS",
          "represent": "Free State (Vrystaat)"
        }, {
          "code": "ZA-GT",
          "represent": "Gauteng"
        }, {
          "code": "ZA-NL",
          "represent": "Kwazulu-Natal"
        }, {
          "code": "ZA-MP",
          "represent": "Mpumalanga"
        }, {
          "code": "ZA-NC",
          "represent": "Northern Cape (Noord-Kaap)"
        }, {
          "code": "ZA-NP",
          "represent": "Northern Province (Noordelike Provinsie)"
        }, {
          "code": "ZA-NW",
          "represent": "North-West (Noord-Wes)"
        }, {
          "code": "ZA-WC",
          "represent": "Western Cape (Wes-Kaap)"
        }]
      }, {
        "name": "S.Georgia & S.Sandwich Islands",
        "code": "GS",
        "divisions": [{
          "code": "GS-GS",
          "represent": "S.Georgia & S.Sandwich Islands"
        }]
      }, {
        "name": "Spain",
        "code": "ES",
        "divisions": [{
          "code": "ES-AN",
          "represent": "Andalucía"
        }, {
          "code": "ES-AL",
          "represent": "Almería"
        }, {
          "code": "ES-CA",
          "represent": "Cádiz"
        }, {
          "code": "ES-CO",
          "represent": "Córdoba"
        }, {
          "code": "ES-GR",
          "represent": "Granada"
        }, {
          "code": "ES-H",
          "represent": "Huelva"
        }, {
          "code": "ES-J",
          "represent": "Jaén"
        }, {
          "code": "ES-MA",
          "represent": "Málaga"
        }, {
          "code": "ES-SE",
          "represent": "Sevilla"
        }, {
          "code": "ES-AR",
          "represent": "Aragón"
        }, {
          "code": "ES-HU",
          "represent": "Huesca"
        }, {
          "code": "ES-TE",
          "represent": "Teruel"
        }, {
          "code": "ES-Z",
          "represent": "Zaragoza"
        }, {
          "code": "ES-O",
          "represent": "Asturias"
        }, {
          "code": "ES-CN",
          "represent": "Canarias"
        }, {
          "code": "ES-GC",
          "represent": "Las Palmas"
        }, {
          "code": "ES-TF",
          "represent": "Santa Cruz De Tenerife"
        }, {
          "code": "ES-S",
          "represent": "Cantabria"
        }, {
          "code": "ES-CM",
          "represent": "Castilla-La Mancha"
        }, {
          "code": "ES-AB",
          "represent": "Albacete"
        }, {
          "code": "ES-CR",
          "represent": "Ciudad Real"
        }, {
          "code": "ES-CU",
          "represent": "Cuenca"
        }, {
          "code": "ES-GU",
          "represent": "Guadalajara"
        }, {
          "code": "ES-TO",
          "represent": "Toledo"
        }, {
          "code": "ES-CL",
          "represent": "Castilla y León"
        }, {
          "code": "ES-AV",
          "represent": "Ávila"
        }, {
          "code": "ES-BU",
          "represent": "Burgos"
        }, {
          "code": "ES-LE",
          "represent": "León"
        }, {
          "code": "ES-P",
          "represent": "Palencia"
        }, {
          "code": "ES-SA",
          "represent": "Salamanca"
        }, {
          "code": "ES-SG",
          "represent": "Segovia"
        }, {
          "code": "ES-SO",
          "represent": "Soria"
        }, {
          "code": "ES-VA",
          "represent": "Valladolid"
        }, {
          "code": "ES-ZA",
          "represent": "Zamora"
        }, {
          "code": "ES-CT",
          "represent": "Cataluña"
        }, {
          "code": "ES-B",
          "represent": "Barcelona"
        }, {
          "code": "ES-GE",
          "represent": "Gerona"
        }, {
          "code": "ES-L",
          "represent": "Lérida"
        }, {
          "code": "ES-T",
          "represent": "Tarragona"
        }, {
          "code": "ES-EX",
          "represent": "Extremadura"
        }, {
          "code": "ES-BA",
          "represent": "Badajoz"
        }, {
          "code": "ES-CC",
          "represent": "Cáceres"
        }, {
          "code": "ES-GA",
          "represent": "Galicia"
        }, {
          "code": "ES-C",
          "represent": "La Coruña"
        }, {
          "code": "ES-LU",
          "represent": "Lugo"
        }, {
          "code": "ES-OR",
          "represent": "Orense"
        }, {
          "code": "ES-PO",
          "represent": "Pontevedra"
        }, {
          "code": "ES-PM",
          "represent": "Baleares"
        }, {
          "code": "ES-LO",
          "represent": "La Rioja"
        }, {
          "code": "ES-M",
          "represent": "Madrid"
        }, {
          "code": "ES-MU",
          "represent": "Murcia"
        }, {
          "code": "ES-NA",
          "represent": "Navarra"
        }, {
          "code": "ES-PV",
          "represent": "País Vasco"
        }, {
          "code": "ES-VI",
          "represent": "Álava"
        }, {
          "code": "ES-SS",
          "represent": "Guipúzcoa"
        }, {
          "code": "ES-BI",
          "represent": "Vizcaya"
        }, {
          "code": "ES-VC",
          "represent": "Valenciana, Comunidad"
        }, {
          "code": "ES-A",
          "represent": "Alicante"
        }, {
          "code": "ES-CS",
          "represent": "Castellón"
        }, {
          "code": "ES-V",
          "represent": "Valencia"
        }]
      }, {
        "name": "Sri Lanka",
        "code": "LK",
        "divisions": [{
          "code": "LK-1",
          "represent": "Basnahira Palata (Western Province)"
        }, {
          "code": "LK-11",
          "represent": "Colombo"
        }, {
          "code": "LK-12",
          "represent": "Gampaha"
        }, {
          "code": "LK-13",
          "represent": "Kalutara"
        }, {
          "code": "LK-3",
          "represent": "Dakunu Palata (Southern Province)"
        }, {
          "code": "LK-31",
          "represent": "Galle"
        }, {
          "code": "LK-33",
          "represent": "Hambantota"
        }, {
          "code": "LK-32",
          "represent": "Matara"
        }, {
          "code": "LK-2",
          "represent": "Madhyama Palata (Central Province)"
        }, {
          "code": "LK-21",
          "represent": "Kandy"
        }, {
          "code": "LK-22",
          "represent": "Matale"
        }, {
          "code": "LK-23",
          "represent": "Nuwara Eliya"
        }, {
          "code": "LK-5",
          "represent": "Negenahira Palata (Eastern Province)"
        }, {
          "code": "LK-52",
          "represent": "Arnpara"
        }, {
          "code": "LK-51",
          "represent": "Batticaloa"
        }, {
          "code": "LK-53",
          "represent": "Trincomalee"
        }, {
          "code": "LK-9",
          "represent": "Sabaragamuwa Palata"
        }, {
          "code": "LK-92",
          "represent": "Kegalla"
        }, {
          "code": "LK-91",
          "represent": "Ratnapura"
        }, {
          "code": "LK-7",
          "represent": "Uturumeda Palata (North Central Province)"
        }, {
          "code": "LK-71",
          "represent": "Anuradhapura"
        }, {
          "code": "LK-72",
          "represent": "Polonnaruwa"
        }, {
          "code": "LK-4",
          "represent": "Uturu Palata (Northern Province)"
        }, {
          "code": "LK-41",
          "represent": "Jaffna"
        }, {
          "code": "LK-42",
          "represent": "Kilinochchi"
        }, {
          "code": "LK-43",
          "represent": "Mannar"
        }, {
          "code": "LK-45",
          "represent": "Mullaittivu"
        }, {
          "code": "LK-44",
          "represent": "Vavuniya"
        }, {
          "code": "LK-8",
          "represent": "Uva Palata"
        }, {
          "code": "LK-81",
          "represent": "Badulla"
        }, {
          "code": "LK-82",
          "represent": "Monaragala"
        }, {
          "code": "LK-6",
          "represent": "Wayamba Palata (North Western Province)"
        }, {
          "code": "LK-61",
          "represent": "Kurunegala"
        }, {
          "code": "LK-62",
          "represent": "Puttalam"
        }]
      }, {
        "name": "Sudan",
        "code": "SD",
        "divisions": [{
          "code": "SD-23",
          "represent": "A‘ālī an Nīl"
        }, {
          "code": "SD-26",
          "represent": "Al Baḩr al Aḩmar"
        }, {
          "code": "SD-18",
          "represent": "Al Buḩayrāt"
        }, {
          "code": "SD-07",
          "represent": "Al Jazīrah"
        }, {
          "code": "SD-03",
          "represent": "Al Kharţūm"
        }, {
          "code": "SD-06",
          "represent": "Al Qaḑārif"
        }, {
          "code": "SD-22",
          "represent": "Al Waḩdah"
        }, {
          "code": "SD-04",
          "represent": "An Nīl"
        }, {
          "code": "SD-08",
          "represent": "An Nīl al Abyaḑ"
        }, {
          "code": "SD-24",
          "represent": "An Nīl al Azraq"
        }, {
          "code": "SD-01",
          "represent": "Ash Shamālīyah"
        }, {
          "code": "SD-17",
          "represent": "Baḩr al Jabal"
        }, {
          "code": "SD-16",
          "represent": "Gharb al Istiwā'īyah"
        }, {
          "code": "SD-14",
          "represent": "Gharb Baḩr al Ghazāl"
        }, {
          "code": "SD-12",
          "represent": "Gharb Dārfūr"
        }, {
          "code": "SD-10",
          "represent": "Gharb Kurdufān"
        }, {
          "code": "SD-11",
          "represent": "Janūb Dārfūr"
        }, {
          "code": "SD-13",
          "represent": "Janūb Kurdufān"
        }, {
          "code": "SD-20",
          "represent": "Jūnqalī"
        }, {
          "code": "SD-05",
          "represent": "Kassalā"
        }, {
          "code": "SD-15",
          "represent": "Shamāl Baḩr al Ghazāl"
        }, {
          "code": "SD-02",
          "represent": "Shamāl Dārfūr"
        }, {
          "code": "SD-09",
          "represent": "Shamāl Kurdufān"
        }, {
          "code": "SD-19",
          "represent": "Sharq al Istiwā'iyah"
        }, {
          "code": "SD-25",
          "represent": "Sinnār"
        }, {
          "code": "SD-21",
          "represent": "Wārāb"
        }]
      }, {
        "name": "Suriname",
        "code": "SR",
        "divisions": [{
          "code": "SR-BR",
          "represent": "Brokopondo"
        }, {
          "code": "SR-CM",
          "represent": "Commewijne"
        }, {
          "code": "SR-CR",
          "represent": "Coronie"
        }, {
          "code": "SR-MA",
          "represent": "Marowijne"
        }, {
          "code": "SR-NI",
          "represent": "Nickerie"
        }, {
          "code": "SR-PR",
          "represent": "Para"
        }, {
          "code": "SR-PM",
          "represent": "Paramaribo"
        }, {
          "code": "SR-SA",
          "represent": "Saramacca"
        }, {
          "code": "SR-SI",
          "represent": "Sipaliwini"
        }, {
          "code": "SR-WA",
          "represent": "Wanica"
        }]
      }, {
        "name": "Svalbard & Jan Mayen Islands",
        "code": "SJ",
        "divisions": [{
          "code": "SJ-SJ",
          "represent": "Svalbard & Jan Mayen Islands"
        }]
      }, {
        "name": "Swaziland",
        "code": "SZ",
        "divisions": [{
          "code": "SZ-HH",
          "represent": "Hhohho"
        }, {
          "code": "SZ-LU",
          "represent": "Lubombo"
        }, {
          "code": "SZ-MA",
          "represent": "Manzini"
        }, {
          "code": "SZ-SH",
          "represent": "Shiselweni"
        }]
      }, {
        "name": "Sweden",
        "code": "SE",
        "divisions": [{
          "code": "SE-K",
          "represent": "Blekinge län"
        }, {
          "code": "SE-W",
          "represent": "Dalarnas län"
        }, {
          "code": "SE-I",
          "represent": "Gotlands län"
        }, {
          "code": "SE-X",
          "represent": "Gävleborgs län"
        }, {
          "code": "SE-N",
          "represent": "Hallands län"
        }, {
          "code": "SE-Z",
          "represent": "Jämtlands län"
        }, {
          "code": "SE-F",
          "represent": "Jönköpings län"
        }, {
          "code": "SE-H",
          "represent": "Kalmar län"
        }, {
          "code": "SE-G",
          "represent": "Kronobergs län"
        }, {
          "code": "SE-BD",
          "represent": "Norrbottens län"
        }, {
          "code": "SE-M",
          "represent": "Skåne län"
        }, {
          "code": "SE-AB",
          "represent": "Stockholms län"
        }, {
          "code": "SE-D",
          "represent": "Södermanlands län"
        }, {
          "code": "SE-C",
          "represent": "Uppsala län"
        }, {
          "code": "SE-S",
          "represent": "Värmlands län"
        }, {
          "code": "SE-AC",
          "represent": "Västerbottens län"
        }, {
          "code": "SE-Y",
          "represent": "Västernorrlands län"
        }, {
          "code": "SE-U",
          "represent": "Västmanlands län"
        }, {
          "code": "SE-O",
          "represent": "Västra Götalands län"
        }, {
          "code": "SE-T",
          "represent": "Örebro län"
        }, {
          "code": "SE-E",
          "represent": "Östergötlands län"
        }]
      }, {
        "name": "Switzerland",
        "code": "CH",
        "divisions": [{
          "code": "CH-AG",
          "represent": "Aargau"
        }, {
          "code": "CH-AR",
          "represent": "Appenzell Ausser-Rhoden"
        }, {
          "code": "CH-AI",
          "represent": "Appenzell Inner-Rhoden"
        }, {
          "code": "CH-BL",
          "represent": "Basel-Landschaft"
        }, {
          "code": "CH-BS",
          "represent": "Basel-Stadt"
        }, {
          "code": "CH-BE",
          "represent": "Bern"
        }, {
          "code": "CH-FR",
          "represent": "Freiburg"
        }, {
          "code": "CH-GE",
          "represent": "Geneve"
        }, {
          "code": "CH-GL",
          "represent": "Glarus"
        }, {
          "code": "CH-GR",
          "represent": "Graubünden"
        }, {
          "code": "CH-JU",
          "represent": "Jura"
        }, {
          "code": "CH-LU",
          "represent": "Luzern"
        }, {
          "code": "CH-NE",
          "represent": "Neuchatel"
        }, {
          "code": "CH-NW",
          "represent": "Nidwalden"
        }, {
          "code": "CH-OW",
          "represent": "Obwalden"
        }, {
          "code": "CH-SG",
          "represent": "Sankt Gallen"
        }, {
          "code": "CH-SH",
          "represent": "Schaffhausen"
        }, {
          "code": "CH-SZ",
          "represent": "Schwyz"
        }, {
          "code": "CH-SO",
          "represent": "Solothurn"
        }, {
          "code": "CH-TG",
          "represent": "Thurgau"
        }, {
          "code": "CH-TI",
          "represent": "Ticino"
        }, {
          "code": "CH-UR",
          "represent": "Uri"
        }, {
          "code": "CH-VS",
          "represent": "Wallis"
        }, {
          "code": "CH-VD",
          "represent": "Vaud"
        }, {
          "code": "CH-ZG",
          "represent": "Zug"
        }, {
          "code": "CH-ZH",
          "represent": "Zürich"
        }]
      }, {
        "name": "Syria",
        "code": "SY",
        "divisions": [{
          "code": "SY-HA",
          "represent": "Al Ḩasakah"
        }, {
          "code": "SY-LA",
          "represent": "Al Lādhiqīyah"
        }, {
          "code": "SY-QU",
          "represent": "Al Qunayţirah"
        }, {
          "code": "SY-RA",
          "represent": "Ar Raqqah"
        }, {
          "code": "SY-SU",
          "represent": "As Suwaydā'"
        }, {
          "code": "SY-DR",
          "represent": "Dar’ā"
        }, {
          "code": "SY-DY",
          "represent": "Dayr az Zawr"
        }, {
          "code": "SY-DI",
          "represent": "Dimashq"
        }, {
          "code": "SY-HL",
          "represent": "Ḩalab"
        }, {
          "code": "SY-HM",
          "represent": "Ḩamāh"
        }, {
          "code": "SY-HI",
          "represent": "Ḩimş"
        }, {
          "code": "SY-ID",
          "represent": "Idlib"
        }, {
          "code": "SY-RD",
          "represent": "Rīf Dimashq"
        }, {
          "code": "SY-TA",
          "represent": "Ţarţūs"
        }]
      }, {
        "name": "Taiwan",
        "code": "TW",
        "divisions": [{
          "code": "TW-KHH",
          "represent": "Kaohsiung"
        }, {
          "code": "TW-TPE",
          "represent": "Taipei"
        }, {
          "code": "TW-CYI",
          "represent": "Chiayi"
        }, {
          "code": "TW-HSZ",
          "represent": "Hsinchu"
        }, {
          "code": "TW-KEE",
          "represent": "Keelung"
        }, {
          "code": "TW-TXG",
          "represent": "Taichung"
        }, {
          "code": "TW-TNN",
          "represent": "Tainan"
        }, {
          "code": "TW-CHA",
          "represent": "Changhua"
        }, {
          "code": "TW-HUA",
          "represent": "Hualien"
        }, {
          "code": "TW-ILA",
          "represent": "Ilan"
        }, {
          "code": "TW-MIA",
          "represent": "Miaoli"
        }, {
          "code": "TW-NAN",
          "represent": "Nantou"
        }, {
          "code": "TW-PEN",
          "represent": "Penghu"
        }, {
          "code": "TW-PIF",
          "represent": "Pingtung"
        }, {
          "code": "TW-TTT",
          "represent": "Taitung"
        }, {
          "code": "TW-TAO",
          "represent": "Taoyuan"
        }, {
          "code": "TW-YUN",
          "represent": "Yunlin"
        }]
      }, {
        "name": "Tajikistan",
        "code": "TJ",
        "divisions": [{
          "code": "TJ-KR",
          "represent": "Karategin"
        }, {
          "code": "TJ-KT",
          "represent": "Khatlon"
        }, {
          "code": "TJ-LN",
          "represent": "Leninabad"
        }, {
          "code": "TJ-GB",
          "represent": "Gorno-Badakhshan"
        }]
      }, {
        "name": "Tanzania",
        "code": "TZ",
        "divisions": [{
          "code": "TZ-01",
          "represent": "Arusha"
        }, {
          "code": "TZ-02",
          "represent": "Dar-es-Salaam"
        }, {
          "code": "TZ-03",
          "represent": "Dodoma"
        }, {
          "code": "TZ-04",
          "represent": "Iringa"
        }, {
          "code": "TZ-05",
          "represent": "Kagera"
        }, {
          "code": "TZ-06",
          "represent": "Kaskazini Pemba (Pemba North)"
        }, {
          "code": "TZ-07",
          "represent": "Kaskazini Unguja (Zanzibar North)"
        }, {
          "code": "TZ-08",
          "represent": "Kigoma"
        }, {
          "code": "TZ-09",
          "represent": "Kilimanjaro"
        }, {
          "code": "TZ-10",
          "represent": "Kusini Pemba (Pemba South)"
        }, {
          "code": "TZ-11",
          "represent": "Kusini Unguja (Zanzibar South)"
        }, {
          "code": "TZ-12",
          "represent": "Lindi"
        }, {
          "code": "TZ-13",
          "represent": "Mara"
        }, {
          "code": "TZ-14",
          "represent": "Mbeya"
        }, {
          "code": "TZ-15",
          "represent": "Mjini Magharibi (Zanzibar West)"
        }, {
          "code": "TZ-16",
          "represent": "Morogoro"
        }, {
          "code": "TZ-17",
          "represent": "Mtwara"
        }, {
          "code": "TZ-18",
          "represent": "Mwanza"
        }, {
          "code": "TZ-19",
          "represent": "Pwani (Coast)"
        }, {
          "code": "TZ-20",
          "represent": "Rukwa"
        }, {
          "code": "TZ-21",
          "represent": "Ruvuma"
        }, {
          "code": "TZ-22",
          "represent": "Shinyanga"
        }, {
          "code": "TZ-23",
          "represent": "Singida"
        }, {
          "code": "TZ-24",
          "represent": "Tabora"
        }, {
          "code": "TZ-25",
          "represent": "Tanga"
        }]
      }, {
        "name": "Thailand",
        "code": "TH",
        "divisions": [{
          "code": "TH-10",
          "represent": "Krung Thep Maha Nakhon [Bangkok]"
        }, {
          "code": "TH-S",
          "represent": "Phatthaya"
        }, {
          "code": "TH-37",
          "represent": "Amnat Charoen"
        }, {
          "code": "TH-15",
          "represent": "Ang Thong"
        }, {
          "code": "TH-31",
          "represent": "Buri Ram"
        }, {
          "code": "TH-24",
          "represent": "Chachoengsao"
        }, {
          "code": "TH-18",
          "represent": "Chai Nat"
        }, {
          "code": "TH-36",
          "represent": "Chaiyaphum"
        }, {
          "code": "TH-22",
          "represent": "Chanthaburi"
        }, {
          "code": "TH-50",
          "represent": "Chiang Mai"
        }, {
          "code": "TH-57",
          "represent": "Chiang Rai"
        }, {
          "code": "TH-20",
          "represent": "Chon Buri"
        }, {
          "code": "TH-86",
          "represent": "Chumphon"
        }, {
          "code": "TH-46",
          "represent": "Kalasin"
        }, {
          "code": "TH-62",
          "represent": "Kamphaeng Phet"
        }, {
          "code": "TH-71",
          "represent": "Kanchanaburi"
        }, {
          "code": "TH-40",
          "represent": "Khon Kaen"
        }, {
          "code": "TH-81",
          "represent": "Krabi"
        }, {
          "code": "TH-52",
          "represent": "Lampang"
        }, {
          "code": "TH-51",
          "represent": "Lamphun"
        }, {
          "code": "TH-42",
          "represent": "Loei"
        }, {
          "code": "TH-16",
          "represent": "Lop Buri"
        }, {
          "code": "TH-58",
          "represent": "Mae Hong Son"
        }, {
          "code": "TH-44",
          "represent": "Maha Sarakham"
        }, {
          "code": "TH-49",
          "represent": "Mukdahan"
        }, {
          "code": "TH-26",
          "represent": "Nakhon Nayok"
        }, {
          "code": "TH-73",
          "represent": "Nakhon Pathom"
        }, {
          "code": "TH-48",
          "represent": "Nakhon Phanom"
        }, {
          "code": "TH-30",
          "represent": "Nakhon Ratchasima"
        }, {
          "code": "TH-60",
          "represent": "Nakhon Sawan"
        }, {
          "code": "TH-80",
          "represent": "Nakhon Si Thammarat"
        }, {
          "code": "TH-55",
          "represent": "Nan"
        }, {
          "code": "TH-96",
          "represent": "Narathiwat"
        }, {
          "code": "TH-39",
          "represent": "Nong Bua Lam Phu"
        }, {
          "code": "TH-43",
          "represent": "Nong Khai"
        }, {
          "code": "TH-12",
          "represent": "Nonthaburi"
        }, {
          "code": "TH-13",
          "represent": "Pathum Thani"
        }, {
          "code": "TH-94",
          "represent": "Pattani"
        }, {
          "code": "TH-82",
          "represent": "Phangnga"
        }, {
          "code": "TH-93",
          "represent": "Phatthalung"
        }, {
          "code": "TH-56",
          "represent": "Phayao"
        }, {
          "code": "TH-67",
          "represent": "Phetchabun"
        }, {
          "code": "TH-76",
          "represent": "Phetchaburi"
        }, {
          "code": "TH-66",
          "represent": "Phichit"
        }, {
          "code": "TH-65",
          "represent": "Phitsanulok"
        }, {
          "code": "TH-54",
          "represent": "Phrae"
        }, {
          "code": "TH-14",
          "represent": "Phra Nakhon Si Ayutthaya"
        }, {
          "code": "TH-83",
          "represent": "Phuket"
        }, {
          "code": "TH-25",
          "represent": "Prachin Buri"
        }, {
          "code": "TH-77",
          "represent": "Prachuap Khiri Khan"
        }, {
          "code": "TH-85",
          "represent": "Ranong"
        }, {
          "code": "TH-70",
          "represent": "Ratchaburi"
        }, {
          "code": "TH-21",
          "represent": "Rayong"
        }, {
          "code": "TH-45",
          "represent": "Roi Et"
        }, {
          "code": "TH-27",
          "represent": "Sa Kaeo"
        }, {
          "code": "TH-47",
          "represent": "Sakon Nakhon"
        }, {
          "code": "TH-11",
          "represent": "Samut Prakan"
        }, {
          "code": "TH-74",
          "represent": "Samut Sakhon"
        }, {
          "code": "TH-75",
          "represent": "Samut Songkhram"
        }, {
          "code": "TH-19",
          "represent": "Saraburi"
        }, {
          "code": "TH-91",
          "represent": "Satun"
        }, {
          "code": "TH-17",
          "represent": "Sing Buri"
        }, {
          "code": "TH-33",
          "represent": "Si Sa Ket"
        }, {
          "code": "TH-90",
          "represent": "Songkhla"
        }, {
          "code": "TH-64",
          "represent": "Sukhothai"
        }, {
          "code": "TH-72",
          "represent": "Suphan Buri"
        }, {
          "code": "TH-84",
          "represent": "Surat Thani"
        }, {
          "code": "TH-32",
          "represent": "Surin"
        }, {
          "code": "TH-63",
          "represent": "Tak"
        }, {
          "code": "TH-92",
          "represent": "Trang"
        }, {
          "code": "TH-23",
          "represent": "Trat"
        }, {
          "code": "TH-34",
          "represent": "Ubon Ratchathani"
        }, {
          "code": "TH-41",
          "represent": "Udon Thani"
        }, {
          "code": "TH-61",
          "represent": "Uthai Thani"
        }, {
          "code": "TH-53",
          "represent": "Uttaradit"
        }, {
          "code": "TH-95",
          "represent": "Yala"
        }, {
          "code": "TH-35",
          "represent": "Yasothon"
        }]
      }, {
        "name": "Togo",
        "code": "TG",
        "divisions": [{
          "code": "TG-C",
          "represent": "Centre"
        }, {
          "code": "TG-K",
          "represent": "Kara"
        }, {
          "code": "TG-M",
          "represent": "Maritime (Région)"
        }, {
          "code": "TG-P",
          "represent": "Plateaux"
        }, {
          "code": "TG-S",
          "represent": "Savannes"
        }]
      }, {
        "name": "Tokelau",
        "code": "TK",
        "divisions": [{
          "code": "TK-TK",
          "represent": "Tokelau"
        }]
      }, {
        "name": "Tonga",
        "code": "TO",
        "divisions": [{
          "code": "TO-TO",
          "represent": "Tonga"
        }]
      }, {
        "name": "Trinidad & Tobago",
        "code": "TT",
        "divisions": [{
          "code": "TT-CTT",
          "represent": "Couva-Tabaquite-Talparo"
        }, {
          "code": "TT-DMN",
          "represent": "Diego Martin"
        }, {
          "code": "TT-ETO",
          "represent": "Eastern Tobago"
        }, {
          "code": "TT-PED",
          "represent": "Penal-Debe"
        }, {
          "code": "TT-PRT",
          "represent": "Princes Town"
        }, {
          "code": "TT-RCM",
          "represent": "Rio Claro-Mayaro"
        }, {
          "code": "TT-SGE",
          "represent": "Sangre Grande"
        }, {
          "code": "TT-SJL",
          "represent": "San Juan-Laventille"
        }, {
          "code": "TT-SIP",
          "represent": "Siparia"
        }, {
          "code": "TT-TUP",
          "represent": "Tunapuna-Piarco"
        }, {
          "code": "TT-WTO",
          "represent": "Western Tobago"
        }, {
          "code": "TT-ARI",
          "represent": "Arima"
        }, {
          "code": "TT-CHA",
          "represent": "Chaguanas"
        }, {
          "code": "TT-PTF",
          "represent": "Point Fortin"
        }, {
          "code": "TT-POS",
          "represent": "Port of Spain"
        }, {
          "code": "TT-SFO",
          "represent": "San Fernando"
        }]
      }, {
        "name": "Tunisia",
        "code": "TN",
        "divisions": [{
          "code": "TN-31",
          "represent": "Béja"
        }, {
          "code": "TN-13",
          "represent": "Ben Arous"
        }, {
          "code": "TN-23",
          "represent": "Bizerte"
        }, {
          "code": "TN-81",
          "represent": "Gabès"
        }, {
          "code": "TN-71",
          "represent": "Gafsa"
        }, {
          "code": "TN-32",
          "represent": "Jendouba"
        }, {
          "code": "TN-41",
          "represent": "Kairouan"
        }, {
          "code": "TN-42",
          "represent": "Kasserine"
        }, {
          "code": "TN-73",
          "represent": "Kebili"
        }, {
          "code": "TN-12",
          "represent": "L'Ariana"
        }, {
          "code": "TN-33",
          "represent": "Le Kef"
        }, {
          "code": "TN-53",
          "represent": "Mahdia"
        }, {
          "code": "TN-82",
          "represent": "Medenine"
        }, {
          "code": "TN-52",
          "represent": "Monastir"
        }, {
          "code": "TN-21",
          "represent": "Nabeul"
        }, {
          "code": "TN-61",
          "represent": "Sfax"
        }, {
          "code": "TN-43",
          "represent": "Sidi Bouzid"
        }, {
          "code": "TN-34",
          "represent": "Siliana"
        }, {
          "code": "TN-51",
          "represent": "Sousse"
        }, {
          "code": "TN-83",
          "represent": "Tataouine"
        }, {
          "code": "TN-72",
          "represent": "Tozeur"
        }, {
          "code": "TN-11",
          "represent": "Tunis"
        }, {
          "code": "TN-22",
          "represent": "Zaghouan"
        }]
      }, {
        "name": "Turkey",
        "code": "TR",
        "divisions": [{
          "code": "TR-01",
          "represent": "Adana"
        }, {
          "code": "TR-02",
          "represent": "Adiyaman"
        }, {
          "code": "TR-03",
          "represent": "Afyon"
        }, {
          "code": "TR-04",
          "represent": "Ağrı"
        }, {
          "code": "TR-68",
          "represent": "Aksaray"
        }, {
          "code": "TR-05",
          "represent": "Amasya"
        }, {
          "code": "TR-06",
          "represent": "Ankara"
        }, {
          "code": "TR-07",
          "represent": "Antalya"
        }, {
          "code": "TR-75",
          "represent": "Ardahan"
        }, {
          "code": "TR-08",
          "represent": "Artvin"
        }, {
          "code": "TR-09",
          "represent": "Aydin"
        }, {
          "code": "TR-10",
          "represent": "Balıkesir"
        }, {
          "code": "TR-74",
          "represent": "Bartın"
        }, {
          "code": "TR-72",
          "represent": "Batman"
        }, {
          "code": "TR-69",
          "represent": "Bayburt"
        }, {
          "code": "TR-11",
          "represent": "Bilecik"
        }, {
          "code": "TR-12",
          "represent": "Bingöl"
        }, {
          "code": "TR-13",
          "represent": "Bitlis"
        }, {
          "code": "TR-14",
          "represent": "Bolu"
        }, {
          "code": "TR-15",
          "represent": "Burdur"
        }, {
          "code": "TR-16",
          "represent": "Bursa"
        }, {
          "code": "TR-17",
          "represent": "Çanakkale"
        }, {
          "code": "TR-18",
          "represent": "Çankırı"
        }, {
          "code": "TR-19",
          "represent": "Çorum"
        }, {
          "code": "TR-20",
          "represent": "Denizli"
        }, {
          "code": "TR-21",
          "represent": "Diyarbakır"
        }, {
          "code": "TR-22",
          "represent": "Edirne"
        }, {
          "code": "TR-23",
          "represent": "Elaziğ"
        }, {
          "code": "TR-24",
          "represent": "Erzincan"
        }, {
          "code": "TR-25",
          "represent": "Erzurum"
        }, {
          "code": "TR-26",
          "represent": "Eskişehir"
        }, {
          "code": "TR-27",
          "represent": "Gaziantep"
        }, {
          "code": "TR-28",
          "represent": "Giresun"
        }, {
          "code": "TR-29",
          "represent": "Gümüşhane"
        }, {
          "code": "TR-30",
          "represent": "Hakkari"
        }, {
          "code": "TR-31",
          "represent": "Hatay"
        }, {
          "code": "TR-76",
          "represent": "Iğdir"
        }, {
          "code": "TR-32",
          "represent": "Isparta"
        }, {
          "code": "TR-33",
          "represent": "İçel"
        }, {
          "code": "TR-34",
          "represent": "İstanbul"
        }, {
          "code": "TR-35",
          "represent": "İzmir"
        }, {
          "code": "TR-46",
          "represent": "Kahramanmaraş"
        }, {
          "code": "TR-78",
          "represent": "Karabük"
        }, {
          "code": "TR-70",
          "represent": "Karaman"
        }, {
          "code": "TR-36",
          "represent": "Kars"
        }, {
          "code": "TR-37",
          "represent": "Kastamonu"
        }, {
          "code": "TR-38",
          "represent": "Kayseri"
        }, {
          "code": "TR-71",
          "represent": "Kırıkkale"
        }, {
          "code": "TR-39",
          "represent": "Kırklareli"
        }, {
          "code": "TR-40",
          "represent": "Kırşehir"
        }, {
          "code": "TR-79",
          "represent": "Kilis"
        }, {
          "code": "TR-41",
          "represent": "Kocaeli"
        }, {
          "code": "TR-42",
          "represent": "Konya"
        }, {
          "code": "TR-43",
          "represent": "Kütahya"
        }, {
          "code": "TR-44",
          "represent": "Malatya"
        }, {
          "code": "TR-4S",
          "represent": "Manisa"
        }, {
          "code": "TR-47",
          "represent": "Mardin"
        }, {
          "code": "TR-48",
          "represent": "Muğla"
        }, {
          "code": "TR-49",
          "represent": "Muş"
        }, {
          "code": "TR-SO",
          "represent": "Nevşehir"
        }, {
          "code": "TR-51",
          "represent": "Niğde"
        }, {
          "code": "TR-52",
          "represent": "Ordu"
        }, {
          "code": "TR-53",
          "represent": "Rize"
        }, {
          "code": "TR-54",
          "represent": "Sakarya"
        }, {
          "code": "TR-SS",
          "represent": "Samsun"
        }, {
          "code": "TR-56",
          "represent": "Siirt"
        }, {
          "code": "TR-57",
          "represent": "Sinop"
        }, {
          "code": "TR-S8",
          "represent": "Sivas"
        }, {
          "code": "TR-63",
          "represent": "Şanlıurfa"
        }, {
          "code": "TR-73",
          "represent": "Şirnak"
        }, {
          "code": "TR-59",
          "represent": "Tekirdağ"
        }, {
          "code": "TR-60",
          "represent": "Tokat"
        }, {
          "code": "TR-61",
          "represent": "Trabzon"
        }, {
          "code": "TR-62",
          "represent": "Tunceli"
        }, {
          "code": "TR-64",
          "represent": "Uşak"
        }, {
          "code": "TR-65",
          "represent": "Van"
        }, {
          "code": "TR-77",
          "represent": "Yalova"
        }, {
          "code": "TR-66",
          "represent": "Yozgat"
        }, {
          "code": "TR-67",
          "represent": "Zonguldak"
        }]
      }, {
        "name": "Turkmenistan",
        "code": "TM",
        "divisions": [{
          "code": "TM-A",
          "represent": "Ahal"
        }, {
          "code": "TM-B",
          "represent": "Balkan"
        }, {
          "code": "TM-D",
          "represent": "Daşhowuz"
        }, {
          "code": "TM-L",
          "represent": "Lebap"
        }, {
          "code": "TM-M",
          "represent": "Mary"
        }]
      }, {
        "name": "Turks & Caicos Islands",
        "code": "TC",
        "divisions": [{
          "code": "TC-TC",
          "represent": "Turks & Caicos Islands"
        }]
      }, {
        "name": "Tuvalu",
        "code": "TV",
        "divisions": [{
          "code": "TV-TV",
          "represent": "Tuvalu"
        }]
      }, {
        "name": "Uganda",
        "code": "UG",
        "divisions": [{
          "code": "UG-APA",
          "represent": "Apac"
        }, {
          "code": "UG-ARU",
          "represent": "Arua"
        }, {
          "code": "UG-BUN",
          "represent": "Bundibugyo"
        }, {
          "code": "UG-BUS",
          "represent": "Bushenyi"
        }, {
          "code": "UG-GUL",
          "represent": "Gulu"
        }, {
          "code": "UG-HOI",
          "represent": "Hoima"
        }, {
          "code": "UG-IGA",
          "represent": "Iganga"
        }, {
          "code": "UG-JIN",
          "represent": "Jinja"
        }, {
          "code": "UG-KBL",
          "represent": "Kabale"
        }, {
          "code": "UG-KBR",
          "represent": "Kabarole"
        }, {
          "code": "UG-KLG",
          "represent": "Kalangala"
        }, {
          "code": "UG-KLA",
          "represent": "Kampala"
        }, {
          "code": "UG-KLI",
          "represent": "Kamuli"
        }, {
          "code": "UG-KAP",
          "represent": "Kapchorwa"
        }, {
          "code": "UG-KAS",
          "represent": "Kasese"
        }, {
          "code": "UG-KLE",
          "represent": "Kibaale"
        }, {
          "code": "UG-KIB",
          "represent": "Kiboga"
        }, {
          "code": "UG-KIS",
          "represent": "Kisoro"
        }, {
          "code": "UG-KIT",
          "represent": "Kitgum"
        }, {
          "code": "UG-KOT",
          "represent": "Kotido"
        }, {
          "code": "UG-KUM",
          "represent": "Kumi"
        }, {
          "code": "UG-LIR",
          "represent": "Lira"
        }, {
          "code": "UG-LUW",
          "represent": "Luwero"
        }, {
          "code": "UG-MSK",
          "represent": "Masaka"
        }, {
          "code": "UG-MSI",
          "represent": "Masindi"
        }, {
          "code": "UG-MBL",
          "represent": "Mbale"
        }, {
          "code": "UG-MBR",
          "represent": "Mbarara"
        }, {
          "code": "UG-MOR",
          "represent": "Moroto"
        }, {
          "code": "UG-MOY",
          "represent": "Moyo"
        }, {
          "code": "UG-MPI",
          "represent": "Mpigi"
        }, {
          "code": "UG-MUB",
          "represent": "Mubende"
        }, {
          "code": "UG-MUK",
          "represent": "Mukono"
        }, {
          "code": "UG-NEB",
          "represent": "Nebbi"
        }, {
          "code": "UG-NTU",
          "represent": "Ntungamo"
        }, {
          "code": "UG-PAL",
          "represent": "Pallisa"
        }, {
          "code": "UG-RAK",
          "represent": "Rakai"
        }, {
          "code": "UG-RUK",
          "represent": "Rukungiri"
        }, {
          "code": "UG-SOR",
          "represent": "Soroti"
        }, {
          "code": "UG-TOR",
          "represent": "Tororo"
        }]
      }, {
        "name": "Ukraine",
        "code": "UA",
        "divisions": [{
          "code": "UA-71",
          "represent": "Cherkas'ka Oblast'"
        }, {
          "code": "UA-74",
          "represent": "Chernihivs'ka Oblast'"
        }, {
          "code": "UA-77",
          "represent": "Chernivets'ka Oblast'"
        }, {
          "code": "UA-12",
          "represent": "Dnipropetrovs'ka Oblast'"
        }, {
          "code": "UA-14",
          "represent": "Donets'ka Oblast'"
        }, {
          "code": "UA-26",
          "represent": "Ivano-Frankivs'ka Oblast'"
        }, {
          "code": "UA-63",
          "represent": "Kharkivs'ka Oblast'"
        }, {
          "code": "UA-65",
          "represent": "Khersons'ka Oblast'"
        }, {
          "code": "UA-68",
          "represent": "Khmel'nyts'ka Oblast'"
        }, {
          "code": "UA-35",
          "represent": "Kirovohrads'ka Oblast'"
        }, {
          "code": "UA-32",
          "represent": "Kyïvs'ka Oblast'"
        }, {
          "code": "UA-09",
          "represent": "Luhans'ka Oblast'"
        }, {
          "code": "UA-46",
          "represent": "L'vivs'ka Oblast'"
        }, {
          "code": "UA-48",
          "represent": "Mykolaïvs'ka Oblast'"
        }, {
          "code": "UA-51",
          "represent": "Odes'ka Oblast'"
        }, {
          "code": "UA-53",
          "represent": "Poltavs'ka Oblast'"
        }, {
          "code": "UA-56",
          "represent": "Rivnens'ka Oblast'"
        }, {
          "code": "UA-59",
          "represent": "Sums'ka Oblast'"
        }, {
          "code": "UA-61",
          "represent": "Ternopil's'ka Oblast'"
        }, {
          "code": "UA-05",
          "represent": "Vinnyts'ka Oblast'"
        }, {
          "code": "UA-07",
          "represent": "Volyns'ka Oblast'"
        }, {
          "code": "UA-21",
          "represent": "Zakarpats'ka Oblast'"
        }, {
          "code": "UA-23",
          "represent": "Zaporiz'ka Oblast'"
        }, {
          "code": "UA-18",
          "represent": "Zhytomyrs'ka Oblast'"
        }, {
          "code": "UA-43",
          "represent": "Respublika Krym"
        }, {
          "code": "UA-30",
          "represent": "Kyïv"
        }, {
          "code": "UA-40",
          "represent": "Sevastopol'"
        }]
      }, {
        "name": "United Arab Emirates",
        "code": "AE",
        "divisions": [{
          "code": "AE-AZ",
          "represent": "Abū Zaby (Abu Dhabi)"
        }, {
          "code": "AE-AJ",
          "represent": "‘Ajmān"
        }, {
          "code": "AE-FU",
          "represent": "Al Fujayrah"
        }, {
          "code": "AE-SH",
          "represent": "Ash Shāriqah (Sharjah)"
        }, {
          "code": "AE-DU",
          "represent": "Dubayy (Dubai)"
        }, {
          "code": "AE-RK",
          "represent": "R'as al Khaymah"
        }, {
          "code": "AE-UQ",
          "represent": "Umm al Qaywayn"
        }]
      }, {
        "name": "United States",
        "code": "US",
        "divisions": [{
          "code": "US-AL",
          "represent": "Alabama"
        }, {
          "code": "US-AK",
          "represent": "Alaska"
        }, {
          "code": "US-AZ",
          "represent": "Arizona"
        }, {
          "code": "US-AR",
          "represent": "Arkansas"
        }, {
          "code": "US-CA",
          "represent": "California"
        }, {
          "code": "US-CO",
          "represent": "Colorado"
        }, {
          "code": "US-CT",
          "represent": "Connecticut"
        }, {
          "code": "US-DE",
          "represent": "Delaware"
        }, {
          "code": "US-FL",
          "represent": "Florida"
        }, {
          "code": "US-GA",
          "represent": "Georgia"
        }, {
          "code": "US-HI",
          "represent": "Hawaii"
        }, {
          "code": "US-ID",
          "represent": "Idaho"
        }, {
          "code": "US-IL",
          "represent": "Illinois"
        }, {
          "code": "US-IN",
          "represent": "Indiana"
        }, {
          "code": "US-IA",
          "represent": "Iowa"
        }, {
          "code": "US-KS",
          "represent": "Kansas"
        }, {
          "code": "US-KY",
          "represent": "Kentucky"
        }, {
          "code": "US-LA",
          "represent": "Louisiana"
        }, {
          "code": "US-ME",
          "represent": "Maine"
        }, {
          "code": "US-MD",
          "represent": "Maryland"
        }, {
          "code": "US-MA",
          "represent": "Massachusetts"
        }, {
          "code": "US-MI",
          "represent": "Michigan"
        }, {
          "code": "US-MN",
          "represent": "Minnesota"
        }, {
          "code": "US-MS",
          "represent": "Mississippi"
        }, {
          "code": "US-MO",
          "represent": "Missouri"
        }, {
          "code": "US-MT",
          "represent": "Montana"
        }, {
          "code": "US-NE",
          "represent": "Nebraska"
        }, {
          "code": "US-NV",
          "represent": "Nevada"
        }, {
          "code": "US-NH",
          "represent": "New Hampshire"
        }, {
          "code": "US-NJ",
          "represent": "New Jersey"
        }, {
          "code": "US-NM",
          "represent": "New Mexico"
        }, {
          "code": "US-NY",
          "represent": "New York"
        }, {
          "code": "US-NC",
          "represent": "North Carolina"
        }, {
          "code": "US-ND",
          "represent": "North Dakota"
        }, {
          "code": "US-OH",
          "represent": "Ohio"
        }, {
          "code": "US-OK",
          "represent": "Oklahoma"
        }, {
          "code": "US-OR",
          "represent": "Oregon"
        }, {
          "code": "US-PA",
          "represent": "Pennsylvania"
        }, {
          "code": "US-RI",
          "represent": "Rhode Island"
        }, {
          "code": "US-SC",
          "represent": "South Carolina"
        }, {
          "code": "US-SD",
          "represent": "South Dakota"
        }, {
          "code": "US-TN",
          "represent": "Tennessee"
        }, {
          "code": "US-TX",
          "represent": "Texas"
        }, {
          "code": "US-UT",
          "represent": "Utah"
        }, {
          "code": "US-VT",
          "represent": "Vermont"
        }, {
          "code": "US-VA",
          "represent": "Virginia"
        }, {
          "code": "US-WA",
          "represent": "Washington"
        }, {
          "code": "US-WV",
          "represent": "West Virginia"
        }, {
          "code": "US-WI",
          "represent": "Wisconsin"
        }, {
          "code": "US-WY",
          "represent": "Wyoming"
        }, {
          "code": "US-DC",
          "represent": "District of Columbia"
        }, {
          "code": "US-AS",
          "represent": "American Samoa"
        }, {
          "code": "US-GU",
          "represent": "Guam"
        }, {
          "code": "US-MP",
          "represent": "Northern Mariana Islands"
        }, {
          "code": "US-PR",
          "represent": "Puerto Rico"
        }, {
          "code": "US-UM",
          "represent": "United States Minor Outlying Islands"
        }, {
          "code": "US-VI",
          "represent": "Virgin Islands, U.S."
        }]
      }, {
        "name": "Uruguay",
        "code": "UY",
        "divisions": [{
          "code": "UY-AR",
          "represent": "Artigas"
        }, {
          "code": "UY-CA",
          "represent": "Canelones"
        }, {
          "code": "UY-CL",
          "represent": "Cerro Largo"
        }, {
          "code": "UY-CO",
          "represent": "Colonia"
        }, {
          "code": "UY-DU",
          "represent": "Durazno"
        }, {
          "code": "UY-FS",
          "represent": "Flores"
        }, {
          "code": "UY-FD",
          "represent": "Florida"
        }, {
          "code": "UY-LA",
          "represent": "Lavalleja"
        }, {
          "code": "UY-MA",
          "represent": "Maldonado"
        }, {
          "code": "UY-MO",
          "represent": "Montevideo"
        }, {
          "code": "UY-PA",
          "represent": "Paysandú"
        }, {
          "code": "UY-RN",
          "represent": "Río Negro"
        }, {
          "code": "UY-RV",
          "represent": "Rivera"
        }, {
          "code": "UY-RO",
          "represent": "Rocha"
        }, {
          "code": "UY-SA",
          "represent": "Salto"
        }, {
          "code": "UY-SJ",
          "represent": "San José"
        }, {
          "code": "UY-SO",
          "represent": "Soriano"
        }, {
          "code": "UY-TA",
          "represent": "Tacuarembó"
        }, {
          "code": "UY-TT",
          "represent": "Treinta y Tres"
        }]
      }, {
        "name": "Uzbekistan",
        "code": "UZ",
        "divisions": [{
          "code": "UZ-QR",
          "represent": "Qoraqalpoghiston Respublikasi (Karakalpakstan, Respublika)"
        }, {
          "code": "UZ-AN",
          "represent": "Andijon (Andizhan)"
        }, {
          "code": "UZ-BU",
          "represent": "Bukhoro (Bukhara)"
        }, {
          "code": "UZ-FA",
          "represent": "Farghona (Fergana)"
        }, {
          "code": "UZ-JI",
          "represent": "Jizzakh (Dzhizak)"
        }, {
          "code": "UZ-KH",
          "represent": "Khorazm (Khorezm)"
        }, {
          "code": "UZ-NG",
          "represent": "Namangan"
        }, {
          "code": "UZ-NW",
          "represent": "Nawoiy (Navoi)"
        }, {
          "code": "UZ-QA",
          "represent": "Qashqadaryo (Kashkadar'ya)"
        }, {
          "code": "UZ-SA",
          "represent": "Samarqand (Samarkand)"
        }, {
          "code": "UZ-SI",
          "represent": "Sirdaryo (Syrdar'ya)"
        }, {
          "code": "UZ-SU",
          "represent": "Surkhondaryo (Surkhandar'ya)"
        }, {
          "code": "UZ-TO",
          "represent": "Toshkent (Tashkent)"
        }]
      }, {
        "name": "Vanuatu",
        "code": "VU",
        "divisions": [{
          "code": "VU-MAP",
          "represent": "Malampa"
        }, {
          "code": "VU-PAM",
          "represent": "Pénama"
        }, {
          "code": "VU-SAM",
          "represent": "Sanma"
        }, {
          "code": "VU-SEE",
          "represent": "Shéfa"
        }, {
          "code": "VU-TAE",
          "represent": "Taféa"
        }, {
          "code": "VU-TOB",
          "represent": "Torba"
        }]
      }, {
        "name": "Venezuela",
        "code": "VE",
        "divisions": [{
          "code": "VE-A",
          "represent": "Distrito Federal"
        }, {
          "code": "VE-B",
          "represent": "Anzoátegui"
        }, {
          "code": "VE-C",
          "represent": "Apure"
        }, {
          "code": "VE-D",
          "represent": "Aragua"
        }, {
          "code": "VE-E",
          "represent": "Barinas"
        }, {
          "code": "VE-F",
          "represent": "Bolívar"
        }, {
          "code": "VE-G",
          "represent": "Carabobo"
        }, {
          "code": "VE-H",
          "represent": "Cojedes"
        }, {
          "code": "VE-I",
          "represent": "Falcón"
        }, {
          "code": "VE-J",
          "represent": "Guárico"
        }, {
          "code": "VE-K",
          "represent": "Lara"
        }, {
          "code": "VE-L",
          "represent": "Mérida"
        }, {
          "code": "VE-M",
          "represent": "Miranda"
        }, {
          "code": "VE-N",
          "represent": "Monagas"
        }, {
          "code": "VE-O",
          "represent": "Nueva Esparta"
        }, {
          "code": "VE-P",
          "represent": "Portuguesa"
        }, {
          "code": "VE-R",
          "represent": "Sucre"
        }, {
          "code": "VE-S",
          "represent": "Táchira"
        }, {
          "code": "VE-T",
          "represent": "Trujillo"
        }, {
          "code": "VE-U",
          "represent": "Yaracuy"
        }, {
          "code": "VE-V",
          "represent": "Zulia"
        }, {
          "code": "VE-Z",
          "represent": "Amazonas"
        }, {
          "code": "VE-Y",
          "represent": "Delta Amacuro"
        }, {
          "code": "VE-W",
          "represent": "Dependencias Federales"
        }]
      }, {
        "name": "Viet Nam",
        "code": "VN",
        "divisions": [{
          "code": "VN-44",
          "represent": "An Giang"
        }, {
          "code": "VN-53",
          "represent": "Bat Can"
        }, {
          "code": "VN-54",
          "represent": "Bat Giang"
        }, {
          "code": "VN-55",
          "represent": "Bat Lieu"
        }, {
          "code": "VN-56",
          "represent": "Bat Ninh"
        }, {
          "code": "VN-43",
          "represent": "Ba Ria - Vung Tau"
        }, {
          "code": "VN-50",
          "represent": "Ben Tre"
        }, {
          "code": "VN-31",
          "represent": "Binh Dinh"
        }, {
          "code": "VN-57",
          "represent": "Binh Duong"
        }, {
          "code": "VN-58",
          "represent": "Binh Phuoc"
        }, {
          "code": "VN-40",
          "represent": "Binh Thuan"
        }, {
          "code": "VN-59",
          "represent": "Ca Mau"
        }, {
          "code": "VN-48",
          "represent": "Can Tho"
        }, {
          "code": "VN-04",
          "represent": "Cao Bang"
        }, {
          "code": "VN-33",
          "represent": "Dac Lac"
        }, {
          "code": "VN-60",
          "represent": "Da Nang, thanh pho"
        }, {
          "code": "VN-39",
          "represent": "Dong Nai"
        }, {
          "code": "VN-45",
          "represent": "Dong Thap"
        }, {
          "code": "VN-30",
          "represent": "Gia Lai"
        }, {
          "code": "VN-03",
          "represent": "Ha Giang"
        }, {
          "code": "VN-61",
          "represent": "Hai Duong"
        }, {
          "code": "VN-62",
          "represent": "Hai Phong, thanh pho"
        }, {
          "code": "VN-63",
          "represent": "Ha Nam"
        }, {
          "code": "VN-64",
          "represent": "Ha Noi, thu do"
        }, {
          "code": "VN-15",
          "represent": "Ha Tay"
        }, {
          "code": "VN-23",
          "represent": "Ha Tinh"
        }, {
          "code": "VN-14",
          "represent": "Hoa Binh"
        }, {
          "code": "VN-65",
          "represent": "Ho Chi Minh, thanh po [Sai Gon]"
        }, {
          "code": "VN-66",
          "represent": "Hung Yen"
        }, {
          "code": "VN-34",
          "represent": "Khanh Hoa"
        }, {
          "code": "VN-47",
          "represent": "Kien Giang"
        }, {
          "code": "VN-28",
          "represent": "Kon Turn"
        }, {
          "code": "VN-01",
          "represent": "Lai Chau"
        }, {
          "code": "VN-35",
          "represent": "Lam Dong"
        }, {
          "code": "VN-09",
          "represent": "Lang Son"
        }, {
          "code": "VN-02",
          "represent": "Lao Cai"
        }, {
          "code": "VN-41",
          "represent": "Long An"
        }, {
          "code": "VN-67",
          "represent": "Nam Dinh"
        }, {
          "code": "VN-22",
          "represent": "Nghe An"
        }, {
          "code": "VN-18",
          "represent": "Ninh Binh"
        }, {
          "code": "VN-36",
          "represent": "Ninh Thuan"
        }, {
          "code": "VN-68",
          "represent": "Phu Tho"
        }, {
          "code": "VN-32",
          "represent": "Phu Yen"
        }, {
          "code": "VN-24",
          "represent": "Quang Ninh"
        }, {
          "code": "VN-27",
          "represent": "Quang Nam"
        }, {
          "code": "VN-29",
          "represent": "Quang Ngai"
        }, {
          "code": "VN-25",
          "represent": "Quang Tri"
        }, {
          "code": "VN-52",
          "represent": "Sec Trang"
        }, {
          "code": "VN-05",
          "represent": "Son La"
        }, {
          "code": "VN-37",
          "represent": "Tay Ninh"
        }, {
          "code": "VN-20",
          "represent": "Thai Binh"
        }, {
          "code": "VN-69",
          "represent": "Thai Nguyen"
        }, {
          "code": "VN-21",
          "represent": "Thanh Hoa"
        }, {
          "code": "VN-26",
          "represent": "Thua Thien-Hue"
        }, {
          "code": "VN-46",
          "represent": "Tien Giang"
        }, {
          "code": "VN-51",
          "represent": "Tra Vinh"
        }, {
          "code": "VN-07",
          "represent": "Tuyen Quang"
        }, {
          "code": "VN-49",
          "represent": "Vinh Long"
        }, {
          "code": "VN-70",
          "represent": "Vinh Yen"
        }, {
          "code": "VN-06",
          "represent": "Yen Bai"
        }]
      }, {
        "name": "Virgin Islands (British)",
        "code": "VG",
        "divisions": [{
          "code": "VG-VG",
          "represent": "Virgin Islands (British)"
        }]
      }, {
        "name": "Virgin Islands (U.S.)",
        "code": "VI",
        "divisions": [{
          "code": "VI-VI",
          "represent": "Virgin Islands (U.S.)"
        }]
      }, {
        "name": "Wallis & Futuna Islands",
        "code": "WF",
        "divisions": [{
          "code": "WF-WF",
          "represent": "Wallis & Futuna Islands"
        }]
      }, {
        "name": "Western Sahara",
        "code": "EH",
        "divisions": [{
          "code": "EH-EH",
          "represent": "Western Sahara"
        }]
      }, {
        "name": "Yemen",
        "code": "YE",
        "divisions": [{
          "code": "YE-AB",
          "represent": "Abyān"
        }, {
          "code": "YE-AD",
          "represent": "‘Adan"
        }, {
          "code": "YE-BA",
          "represent": "Al Bayḑā'"
        }, {
          "code": "YE-HU",
          "represent": "Al Ḩudaydah"
        }, {
          "code": "YE-JA",
          "represent": "Al Jawf"
        }, {
          "code": "YE-MR",
          "represent": "Al Mahrah"
        }, {
          "code": "YE-MW",
          "represent": "Al Maḩwit"
        }, {
          "code": "YE-DH",
          "represent": "Dhamār"
        }, {
          "code": "YE-HD",
          "represent": "Ḩaḑramawt"
        }, {
          "code": "YE-HJ",
          "represent": "Ḩajjah"
        }, {
          "code": "YE-IB",
          "represent": "Ibb"
        }, {
          "code": "YE-LA",
          "represent": "Laḩij"
        }, {
          "code": "YE-MA",
          "represent": "Ma'rib"
        }, {
          "code": "YE-SD",
          "represent": "Şa'dah"
        }, {
          "code": "YE-SN",
          "represent": "Şan‘ā'"
        }, {
          "code": "YE-SH",
          "represent": "Shabwah"
        }, {
          "code": "YE-TA",
          "represent": "Ta‘izz"
        }]
      }, {
        "name": "Yugoslavia",
        "code": "YU",
        "divisions": [{
          "code": "YU-CG",
          "represent": "Crna Gora"
        }, {
          "code": "YU-SR",
          "represent": "Srbija"
        }, {
          "code": "YU-KM",
          "represent": "Kosovo-Metohija"
        }, {
          "code": "YU-VO",
          "represent": "Vojvodina"
        }]
      }, {
        "name": "Zambia",
        "code": "ZM",
        "divisions": [{
          "code": "ZM-02",
          "represent": "Central"
        }, {
          "code": "ZM-08",
          "represent": "Copperbelt"
        }, {
          "code": "ZM-03",
          "represent": "Eastern"
        }, {
          "code": "ZM-04",
          "represent": "Luapula"
        }, {
          "code": "ZM-09",
          "represent": "Lusaka"
        }, {
          "code": "ZM-05",
          "represent": "Northern"
        }, {
          "code": "ZM-06",
          "represent": "North-Western"
        }, {
          "code": "ZM-07",
          "represent": "Southern"
        }, {
          "code": "ZM-01",
          "represent": "Western"
        }]
      }, {
        "name": "Zimbabwe",
        "code": "ZW",
        "divisions": [{
          "code": "ZW-BU",
          "represent": "Bulawayo"
        }, {
          "code": "ZW-HA",
          "represent": "Harare"
        }, {
          "code": "ZW-MA",
          "represent": "Manicaland"
        }, {
          "code": "ZW-MC",
          "represent": "Mashonaland Central"
        }, {
          "code": "ZW-ME",
          "represent": "Mashonaland East"
        }, {
          "code": "ZW-MW",
          "represent": "Mashonaland West"
        }, {
          "code": "ZW-MV",
          "represent": "Masvingo"
        }, {
          "code": "ZW-MN",
          "represent": "Matabeleland North"
        }, {
          "code": "ZW-MS",
          "represent": "Matabeleland South"
        }, {
          "code": "ZW-MI",
          "represent": "Midlands"
        }]
      }];

      if (params[0] == 'County') {
        for (var c = 0; c < stateselect.length; c++) {
          if (stateselect[c]['code'] == params[1]) {
            return stateselect[c]['name'];
          }
        }

        return params[1];
      } else if (params[0] == 'State') {
        for (var c = 0; c < stateselect.length; c++) {
          if (stateselect[c]['code'] == params[1]) {
            for (var s = 0; s < stateselect[c]['divisions'].length; s++) {
              if (stateselect[c]['divisions'][s]['code'] == params[2]) return stateselect[c]['divisions'][s]['represent'];
            }
          }
        }

        return params[2];
      }
    } else {
      return null;
    }
  }

  var _default = Ember.Helper.helper(countryandstate);

  _exports.default = _default;
});