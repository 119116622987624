define("subscriptionclient/helpers/calculations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.calculations = calculations;
  _exports.default = void 0;

  function calculations(params
  /*, hash*/
  ) {
    var v1 = params[0];
    var operator = params[1];
    var v2 = params[2];

    if (v2 == null) {
      v2 = 0.00;
    }

    if (v1 == null) {
      v1 = 0.00;
    }

    switch (operator) {
      case '+':
        return v1 + v2;

      case '-':
        return v1 - v2;

      case '*':
        return Math.round(v1 * v2 * 100) / 100;

      case '%':
        return v1 % v2;

      case '/':
        if (v1 % v2 > 0) {
          var result = v1 / v2;
          result = result + 1;
        } else {
          var result = v1 / v2;
        }

        return parseInt(result);

      default:
        return false;
    }
  }

  var _default = Ember.Helper.helper(calculations);

  _exports.default = _default;
});