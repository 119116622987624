define("subscriptionclient/routes/components/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var show_coupon = Cookies.get('show_coupon');
      var show_plan = Cookies.get('show_plan');
      var show_tax = Cookies.get('show_tax');

      if (show_coupon == 'true') {
        window.location.href = '/components/coupons';
      } else if (show_plan == 'true') {
        window.location.href = '/components/plans';
      } else if (show_tax == 'true') {
        window.location.href = '/components/tax';
      } else {
        window.location.href = '/notfound';
      }
    }
  });

  _exports.default = _default;
});