define("subscriptionclient/controllers/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    show_role: Cookies.get('show_role'),
    show_tech: Cookies.get('show_tech')
  });

  _exports.default = _default;
});