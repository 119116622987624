define("subscriptionclient/helpers/datetime", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.datetime = datetime;
  _exports.default = void 0;

  function datetime(params
  /*, hash*/
  ) {
    if (params[0] != null) {
      var t = params[0].split("T");
      var dateinitial = t[0].split("-");
      var timeinitial = t[1].match(/.{1,8}/g);
      var returndate = dateinitial[1] + '/' + dateinitial[2] + '/' + dateinitial[0] + ' ' + timeinitial[0];
      return returndate; // return moment(params[0]).format('LLL');
    } else {
      return null;
    }
  }

  var _default = Ember.Helper.helper(datetime);

  _exports.default = _default;
});