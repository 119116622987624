define("subscriptionclient/helpers/plannamecut", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.plannamecut = plannamecut;
  _exports.default = void 0;

  function plannamecut(params
  /*, hash*/
  ) {
    if (params[0] != null) {
      var planname;
      var planid = params[0];
      var plannamemapper = {
        "Pln_t8baSofAIXxp8SOj": "Express Monthly",
        "Pln_qyTLG68vE18bZM3h": "Ultra Monthly",
        "Pln_X2BmeKZsMHzPMEnF": "Pro Monthly",
        "Pln_mTv02Rv08N3NEajV": "Enterprise Monthly",
        "Pln_4ttPFiQdDaRu9kMv": "Ultimate Monthly",
        "Pln_381bpHcK6c2mq63S": "Express Annual",
        "Pln_XpubfFipCxhFedFq": "Ultra Annual",
        "Pln_5BF72p9PQD6g8kHl": "Pro Annual",
        "Pln_p3IA4PCSJ6vsUcU3": "Enterprise Annual",
        "Pln_X6S3bk3hPdxGlsWA": "Ultimate Annual",
        "Pln_AjZZCnaAMxZWyU7v": "Trial Edition"
      };

      if (planid in plannamemapper) {
        planname = plannamemapper[planid];
      } else {
        planname = 'Custom Plan';
      }

      return planname;
    } else {
      return null;
    }
  }

  var _default = Ember.Helper.helper(plannamecut);

  _exports.default = _default;
});