define("subscriptionclient/components/report-dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    RemoveOption: {
      legend: {
        display: false
      },
      scales: {
        xAxes: [{
          ticks: {
            display: false,
            fontSize: 10
          },
          gridLines: {
            display: false
          }
        }],
        yAxes: [{
          ticks: {
            display: false
          },
          gridLines: {
            display: false
          }
        }]
      },
      elements: {
        point: {
          radius: 0
        }
      },
      hover: {
        mode: 'index',
        intersect: false
      },
      tooltips: {
        mode: 'index',
        intersect: false
      }
    },
    RemoveOptionmain: {
      legend: {
        display: true
      },
      scales: {
        xAxes: [{
          ticks: {
            display: true,
            fontSize: 10,
            color: 'red'
          },
          gridLines: {
            display: false
          }
        }],
        yAxes: [{
          ticks: {
            display: true,
            fontSize: 14,
            color: 'red'
          },
          gridLines: {
            display: true,
            color: '#f2f2f2'
          }
        }]
      },
      elements: {
        point: {
          radius: 0
        }
      },
      hover: {
        mode: 'index',
        intersect: false
      },
      tooltips: {
        mode: 'index',
        intersect: false
      }
    },
    data: {
      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      datasets: [{
        data: [65, 59, 80, 81, 56, 55, 40, 65, 59, 80, 81, 56, 55, 40],
        fill: false,
        borderColor: '#ffffff',
        tension: 0.4
      }]
    },
    data1: {
      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      datasets: [{
        label: 'My First Dataset',
        data: [65, 59, 80, 81, 56, 55, 40, 65, 59, 80, 81, 56, 55, 40],
        fill: false,
        borderColor: '#2078ff',
        tension: 0
      }, {
        label: 'My First Dataset',
        data: [65, 59, 80, 81, 56, 55, 40, 65, 59, 80, 81, 56, 55, 40],
        fill: false,
        borderColor: '#5b4789',
        tension: 0
      }]
    },
    TotalDataPresent: false,
    GraphNumber: 1,
    SideReportHeading: 'Revenue Report',
    SideReportMonth: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    SideReportYear: [],
    SideReportPreYear: [],
    ChangeInReport: [],
    ReportBannerList: [],
    init: function init() {
      this._super.apply(this, arguments);

      var FullData = this.get('reportdata');

      if (FullData.hasOwnProperty('Year')) {
        if (FullData['Year'].hasOwnProperty('MonthData')) {
          var reportData = this.get('reportdata.Year.MonthData');
        } else {
          var reportData = [];
        }
      } else {
        var reportData = [];
      }

      if (FullData.hasOwnProperty('PreYear')) {
        if (FullData['PreYear'].hasOwnProperty('MonthData')) {
          var reportDataPre = this.get('reportdata.PreYear.MonthData');
        } else {
          var reportDataPre = [];
        }
      } else {
        var reportDataPre = [];
      }

      var reportDataYear = this.get('reportdata.Year.Year');
      var reportDataPreYear = this.get('reportdata.PreYear.Year');
      var reportDataExtra = this.get('reportdata.Year');
      var reportDataPreExtra = this.get('reportdata.PreYear');
      this.set('data.datasets.0.data', reportData);
      this.set('data1.datasets.0.data', reportData);
      this.set('data1.datasets.0.label', reportDataYear);
      this.set('SideReportYear', reportData);
      this.set('data1.datasets.1.data', reportDataPre);
      this.set('data1.datasets.1.label', reportDataPreYear);
      this.set('SideReportPreYear', reportDataPre);
      var ChangeInReport = [];

      if (reportData.length > 0) {
        for (var i = 0; i < reportData.length; i++) {
          var Diff = reportData[i] - reportDataPre[i];

          if (reportDataPre[i] > 0) {
            var Diffpercentage = (Diff * 100 / reportDataPre[i]).toFixed(2);
          } else {
            var Diffpercentage = (Diff * 100).toFixed(2);
          }

          ChangeInReport.push(Diffpercentage);

          if (i + 1 == reportData.length) {
            this.set('ChangeInReport', ChangeInReport);
          }
        }

        this.set('TotalDataPresent', true);
      } else {
        this.set('TotalDataPresent', false);
      }

      var ReportBannerList = [];
      var GraphNumber = 0;

      if (reportDataExtra.hasOwnProperty('Total')) {
        if (GraphNumber == 0) {
          GraphNumber = 1;
        }
      }

      if (reportDataExtra.hasOwnProperty('Recurring')) {
        var RecurringDiff = reportDataExtra['Recurring'] - reportDataPreExtra['Recurring'];

        if (reportDataPreExtra['Recurring'] > 0) {
          var RecurringPercentage = (RecurringDiff * 100 / reportDataPreExtra['Recurring']).toFixed(2);
        } else {
          var RecurringPercentage = (RecurringDiff * 100).toFixed(2);
        }

        var RecurringDict = {
          'text': 'RECURRING REVENUE',
          'amount': reportDataExtra['Recurring'],
          'percentage': RecurringPercentage
        };
        ReportBannerList.push(RecurringDict);

        if (GraphNumber == 0) {
          GraphNumber = 2;
        }
      }

      if (reportDataExtra.hasOwnProperty('Upgrade')) {
        var UpgradeDiff = reportDataExtra['Upgrade'] - reportDataPreExtra['Upgrade'];

        if (reportDataPreExtra['Upgrade'] > 0) {
          var UpgradePercentage = (UpgradeDiff * 100 / reportDataPreExtra['Upgrade']).toFixed(2);
        } else {
          var UpgradePercentage = (UpgradeDiff * 100).toFixed(2);
        }

        var UpgradeDict = {
          'text': 'UPGRADE REVENUE',
          'amount': reportDataExtra['Upgrade'],
          'percentage': UpgradePercentage
        };
        ReportBannerList.push(UpgradeDict);

        if (GraphNumber == 0) {
          GraphNumber = 3;
        }
      }

      if (reportDataExtra.hasOwnProperty('New')) {
        var NewDiff = reportDataExtra['New'] - reportDataPreExtra['New'];

        if (reportDataPreExtra['New'] > 0) {
          var NewPercentage = (NewDiff * 100 / reportDataPreExtra['New']).toFixed(2);
        } else {
          var NewPercentage = (NewDiff * 100).toFixed(2);
        }

        var NewDict = {
          'text': 'NEW CUSTOMER REVENUE',
          'amount': reportDataExtra['New'],
          'percentage': NewPercentage
        };
        ReportBannerList.push(NewDict);

        if (GraphNumber == 0) {
          GraphNumber = 4;
        }
      }

      if (reportDataExtra.hasOwnProperty('Churn')) {
        var ChurnDiff = reportDataExtra['Churn'] - reportDataPreExtra['Churn'];

        if (reportDataPreExtra['Churn'] > 0) {
          var ChurnPercentage = (ChurnDiff * 100 / reportDataPreExtra['Churn']).toFixed(2);
        } else {
          var ChurnPercentage = (ChurnDiff * 100).toFixed(2);
        }

        var ChurnDict = {
          'text': 'CHURN CUSTOMER LOSS',
          'amount': reportDataExtra['Churn'],
          'percentage': ChurnPercentage
        };
        ReportBannerList.push(ChurnDict);

        if (GraphNumber == 0) {
          GraphNumber = 5;
        }
      }

      this.set('ReportBannerList', ReportBannerList); // Data set In Graph

      var MonthreportData = this.get('reportdata.Month');
      var MonthreportDataPre = this.get('reportdata.PreMonth');
      var reportData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

      if (MonthreportData.length > 0) {
        for (var i = 0; i < MonthreportData.length; i++) {
          var InsertData = 0;
          var InsertIndex = MonthreportData[i]['Month'] - 1;

          if (GraphNumber == 1) {
            InsertData = MonthreportData[i]['Total'];
          } else if (GraphNumber == 2) {
            InsertData = MonthreportData[i]['Recurring'];
          } else if (GraphNumber == 3) {
            InsertData = MonthreportData[i]['Upgrade'];
          } else if (GraphNumber == 4) {
            InsertData = MonthreportData[i]['New'];
          } else if (GraphNumber == 5) {
            InsertData = MonthreportData[i]['Churn'];
          } else if (GraphNumber == 6) {
            InsertData = MonthreportData[i]['Downgrade'];
          }

          reportData[InsertIndex] = InsertData;

          if (i + 1 == MonthreportData.length) {
            this.set('SideReportYear', reportData);
            var Data1 = this.get('data1');
            Data1['datasets'][0]['data'] = reportData;
            this.set('data1', null);
            this.set('data1', Data1);
          }
        }
      } else {
        this.set('SideReportYear', reportData);
        var Data1 = this.get('data1');
        Data1['datasets'][0]['data'] = reportData;
        this.set('data1', null);
        this.set('data1', Data1);
      }

      var reportDataPre = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

      if (MonthreportDataPre.length > 0) {
        for (var i = 0; i < MonthreportDataPre.length; i++) {
          var InsertData = 0;
          var InsertIndex = MonthreportDataPre[i]['Month'] - 1;

          if (GraphNumber == 1) {
            InsertData = MonthreportDataPre[i]['Total'];
          } else if (GraphNumber == 2) {
            InsertData = MonthreportDataPre[i]['Recurring'];
          } else if (GraphNumber == 3) {
            InsertData = MonthreportDataPre[i]['Upgrade'];
          } else if (GraphNumber == 4) {
            InsertData = MonthreportDataPre[i]['New'];
          } else if (GraphNumber == 5) {
            InsertData = MonthreportDataPre[i]['Churn'];
          } else if (GraphNumber == 6) {
            InsertData = MonthreportDataPre[i]['Downgrade'];
          }

          reportDataPre[InsertIndex] = InsertData;

          if (i + 1 == MonthreportDataPre.length) {
            this.set('SideReportPreYear', reportDataPre);
            var Data1 = this.get('data1');
            Data1['datasets'][1]['data'] = reportDataPre;
            this.set('data1', null);
            this.set('data1', Data1);
          }
        }
      } else {
        this.set('SideReportPreYear', reportDataPre);
        var Data1 = this.get('data1');
        Data1['datasets'][1]['data'] = reportDataPre;
        this.set('data1', null);
        this.set('data1', Data1);
      }

      var ChangeInReport = [];

      for (var i = 0; i < reportData.length; i++) {
        var Diff = reportData[i] - reportDataPre[i];

        if (reportDataPre[i] > 0) {
          var Diffpercentage = (Diff * 100 / reportDataPre[i]).toFixed(2);
        } else {
          var Diffpercentage = (Diff * 100).toFixed(2);
        }

        ChangeInReport.push(Diffpercentage);

        if (i + 1 == reportData.length) {
          this.set('ChangeInReport', ChangeInReport);
        }
      }

      if (GraphNumber == 1) {
        this.set('SideReportHeading', 'Total Revenue');
      } else if (GraphNumber == 2) {
        this.set('SideReportHeading', 'Recurring Revenue');
      } else if (GraphNumber == 3) {
        this.set('SideReportHeading', 'Upgrade Revenue');
      } else if (GraphNumber == 4) {
        this.set('SideReportHeading', 'New Customer Revenue');
      } else if (GraphNumber == 5) {
        this.set('SideReportHeading', 'Churn Loss');
      }
    },
    actions: {
      NextArrow: function NextArrow(to) {
        // 1 - total Revenu, 2 - Recurring, 3 - upgrade, 4- New customer, 5- Churn, 6 - Downgrade
        var GraphNumber = this.get('GraphNumber');
        var reportDataExtra = this.get('reportdata.Year');
        var GraphDataPresent = [];

        if (reportDataExtra.hasOwnProperty('Total')) {
          GraphDataPresent.push(1);
        }

        if (reportDataExtra.hasOwnProperty('Recurring')) {
          GraphDataPresent.push(2);
        }

        if (reportDataExtra.hasOwnProperty('Upgrade')) {
          GraphDataPresent.push(3);
        }

        if (reportDataExtra.hasOwnProperty('New')) {
          GraphDataPresent.push(4);
        }

        if (reportDataExtra.hasOwnProperty('Churn')) {
          GraphDataPresent.push(5);
        }

        if (to == 1) {
          GraphNumber = parseInt(GraphNumber) + 1;
        } else {
          GraphNumber = parseInt(GraphNumber) - 1;
        }

        if (GraphNumber < 1) {
          GraphNumber = 5;
        }

        if (GraphNumber > 6) {
          GraphNumber = 1;
        }

        if (!GraphDataPresent.includes(GraphNumber)) {
          if (GraphDataPresent.length > 1) {
            GraphDataPresent.sort(function (a, b) {
              return a - b;
            });

            for (var k = 0; k < GraphDataPresent.length; k++) {
              if (GraphDataPresent[k] > GraphNumber) {
                GraphNumber = GraphDataPresent[k];
                break;
              }

              if (k + 1 == GraphDataPresent.length) {
                GraphNumber = GraphDataPresent[0];
              }
            }
          } else {
            GraphNumber = GraphDataPresent[0];
          }
        }

        this.set('GraphNumber', GraphNumber);
        var MonthreportData = this.get('reportdata.Month');
        var MonthreportDataPre = this.get('reportdata.PreMonth');
        var reportData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        if (MonthreportData.length > 0) {
          for (var i = 0; i < MonthreportData.length; i++) {
            var InsertData = 0;
            var InsertIndex = MonthreportData[i]['Month'] - 1;

            if (GraphNumber == 1) {
              InsertData = MonthreportData[i]['Total'];
            } else if (GraphNumber == 2) {
              InsertData = MonthreportData[i]['Recurring'];
            } else if (GraphNumber == 3) {
              InsertData = MonthreportData[i]['Upgrade'];
            } else if (GraphNumber == 4) {
              InsertData = MonthreportData[i]['New'];
            } else if (GraphNumber == 5) {
              InsertData = MonthreportData[i]['Churn'];
            } else if (GraphNumber == 6) {
              InsertData = MonthreportData[i]['Downgrade'];
            }

            reportData[InsertIndex] = InsertData;

            if (i + 1 == MonthreportData.length) {
              this.set('SideReportYear', reportData);
              var Data1 = this.get('data1');
              Data1['datasets'][0]['data'] = reportData;
              this.set('data1', null);
              this.set('data1', Data1);
            }
          }
        } else {
          this.set('SideReportYear', reportData);
          var Data1 = this.get('data1');
          Data1['datasets'][0]['data'] = reportData;
          this.set('data1', null);
          this.set('data1', Data1);
        }

        var reportDataPre = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        if (MonthreportDataPre.length > 0) {
          for (var i = 0; i < MonthreportDataPre.length; i++) {
            var InsertData = 0;
            var InsertIndex = MonthreportDataPre[i]['Month'] - 1;

            if (GraphNumber == 1) {
              InsertData = MonthreportDataPre[i]['Total'];
            } else if (GraphNumber == 2) {
              InsertData = MonthreportDataPre[i]['Recurring'];
            } else if (GraphNumber == 3) {
              InsertData = MonthreportDataPre[i]['Upgrade'];
            } else if (GraphNumber == 4) {
              InsertData = MonthreportDataPre[i]['New'];
            } else if (GraphNumber == 5) {
              InsertData = MonthreportDataPre[i]['Churn'];
            } else if (GraphNumber == 6) {
              InsertData = MonthreportDataPre[i]['Downgrade'];
            }

            reportDataPre[InsertIndex] = InsertData;

            if (i + 1 == MonthreportDataPre.length) {
              this.set('SideReportPreYear', reportDataPre);
              var Data1 = this.get('data1');
              Data1['datasets'][1]['data'] = reportDataPre;
              this.set('data1', null);
              this.set('data1', Data1);
            }
          }
        } else {
          this.set('SideReportPreYear', reportDataPre);
          var Data1 = this.get('data1');
          Data1['datasets'][1]['data'] = reportDataPre;
          this.set('data1', null);
          this.set('data1', Data1);
        }

        var ChangeInReport = [];

        for (var i = 0; i < reportData.length; i++) {
          var Diff = reportData[i] - reportDataPre[i];

          if (reportDataPre[i] > 0) {
            var Diffpercentage = (Diff * 100 / reportDataPre[i]).toFixed(2);
          } else {
            var Diffpercentage = (Diff * 100).toFixed(2);
          }

          ChangeInReport.push(Diffpercentage);

          if (i + 1 == reportData.length) {
            this.set('ChangeInReport', ChangeInReport);
          }
        }

        if (GraphNumber == 1) {
          this.set('SideReportHeading', 'Total Revenue');
        } else if (GraphNumber == 2) {
          this.set('SideReportHeading', 'Recurring Revenue');
        } else if (GraphNumber == 3) {
          this.set('SideReportHeading', 'Upgrade Revenue');
        } else if (GraphNumber == 4) {
          this.set('SideReportHeading', 'New Customer Revenue');
        } else if (GraphNumber == 5) {
          this.set('SideReportHeading', 'Churn Loss');
        }
      }
    }
  });

  _exports.default = _default;
});