define("subscriptionclient/controllers/components", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    show_coupon: Cookies.get('show_coupon'),
    show_tax: Cookies.get('show_tax'),
    show_plan: Cookies.get('show_plan')
  });

  _exports.default = _default;
});