define("subscriptionclient/helpers/range", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.range = range;
  _exports.default = void 0;

  function range(params
  /*, hash*/
  ) {
    var range = [];

    for (var i = params[0]; i < params[1]; ++i) {
      range.push(i);
    }

    return range;
  }

  var _default = Ember.Helper.helper(range);

  _exports.default = _default;
});