define("subscriptionclient/templates/quote", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OzT9YP78",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"session\",\"isAuthenticated\"]]],null,{\"statements\":[[0,\"\\n\"],[7,\"div\",true],[10,\"id\",\"mytopsubnav\"],[10,\"class\",\"topnav\"],[10,\"style\",\"min-height: 43px;padding-left:20px;position:sticky;top:68px;z-index: 0;width: 100%;right:0px;\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"quote_show\"]],\"true\"],null]],null,{\"statements\":[[4,\"link-to\",null,[[\"class\",\"route\"],[\"link_changeclass\",\"quote.quoteslist\"]],{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"topsubnavbarhead\"],[10,\"align\",\"center\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"span\",true],[10,\"class\",\"topsubnavbarheadmargin\"],[8],[0,\"Quotes\"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[28,\"eq\",[[24,[\"quoteacc_show\"]],\"true\"],null]],null,{\"statements\":[[4,\"link-to\",null,[[\"class\",\"route\"],[\"link_changeclass\",\"quote.account\"]],{\"statements\":[[0,\"\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"topsubnavbarhead\"],[10,\"align\",\"center\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[7,\"span\",true],[10,\"class\",\"topsubnavbarheadmargin\"],[8],[0,\"Quote Account\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null],[9],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "subscriptionclient/templates/quote.hbs"
    }
  });

  _exports.default = _default;
});